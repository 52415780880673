import React from 'react';
import {Bar} from 'react-chartjs-2'


export default function BarChart() {

    const data = {

    labels: ['Access', 'Build', 'Internalize'],
    datasets: [{
      label: 'Access',
      backgroundColor: "#8bd658",
      data: [4,0,0]
    },
    {
      label: 'Build',
      backgroundColor: "#f5e144",
      data: [0,3,0]
    },
     
    {
      label: 'Internalize',
      backgroundColor: "#eb722e",
      data: [0,0,2]
    }]

    }

    const options = {
        
        legend: {
          display: true
        },
        scales: {
            xAxes: [{
                gridLines: {
                    drawOnChartArea: false,
                    color: 'white',
                }
            }],
            yAxes: [
                

                {
                gridLines: {
                    drawOnChartArea: false,
                    // tickMarkLength: 0
                }
                },

            {
                ticks: 
                {
                
                min: 0,
                max: 4,
                stepSize: 1
                }
            }
            
            
            ]
        },
       
      };

  return (
    <div>

        <Bar
        data = {data}
        options={options}
        />

    </div>
  )
}
