import React, {useState, useEffect} from 'react';
import axios from 'axios';

export default function Data() {

    const [teachers, setTeachers] = useState([])

 useEffect(()=>{
    // axios.get('http://localhost:5000/api/data/')
    axios.get('https://app.udl-boe.eu/api/data/')
    .then(res => {
     
      setTeachers(res.data)
      
     //  setCountry(res.data.country ? res.data.country : "")
     })
   .catch(err => console.log(err));
 }, [])

 useEffect(()=>{
    console.log("All Teachers" + JSON.stringify(teachers))
    setTeachers(teachers)
 }, [teachers])


  return (
    <>
    
    <h1>Teachers Data</h1>
    
    {
        teachers ?
        //  <h1>Teacher data available</h1>
        
            teachers.map((t, idx)=>{
                return (
                <div style={{backgroundColor:"white"}}>
                <hr style={{backgroundColor:"black", height:"5px"}}/>
                <h1>Teacher # {idx+1}</h1>
                <p>{JSON.stringify(t)}</p>
                <hr style={{backgroundColor:"black", height:"5px"}}/>
                
                </div>)
            })
        
        : <h1>Teacher data not availale</h1>
       
    }

    

</>

  )
}
