import React, {useState} from 'react';
import { Link } from "react-router-dom";


// Pass current Language from Flags to UDL Intro Page
// From UDL Intro Page pass to Main Questions

import { useTranslation } from 'react-i18next';


import en from '../images/en.png';
import gr from '../images/gr.png';
import du from '../images/du.png';
import es from '../images/es.png';




export default function Flags({changeLanguage, currentLang}) {

    const [selectLanguage, setSelectedLanguage] = useState(1);

    const { t } = useTranslation();

    console.log("Current Language is" +currentLang)

    
    

    const notHighlighted={
      width: "5em", 
      height: "3em",
      cursor : "pointer"
    }
    
    const highlighted={
      width: "5em", 
      height: "3em",
      cursor : "pointer",
      border: "5px solid #f5c644"
    }

  return(
    <>

    <div style={{backgroundColor:"white"}}>
       
       
       
          

        

    </div>

    <div className="flags-bg" >
      
      
    
      

      <div style={{position:"absolute", right: "20px",
    display:"flex", columnGap:"20px", marginTop:"1em" }}>

      <label
      className="choose-lng">Choose Language</label>

      <div>
        <img src={en} 
        style={currentLang == 'en' ? highlighted : notHighlighted}
        onClick={e=> changeLanguage(e, 'en')}/>
      </div>

      <div>
        <img src={gr} 
        style={currentLang == 'gr' ? highlighted : notHighlighted}
        onClick={e=> changeLanguage(e, 'gr')}/>
      </div>

      <div>
        <img src={du} 
        style={currentLang == 'du' ? highlighted : notHighlighted}
        onClick={e=> changeLanguage(e, 'du')}/>
      </div>

      <div>
        <img src={es} 
        style={currentLang == 'es' ? highlighted : notHighlighted}
        onClick={e=> changeLanguage(e, 'es')}/>
      </div>

      </div>




    </div>
      
    </>

      
  
    );
}
