import React, {useState, useEffect} from 'react';
import styled from 'styled-components';

import { Link } from "react-router-dom";


import youtube from '../images/youtube.png';
import www from '../images/www.png';
import pdf from '../images/pdf.png';
import video from '../images/video.png';

// Load Checkpoint Links Data
import checkpointlinks from './checkpointlinks';


// Use styled components for designing here ... 


const Container = styled.div`
    
    width: 450px;
    background-color: #B2BABB;
    height: 155px;
    margin: 20px;
    padding: 10px 0px;
    font-weight: bold;
    
`;

const Title = styled.label`
    font-family: Ubuntu;
    font-weight: bold;
    font-size: 24px;
    padding-left: 20px;
    
`;

const Details = styled.div`
    background-color: white;
    border: 1px  solid #B2BABB;
    overflow-y: auto;
    height: 210px;
    font-family: Ubuntu;
    font-size: 28px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    
`

const Image = styled.span`
width: 110px;
height: 50px;
object-fit: contain;
`

const Flex = styled.div`
display : flex;
padding :10px 20px`;

const Cluster = styled.div`
  width: 600px;
  height: 44px;
  
  padding: 6px 190px 9px;
  background-color: #705c98;
`

let title = "1-2-3 Captions: Workflows for creating accessible video creating  ";

export default function OERTemp(props) {

    const {title, description, duration, language, cluster, format, link, checkpoint,
    checkpointlink, cplink} = props.dataObj
    
    const [clusterColor, setClusterColor] = useState("orange");

    const [bgImg, setBgimg] = useState(www);

    const [showOER, setShowOER] = useState(false);

    const [goto, setGoTo] = useState("test")

    useEffect(()=>{


        console.log("Checkpoint Links")
        checkpointlinks.map(cp => {
            console.log("Checkpoint Number" +cp.checkpoint)
            console.log("Checkpoint Link" +cp.link)
        })

       
        if(format == "HTML")
        {
            setBgimg(www)
        }
        else if(format == "pdf" || format == "PDF")
        {
            setBgimg(pdf)
        }
        else if(format == "youtube" || format == "YouTube")
        {
            setBgimg(youtube)
        }
        else if(format == "VIDEO")
        {
            setBgimg(video)
        }


        
        if(cluster == "No cluster")
        {
            setClusterColor("red")
        }
        else
        if(cluster == "Expression & Communication" || cluster == "Executive Functions" || cluster == "Physical Action")
        {
            // blue
            setClusterColor("#4280b6")
        }
        else
        if(cluster == "Perception" || cluster == "Language & Symbols" || cluster == "Comprehension")
        {
            // purple
            setClusterColor("#705c98")
        }
        else
        if(cluster == "Self Regulation" || cluster == "Sustaining Effort & Persistence" || cluster == "Recruiting Interest" )
        {
            // Green
            setClusterColor("#488a56")
        }

        

    }, [props]);

    
    const myopenOER = (url) => {
        // setShowOER(!showOER)
        window.open(url, '_blank')
    }

    // Return Link based on checkpoint
    const returnLink = (cp)=> {
       
       
        checkpointlinks.map(cpp => {
            console.log("Ye kr k dekhao"+cpp.checkpoint)
           if(cp == cpp.checkpoint)
           {

            console.log("Matched " + cpp.checkpoint)
            return ""+cpp.link
           }
           else
           {
            return "#";
           }
        })

        
    }

    const goToLink= (cp) => {

        console.log("Checkpoint is ****" +cp);
        let finalLink = "";
        

        checkpointlinks.map(cpp => {
         
            if(cpp.checkpoint != cp  )
            {
             finalLink = "#"
            }
            else
            {
             finalLink = ""+cpp.link
             
            }
           
          
            
        })

        return finalLink;
    }
    
    
    
    return (
    <div>
    
     {/* {console.log("Redish" +clusterColor)}    */}
    
    {
        showOER ? 
        <div style={{position: "fixed", top: "25%", bottom: "25%", left: "25%", right: "25%", backgroundColor: "rgba(0,0,0,0.5)",
        }}>
        
                    <div style={{border:"10px solid black", height: "50px", width:"100%", backgroundColor:"black"}}>
                    <Link to="#" className="launch-btn launch-btn-text"
                        style={{backgroundColor:"red", color:"white", top: "26%", position:"fixed",
                        left:"43%"}}
                        // onClick={openOER}
                        >
                            Close
                    </Link>
                    </div>

                    {/* Simple solution for Youtube Video urls :
                    ytUrl = "https://www.youtube.com/watch?v=DGIXT7ce3vQ"
                    // replace:
                    ytUrl.replace('/watch?v=', '/embed/') 
                    Alternative option : OEmbed API : https://oembed.com/#section3*/}
                    <iframe
                    title={"My OER"}
                    frameBorder="5px"
                    
                    height="100%"
                    src={link}
                    width="100%"
                    // sandbox=''
                    />
        </div>
            :
        
            ""
    }


    <div className="oer-parent">
        
        <div className="oer-subhead">
            {/* Picture and Title */}
            
           <div>
               <img 
               className='oer-img'
               src={bgImg}/>
           </div>

            <div className="oer-title-style"> 
                    {
                    title.length > 65 ?
                        title.substring(0,65) + " ..." 
                    :
                        title
                    }
             </div> 

        </div>

        <div className="oer-cluster" style={{backgroundColor:`${clusterColor}`}}>
            <div>
               <label className="cluster-text">{cluster}</label> 
            </div>
        </div>

        <div className="oer-details">
                    
               <b>Description: </b> 
               
               {
                description.length > 150 ?
                description.substring(0,150) + " ..." 
                :
                description
               }
               
               <br/><br/>
               <b>Duration:</b> {duration}
               <br/>
               <b>Language:</b> {language}
               <br/>
               <b>Format:</b> {format}
               <br/>
               {/* Link as per the checkpoint to the right URL */}
               <b>Checkpoint: </b>  
                    <a 
                    href={cplink} target="_blank">{checkpoint}</a> 
            


        </div>

        {/* Launch Button */}
        <div style={{display:"flex", justifyContent:"center"}}>

            <a className="launch-btn launch-btn-text" 
            href={link}
            target="_blank"
            >
                Launch
            </a>

        </div>

    </div>

    </div>
  )
}
