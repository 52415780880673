import React, {useState, useEffect} from 'react';
import axios from 'axios';

import { useCallback } from 'react';

// Default V2 theme
import 'survey-core/defaultV2.min.css';
// Modern theme
// import 'survey-core/modern.min.css';
import { StylesManager, Model } from 'survey-core';
import { Survey } from 'survey-react-ui';

import { json } from "../survey_json_demog";

import { Link, useLocation, useHistory } from "react-router-dom";


import {useParams} from 'react-router-dom';
import Affiliation from './Affiliation';
import Footer from './Footer';


import Modal from './Modal';

import { useTranslation } from 'react-i18next';


// const SURVEY_ID = 1;

StylesManager.applyTheme("defaultV2");



const surveyJson = {
  elements: [{
    name: "FirstName",
    title: "Enter your first name:",
    type: "text"
  }, {
    name: "LastName",
    title: "Enter your last name:",
    type: "text"
  }]
};


export default function Demographics() {

  const {id} = useParams();
  const [showModal, setShowModal] = useState(false)

  let lang =  localStorage.getItem("key")
  console.log("Patel" + lang)

  const { t } = useTranslation();
  const { i18n } = useTranslation();

  useEffect(()=>{
          // const {id} = useParams();
          console.log("Current User id is " +id)
  }, [])





// Tutorial for incorporating Survey.js in react application : https://www.youtube.com/watch?v=NW0GwiHmNik
// Need the id of current user, so that releveant details can be added for the user
const survey = new Model(json);
const withLocale = Object.assign( survey, {"locale": ""+lang})

console.log("Fixit" +JSON.stringify(withLocale))

const alertResults = useCallback((sender) => {
  const results = JSON.stringify(sender.data);
 
   

   console.log("Demographics data object is " +results);

   axios.put('https://app.udl-boe.eu/api/baupdate/'+id, sender.data).
  //  axios.put('http://localhost:5000/api/baupdate/'+id, sender.data).
      then(res => {
        console.log("Demographics return" + res)
      })
      .catch(err =>{
        console.log(err)
      })

      // setShowModal(true)

    
    // alert("Thank you for adding your demographic details");

    



    // Remove Dialog and redirect to results page of same user 
    // November 2022
    window.location.href="/results/"+id;
  
  // saveSurveyResults(
  //   "https://your-web-service.com/" + SURVEY_ID,
  //   sender.data
  // )


}, []);

survey.onComplete.add(alertResults);


// Update CSS Design for Demographic Questions
// As per the UDL BOE Questions Survey Design
survey.css= {
    question : {
        title : "title_ba"
    },

    radiogroup:{
        root : "radioroot",
        item: "uncheckedradio",
        itemChecked: "checkedradio",
        controlLabel : "radioLabel", 
    },
    checkbox: {
       
        item: "default-checkbox",
        itemChecked: "checked-checkbox",
        controlLabel : "checkboxLabel"
       
    }
}




// Shows the Demographics questionnaire 2 pages, which is a separte survey
return (

<div 
className="demographicbox"
>
<Survey model={survey} />

  <div 
  className='footer'
  style={{position:"fixed", bottom:"0"}}>
    {/* <Affiliation/> */}
    <Footer/>
  </div>



  <Modal 
  heading= "Survey Completed"
  show={showModal}>
            {/* <Modal show> */}
                <div>
                <h4>Thank you for completing our Research Survey. Click the button below to go back to start again.</h4>
                   
                

                <div style={{ display:"flex",columnGap:"3em", justifyContent:"center"}}>
                <div style={{display:"flex", justifyContent:"center"}}>
                    <Link
                    to="/"
                    className='yellowButton yellowButtonText'
                    style={{padding:"0px", width:"10em"}}
                    >
                        Home
                    </Link>
                </div>


                  

                    </div>
                    
                    



                
                    
               

             
                

                
               

               

             
              
                
             

                

               

                {/* <button
                onClick={e => setShowModal(false)}>Close</button> */}

               

                

                </div>
            </Modal>

</div>
);
}
