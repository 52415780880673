import React, {useState} from 'react'
import Recommendation from './Recommendation';
import { Link } from "react-router-dom";

export default function OERs() {

    const [cb, setCB] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);

  return (
    <div style={{ paddingBottom:"50px"}}>
        
       
        {/* Heading */}
        <h1 className="head-style">Your Learning Resource Options</h1>

        {/* Recommendation Component */}
        {
            cb.map((item,index)=>{
                return(
                    <div style={{backgroundColor:"lightgrey", paddingLeft: "20%"}}>
                    <Recommendation
                    item={item}/>
                    </div>
                )
            })
        }

        {/* Footer with Buttons and Enter email  */}
        <div style={{ paddingTop: "20px", paddingLeft: "15%", backgroundColor:"lightgrey", height: "100px"}}>
        <Link to="/oers"
        className='yellowButton yellowButtonText'>
            Email my Results
        </Link>

        <Link to="/oers"
        className='yellowButton yellowButtonText'>
            Download Pdf
        </Link>

        <Link to="/oers"
        className='yellowButton yellowButtonText'>
            Return to Assessment Results
        </Link>
        </div>
        
        

    </div>
  )
}
