import React from "react";
import { Doughnut } from "react-chartjs-2";
import styled from "styled-components";
import {Chart} from 'react-chartjs-2'

const Wrapper = styled.div``;

const Gauge = ({ id = "gauge" }) => {

  const labels = [
    'Not At All Confident',
    'Somewhat confident',
    'Confident',
    'Very Confident',
    
  ];

  var data = {
    labels: labels,
    datasets: [
      {
        data: [3, 10, 10, 10],
        backgroundColor: [
          "#cb300d",
          "#eb722e",
          "#f5e144",
          "#8bd658",
          "#CC9933",
          "#006666",
          "#3399FF",
          "#993300",
          "#CCCC99",
          "#666666",
          "#FFFFFF",
          "#FFFFFF",
          "#FFFFFF"
        ],
        display: true,
        borderColor: "#D1D6DC"
      }
    ]
  };

  const options = {
    rotation: 1 * Math.PI,
    circumference: 1 * Math.PI,
    // legend: {
    //   display: false
    // }
  };

  Chart.pluginService.register({
    beforeDraw: function (chart) {
      var ctx = chart.chart.ctx;
      ctx.font = 2 + "em sans-serif";
      ctx.textBaseline = "bottom";
      ctx.fillStyle = "red";
      // ctx.fillText("Text Here", width / 2 - 60, height / 2 + 100);
      ctx.save();
    }
  });

  return (
    <div>
      <Wrapper>
        <Doughnut
          height="48px"
          width="174px"
          id={id}
          data={data}
          options={{
            responsive: true,
            onClick: (evt, element) => {
              if (element.length > 0) {
                var ind = element[0].index;
                console.log(ind);
              }
            },
            ...options
          }}
        />
      </Wrapper>
    </div>
  );
};

export default Gauge;
