import React from 'react';
import { useMediaQuery } from 'react-responsive'


export default function LeftIntro(props) {

  const isLaptop = useMediaQuery({
    query: "(min-device-width: 1280px)",
  });

  const isBigScreen = useMediaQuery({
    query: "(min-device-width: 1920px )",
  });


  return (

    <div >
      
         <div className="box-outline">
    
       
       
        <div className="title-bg">
          <label className='title-style'>{props.heading}</label> 
        </div>
    
        <div className="description-style">
        {props.text}
    
    
        {/* {props.assess ? <><h4>Click start to begin.</h4></> : ""} */}
        </div>
    
        </div>
        
        
      


    </div>

    
   
  )
}
