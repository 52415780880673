import React from 'react'

export default function ProgressBar({currentPage}) {
  return (
      <>
    <div
    style={{backgroundColor:"white"}}>
        ProgressBar {currentPage}


        {/* Circle Start */}
        <div>
        <div className="inner-circle">
                abc
            </div>
        
        <div className="outer-circle">
            &nbsp;
        </div>

        <div className="outer-2nd-circle">
            &nbsp;
        </div>
        </div>
         {/* End */}
        

        
        </div>
    
    </>
  )
}
