import React, {useState, useEffect} from 'react';
import Flags from './Flags';
import LeftIntro from './LeftIntro';
import RightIntro from './RightIntro';

import { useTranslation } from 'react-i18next';
import useForceUpdate from 'use-force-update';
import { Link } from "react-router-dom";


import useLocalStorage from 'react-localstorage-hook';


export default function MainSectionsm() {

  

  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const forceUpdate = useForceUpdate();

  const [currentLang, setCurrentLang] = useState('en');

  
  
  

  // Store current Language in local storage
  // Get current Langauge in useEffet hook
  // 2 STEPS PROCESS
  // In change lanaugae, store the changed Language to local storage
  //  localStorage.setItem("key",lang)
  // Step 2 - In useEffect hook, get the stored value and set the state of currentLang based on that
  // setCurrentLang(localStorage.getItem("key"))

  useEffect(()=> {
    console.log("Currrrrrrrrrrent lang" +currentLang)
    
    console.log("Language stored is : " +localStorage.getItem("key"));

    // Step 2 - update the currentLang, based on local storage
    setCurrentLang(localStorage.getItem("key"))
  }, [])

  const changeLanguage = (e, lang) => {
    console.log("Language changed" +lang)
    i18n.changeLanguage(lang);

    // Step 1 - Update the stored language here based on change by user
    localStorage.setItem("key",lang)

    setCurrentLang(lang);

    forceUpdate();
    
  }

  return (
    <div>

      <div>
        <Flags
        changeLanguage={changeLanguage}
        currentLang={currentLang}/>
      </div>


      <div 
      className="main-section">


          <div style={{display: "flex", justifyContent:"space-evenly", 
            paddingTop:"2%",  }}>
          
                {/* Left Info Box */}
                <LeftIntro text={t('focus')} heading={t('focushead')}/>
          
                <LeftIntro text={t('aims')} heading={t('aimshead')}/>
          
                <LeftIntro text={t('framework')} heading={t('framehead')}/>
          
                
          
            </div>

            <div style={{display: "flex", justifyContent:"space-evenly", 
            paddingTop:"2%", }}>
               <LeftIntro text={t('assess')} heading={t('assesshead')}
                assess={true}/>
            </div>

            
            <div style={{marginTop:"2%", display:"flex", justifyContent:"center"}}>
              <Link to="/udlintro"
              className='yellowButton yellowButtonText'
              state= {{currentLang : currentLang}}
              >  
              {t('startbtn')}
              </Link>
            </div>
            
            



       
      </div>


    

          

          {/* <div style={{display: "flex", justifyContent:"space-evenly",
        paddingBottom:"200px"}}>
            
            

         


          </div> */}
          </div>
  )
}
