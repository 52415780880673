import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { LinkText } from './LinkText';

export default function Affiliation() {

  const { t } = useTranslation();


  return (
    <div
    className="affiliation-div"
    >
        
       

        {/* <p>
            This project is part of the <a href="https://udl-boe.eu/" target="_blank">UDL-BOE Erasmus + project</a> which is funded by the European Union to support post-primary
            teachers in building inclusive digital learning experiences.
       
            If you want any information about the project and how the results will be used, please contact 
             
             <a href="mailto:info@udl-boe.eu?subject= Need More Info about UDL BOE & body=Please send me more details about UDL"> info@udl-boe.eu</a>.
        </p> */}

        <p>
        {/* {t('acknow')} */}

        {/* Add Link inside Translations
        1. In JSON File, wrap the link with tag like <link1></link1>
        2. Use Trans component and pass the i18nKey which is in the json file
        3. Inside components prop, pass the link1 and as a parameter pass the newly created 
        LinkTextcomponent, which will require 'to' attribute
        More details : https://www.createit.com/blog/i18next-react-links-inside-translations/ */}
        <Trans
        i18nKey="acknow"
        components={{
          link1 : <LinkText
          to="mailto:info@udl-boe.eu?subject= Need More Info on UDL BOE!&body=Please send me more details on UDL BOE!" 
          title="Email UDL BOE Team"/>
        }}
        />

        </p>
    
    </div>
  )
}
