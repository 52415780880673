import React from 'react';

export default function Footer() {
  return(
    
      <div className="footer-div">

          <p style={{color: "white"}}>
              <center>
          © Project UDL-BOE 2022. All rights reserved. 
                <br/>

            This project was funded by the European Union’s Rights, Equality and Citizenship Programme (2014-2020).
            </center>
          </p>
        
        </div>
      
      );
}
