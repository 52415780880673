import React, {useState, useEffect} from 'react';
import oerslist from '../sheets/oers_list.xlsx';
import * as XLSX from 'xlsx';
import OERTemp from './OERTemp';
import { Link } from "react-router-dom";
import useForceUpdate from 'use-force-update';

// import json data for OERs
import oerDataFile from './oerdata';
import FilterRadio from './FilterRadio';

// Extract data from Excel Sheet 
// Iterate over it and then generate the divs accordingly using e.g. map function
// https://dev.to/shareef/convert-excel-to-json-in-reactjs-in-just-2-steps-4pa1

// Information to add for each OER
// 1. Icon
// 2. Title
// 3. Description
// 4. Duration
// 5. Language
// 6. Blended
// 7. Link for Launch Button




export default function DynamicOerssm() {

    const forceUpdate = useForceUpdate();
    
    const [oerData, setOERData] = useState(null);

    const [dataObj, setDataObj] = useState(null);

    const [showLoadBtn, setShowLoadBtn] = useState(true);


    let testObj = [];
    
    useEffect(()=>{
        
        oerDataFile.map( (j, index) => {
            console.log("Titless : " + j['__EMPTY'] + "\n" + "Description: " + j['__EMPTY_1'] 
            + "\n" + "Duration: " +j['__EMPTY_17'] + "\n" + "Language" + j['__EMPTY_2'] + "\n Format " + j['__EMPTY_12']
            + "\n Cluster " +j['__EMPTY_16'] + "\n Link: "+j['__EMPTY_9']);

            
           if(index <2){

           
                testObj.push (
                    {
                        title : j['__EMPTY']? j['__EMPTY'] : "Sample Title and a Long one Very Long one Sample Title and a Long one Very Long oneSample Title and a Long one Very Long oneSample Title and a Long one Very Long one",
                        description: j['__EMPTY_1']? j['__EMPTY_1'] : "Sample Description" ,
                        duration:  j['__EMPTY_17'] ?j['__EMPTY_17'] : "0 mins",
                        language : j['__EMPTY_2']?j['__EMPTY_2'] : "Language Agnostic" ,
                        cluster : j['__EMPTY_16']? j['__EMPTY_16'] : "No cluster",
                        format : j['__EMPTY_12'] ? j['__EMPTY_12'] : "www",
                        link : j['__EMPTY_9'] ? j['__EMPTY_9'] : "https://udl-boe.eu/"
                    })
            
            }
           
            

        })

        setDataObj(testObj)
    }, [])
    

    const [oerD, setOERD] = useState(
        [
            {icon : "youtube",
            title : "1-2-3 Captions: Workflows for creating accessible videos",
            cluster : "Language & Symbols",
            description : "Webinar with handout slides on making videos accessible",
            duration: "1:01:33",
            language: "English",
            environment: "blended"
            },
            {icon : "youtube",
            title : "1-2-3 Captions: Workflows for creating accessible videos",
            cluster : "Language & Symbols",
            description : "Webinar with handout slides on making videos accessible",
            duration: "1:01:33",
            language: "English",
            environment: "blended"
            },
            {icon : "youtube",
            title : "1-2-3 Captions: Workflows for creating accessible videos",
            cluster : "Language & Symbols",
            description : "Webinar with handout slides on making videos accessible",
            duration: "1:01:33",
            language: "English",
            environment: "blended"
            },
            {icon : "youtube",
            title : "1-2-3 Captions: Workflows for creating accessible videos",
            cluster : "Language & Symbols",
            description : "Webinar with handout slides on making videos accessible",
            duration: "1:01:33",
            language: "English",
            environment: "blended"
            },
            {icon : "youtube",
            title : "1-2-3 Captions: Workflows for creating accessible videos",
            cluster : "Language & Symbols",
            description : "Webinar with handout slides on making videos accessible",
            duration: "1:01:33",
            language: "English",
            environment: "blended"
            },
            {icon : "youtube",
            title : "1-2-3 Captions: Workflows for creating accessible videos",
            cluster : "Language & Symbols",
            description : "Webinar with handout slides on making videos accessible",
            duration: "1:01:33",
            language: "English",
            environment: "blended"
            }

        ]
    )

    const readUploadFile = (e) => {
        e.preventDefault();
        if (e.target.files) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = XLSX.utils.sheet_to_json(worksheet);
                console.log("Sheet name" + sheetName)
                console.log("Raw" + JSON.stringify(json));

                // let testObj = [];
                // json.map(j => {
                //     console.log("Title : " + j['__EMPTY'] + "\n" + "Description: " + j['__EMPTY_1'] 
                //     + "\n" + "Duration: " +j['__EMPTY_17'] + "\n" + "Language" + j['__EMPTY_2'] + "\n Format " + j['__EMPTY_12']
                //     + "\n Cluster " +j['__EMPTY_16']);

                //     testObj.push (
                //     {
                //         title : j['__EMPTY']? j['__EMPTY'] : "Sample Title and a Long one Very Long one Sample Title and a Long one Very Long oneSample Title and a Long one Very Long oneSample Title and a Long one Very Long one",
                //         description: j['__EMPTY_1']? j['__EMPTY_1'] : "Sample Description" ,
                //         duration:  j['__EMPTY_17'] ?j['__EMPTY_17'] : "0 mins",
                //         language : j['__EMPTY_2']?j['__EMPTY_2'] : "Language Agnostic" ,
                //         cluster : j['__EMPTY_16']? j['__EMPTY_16'] : "No cluster"
                //     })

                // })

               

                setDataObj(testObj)

                setOERData(json);

                


            };
            reader.readAsArrayBuffer(e.target.files[0]);
        }


        

    }



    const loadOERs = e => {
        e.preventDefault();
        console.log("Loading OERs 3 each time" +dataObj.length)
        let length = dataObj.length;

        let tempData = dataObj

        // set dataObj
        // add 3 items 
        oerDataFile.map( (j, index) => {
           if(index >=length && index <=length+1){

           
                tempData.push (
                    {
                        title : j['__EMPTY']? j['__EMPTY'] : "Sample Title and a Long one Very Long one Sample Title and a Long one Very Long oneSample Title and a Long one Very Long oneSample Title and a Long one Very Long one",
                        description: j['__EMPTY_1']? j['__EMPTY_1'] : "Sample Description" ,
                        duration:  j['__EMPTY_17'] ?j['__EMPTY_17'] : "0 mins",
                        language : j['__EMPTY_2']?j['__EMPTY_2'] : "Language Agnostic" ,
                        cluster : j['__EMPTY_16']? j['__EMPTY_16'] : "No cluster",
                        format : j['__EMPTY_12'] ? j['__EMPTY_12'] : "www",
                        link : j['__EMPTY_9'] ? j['__EMPTY_9'] : "https://udl-boe.eu/"
                    })
            
            }
            else if(length >= 87)
            {
                console.log("You are a Pro, Explored all OERs")
                // Hide Load More Btn
                setShowLoadBtn(false);
            }
           
            

        })

        setDataObj(tempData)
        
        forceUpdate()


        



    }


    
   
  
    
  return (
    <div style={{height:"95vh"}}>
        
        {/* DynamicOers */}

        {/* File Element to select excel sheet */}
        {/* <form>
        <label htmlFor="upload">Upload File</label>
        <input
        type="file"
        name="upload"
        id="upload"
        onChange={readUploadFile}
        />
        </form> */}

        <div style={{border: "1px solid black"}}>
            {/* Single selection radio button for Type, Cluster, Language, Duration */}
            Sort By : <FilterRadio/>
        </div>


        

        {/* Dynamic OERS based on data */}
        <div style={{ height:"85vh"
    }}>
        
        
        
        
       
        {
            dataObj && dataObj.map((oer,idx) => {
                // Break line after every third oer
                // https://stackoverflow.com/questions/25426418/css-and-html-2-divs-on-one-line-then-go-next-line
                    console.log("..." +idx)
                
                
                    return(
                    <div style={{float:"left", marginBottom:"20px"}}>
                    <OERTemp
                    dataObj = {oer} />
                    </div>
                    )  
                
                

                
                

            })
        }
        
        <br/>

       {
       showLoadBtn ?
       <div style={{marginLeft:"45%", marginTop:"25%"}}>
        <Link to="/" className="launch-btn launch-btn-text"
        onClick={loadOERs} style={{backgroundColor:"red", color:"white",}}>
                Load More
        </Link>
        </div> : <h1> Legend! You are a Pro, and have explored all the courses</h1>
        }
        


        </div>


        
        

      
        

    </div>


    
  )
}
