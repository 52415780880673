import logo from './logo.svg';
import './App.css';
import { SurveyPage } from "./Survey";

import React, {useState, useEffect} from 'react';
import axios from 'axios'

import Navbar from './components/Navbar';
import Footer from './components/Footer';
import LeftIntro from './components/LeftIntro';
import RightIntro from './components/RightIntro';

import { BrowserRouter as Router, Route, Routes, Switch } from "react-router-dom";
import MainSection from './components/MainSection';
import UDLIntro from './components/UDLIntro';
import QuestionOverview from './components/QuestionOverview';
import OERs from './components/OERs';
import Results from './components/Results';
import DynamicOers from './components/DynamicOers';
import CookieConsent, { Cookies, getCookieConsentValue } from "react-cookie-consent";

import { useMediaQuery } from 'react-responsive'


import MainSectionsm from './components/MainSectionsm';
import Resultssm from './components/Resultssm';
import TestSass from './components/TestSass';
import Affiliation from './components/Affiliation';
import Demographics from './components/Demographics';
import Data from './components/Data';

import CookiePolicy from './components/CookiePolicy';

import { Link, useLocation} from "react-router-dom";

function App() {


  const [name, setName] = useState("")
  const [num, setNum] = useState(0);

  // show/hide the cookie bar
  const [showCookieBar, setShowCookieBar] = useState("show")





  const isLaptop = useMediaQuery({
    query: "(min-device-width: 1280px)",
  });

  const isBigScreen = useMediaQuery({
    query: "(min-device-width: 1920px )",
  });


  const isMobileDevice = useMediaQuery({
    query: "(min-device-width: 480px)",
  });

  const isTabletDevice = useMediaQuery({
    query: "(min-device-width: 768px)",
  });

 
  const isDesktop = useMediaQuery({
    query: "(min-device-width: 1200px)",
  });

 















  
  useEffect(() => { 

    window.scrollTo(0,0)
    // axios.get('http://52.209.244.61:5000/listcountries')
    // .then(response => {
      
    //   console.log("xxx"+ JSON.stringify(response.data))
    //   console.log("Number is " + response.data.length)
    //   setNum(response.data.length)
    //   });


      if(isBigScreen){
        console.log("This is a big screenss")
      }
      else
      if(isLaptop)
      {
        console.log("This is a laptop")
      }
      

     


  })
  
  
  const sendDataTest = (e) => {
    e.preventDefault()
    console.log("...." +name)

    const obj = {
      country : "Pakistan",
      city : name
    }

    axios.post('http://52.209.244.61:5000/countries/createcountry', obj)
        .then(response => console.log("xxx"+ JSON.stringify(response.data)));

  }

  const changeName = (value) => {
      setName(value)
  }



  const showCookieDetails = ()=>{
    console.log("Show cookie details")
    window.location.href="/cp"
  }

  // Cookies accept method
  const cookiesAccepted = (acceptedByScrolling) => {
    if(acceptedByScrolling){
      // alert("Oh Wow! You Love cookies :) ")
      // Hide cookie bar
      setShowCookieBar("hidden")
    }
    else
    {
      // alert("Oh Wow! You Love cookies :) ")
      console.log(getCookieConsentValue());
      // Hide cookie bar
      setShowCookieBar("hidden")
    }
  }

  // Cookies rejected method
  const cookiesRejected = () => {
    // alert("Oh No!You seem to be least interested in Cookies :( ")
    // Hide cookie bar
    setShowCookieBar("hidden")  
  }

  return (
    <div>

{/* Remove change cookie preferences button 29 March 2023 */}
    {/* <div 
    style={{position: "absolute", 
        backgroundColor:"#333", width:"12%", 
        height:"70px",
          right:0, 
          visibility: `${showCookieBar == "show"? "hidden" : "visible"}`
          }}>
          <button
          className="cookie-btn"
          onClick={()=> {
            setShowCookieBar("show")
          }}>
            Change Cookie Preferences
          </button>
      </div> */}

      <Navbar/>

      <Router>
              <Routes>

              <Route exact path="/" element={<MainSection/>}/>
                {/* Add specific Route for Dutch language */}
                <Route exact path="/nl" element={<MainSection/>}/>
                

                <Route exact path="/udlintro" element={<UDLIntro/>}/>
                <Route exact path="/mainquestions" element={<SurveyPage/>}/>
                <Route exact path="/dynoers" element={<DynamicOers/>}/>
                <Route exact path="/results/:id" element={<Results/>}/>
                <Route exact path="/oers" element={<OERs/>}/>
                {/* <Route exact path="/udlintro" element ={<Affiliation/>}/> */}

                <Route exact path="/demographics/:id" element={<Demographics/>}/>

                <Route exact path="/data" element={<Data/>}/>

                <Route exact path="/cp" element={<CookiePolicy/>}/>
                
                
  
              </Routes>
      </Router>

      


       {/* Add React Cookie Component Here */}
       <CookieConsent
           location="bottom"
           enableDeclineButton flipButtons
           onAccept={cookiesAccepted}
           onDecline={cookiesRejected}
           cookieName="udlboecookie"
           expires={999}
          //  overlay
          //  debug={true}
            visible={showCookieBar}
           style={{ background: "black", height: "70px" }}>
           This website uses cookies to improve your experience. By clicking “I understand” button, you agree to the storing of cookies on your device to enhance site navigation and analyse site usage. 
            
             {/* <a href="#">Learn More </a> */}

             <button
             style={{backgroundColor:"black", color: "white", textDecoration:"underline", fontFamily:"Ubuntu", fontSize:"18px"}}
             onClick={showCookieDetails}> Learn More </button>

            {/* <div><Link to="/cookiepolicy">Cookie Policy</Link></div> */}
          </CookieConsent>

          {/* <Link to={`/cookiepolicy`}>Cookie Policy</Link> */}



          {/* <div><Link to="/cp">Cookie Policy</Link></div> */}
          
    </div>
  );
}

export default App;
