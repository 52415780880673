import React, {CSSProperties} from 'react';
import GridLoader from "react-spinners/GridLoader";

const override = {
    display: "block",
    margin: "15% 45%",
    
  };

export default function Spinner() {

    


  return (
    <div
    style={{ width:"100%", height:"120%", position:"absolute",
zIndex:"5", }}
    >
       
    
        {/* <GridLoader	  size={50}
        cssOverride={override}
        color={"#007ac0"}
         /> */}
    
    </div>
  )
}
