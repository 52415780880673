import React, {useState, useEffect} from 'react';
import Flags from './Flags';
import LeftIntro from './LeftIntro';
import RightIntro from './RightIntro';

import { useTranslation } from 'react-i18next';
import useForceUpdate from 'use-force-update';
import { Link, useLocation } from "react-router-dom";


import useLocalStorage from 'react-localstorage-hook';
import Affiliation from './Affiliation';
import Footer from './Footer';



export default function MainSection() {

  

  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const forceUpdate = useForceUpdate();

  const location = useLocation();

  const [currentLang, setCurrentLang] = useState('en');

  // Get current URL path name
  const [currentPath, setCurrentPath] = useState('')

 
  window.onload = function() {
    console.log("Refresh")
    // On Home Page Refresh,change default language to English
    localStorage.setItem("key", "en")
    setCurrentLang("en")
  };
  

  // Store current Language in local storage
  // Get current Langauge in useEffet hook
  // 2 STEPS PROCESS
  // In change lanaugae, store the changed Language to local storage
  //  localStorage.setItem("key",lang)
  // Step 2 - In useEffect hook, get the stored value and set the state of currentLang based on that
  // setCurrentLang(localStorage.getItem("key"))

  useEffect(()=> {

    //  Get current URL
    // May 2023 - Separate Link for Dutch Language
    // Remove the select option from the menu 
    

    
    console.log("Uth Oye" + location.pathname)
    setCurrentPath(location.pathname? location.pathname : "")

    if(currentPath == "/nl" || currentPath == "/NL" || currentPath == "/Nl"){
      localStorage.setItem("key", "du")
      setCurrentLang("du")
      i18n.changeLanguage("du") 
      forceUpdate()
    }
    else
    {
    console.log("Currrrrrrrrrrent lang" +currentLang)
    console.log("Language stored is : " +localStorage.getItem("key"));
    // Step 2 - update the currentLang, based on local storage
    setCurrentLang(localStorage.getItem("key"))

    // if(localStorage.getItem("key") !== "en")
    // {
    //   console.log("Selected language is not English")
    //   setCurrentLang("en")
    // }

  }
     
  }, [currentPath, currentLang])

  const changeLanguage = (e, lang) => {
    console.log("Language changed" +lang)
    i18n.changeLanguage(lang);

    // Step 1 - Update the stored language here based on change by user
    localStorage.setItem("key",lang)

    
    
    setCurrentLang(lang);



    forceUpdate();
    
  }

  return (
    <div>


<div className="flags-bg" >
        <div
        style={{position:"absolute", right:10, paddingTop:"1em"}}>
       
       {/* Hide this menu for Dutch Language route /nl */}
       {

        currentPath.length<=1? 
        <>
        <label
        className="choose-lng">{t('chooselang')} </label>

        <select value={currentLang} onChange={e=> changeLanguage(e, e.target.value)} className="selectlangclass">
            <option value="en">English</option>
            <option value="gr">Greek</option>
            <option value="du">Dutch</option>
            <option value="es">Spanish</option>
          </select>
          </>
          :
          ""
       }
        




          </div>
  </div>

      {/* <div>
        <Flags
        changeLanguage={changeLanguage}
        currentLang={currentLang}/>

          Select one menu

          

      </div> */}


      <div 
      className="main-section"
      >


          <div style={{display: "flex", justifyContent:"space-around", 
           padding: "2em" }}>
          
                {/* Left Info Box */}
                <LeftIntro text={t('focus')} heading={t('focushead')}/>
          
                <LeftIntro text={t('aims')} heading={t('aimshead')}/>
          
                <LeftIntro text={t('framework')} heading={t('framehead')}/>
          
                <LeftIntro text={t('assess')} heading={t('assesshead')}
                assess={true}/>
          
            </div>

            
            <div style={{display:"flex", justifyContent:"center"}}
            className="btn-main-section">
              <Link to="/udlintro"
              className='yellowButton yellowButtonText'
              state= {{currentLang : currentLang}}
              >  
              {t('startbtn')}
              </Link>
            </div>
            
            



       
      </div>


    

          

          {/* <div style={{display: "flex", justifyContent:"space-evenly",
        paddingBottom:"200px"}}>
            
            

         


          </div> */}

      <div 
      className="footer"
      style={{position:"fixed", bottom:0, height:"160px"}}>
                <Affiliation/>
                <Footer/>
      </div>


          </div>
  )
}
