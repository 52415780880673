import React, {useState} from 'react';

export default function FilterRadio() {

    const [sortby, setSortby] = useState("None");

    function onChangeValue(event) {
        setSortby(event.target.value);
        console.log(event.target.value);
      }

  return (
    <div>

    <div onChange={onChangeValue}>
      <input type="radio" value="None" name="sort" checked={sortby === "None"} /> None
      <input type="radio" value="Cluster" name="sort" checked={sortby === "Cluster"} /> Cluster
      <input type="radio" value="Type" name="sort" checked={sortby === "Type"} /> Type
      <input type="radio" value="Duration" name="sort" checked={sortby === "Duration"} /> Duration (Short to Long)
      <input type="radio" value="Language" name="sort" checked={sortby === "Language"} /> Language
      <input type="radio" value="Environment" name="sort" checked={sortby === "Environment"} /> Environment
      
    </div>

    </div>
  )
}
