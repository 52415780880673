import React, {useState, useEffect} from 'react';

const CustomProgressBar = (props) => {

    const [done, setDone] = useState(0);

    

    const{bgcolor, bgcolorcomp, completed, label} = props;


    useEffect(()=>{
          
        console.log("£££££££££££££££Progress Bar Start £££££££££££££££")

        setTimeout(()=> {
            setDone(completed)
            }, 100)

            console.log("£££££££££££££££Progress Bar End ££££££££££££££");
    }, [bgcolor, bgcolorcomp, completed, label])

    const pgbParent = {
        width: "100%",
        height: "27px",
        backgroundColor: `${bgcolor}`,
        
        
    }

    const pgbCompleted = {
        width: `${done}%`,
        height: "27px",
        backgroundColor: `${bgcolorcomp}`,
        borderRadius: "10px",
        transition: 'width 1s ease-in-out',
    }

    const pgbLabel = {
        
        height: "29px",
        fontFamily: "Ubuntu",
        fontSize: "24px",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        textAlign: "left",
        color: "#333"
    }

    return(
        <div style={{
            width: "23.5em", padding: "0.3em"}}>
        <div style={pgbParent}>
            {/* The above is parent div, shows the complete bar */}
            <div style={pgbCompleted}>
                {/* Inner Div which shows percentage of completion */}
                
            </div>
        </div>

        <div style={pgbLabel}>
                {label}
        </div>
        
        </div>
    )

}


export default CustomProgressBar;