import React from "react";
import { RadarData, RadarOptions } from "./RadarConfig";
import { Radar } from "react-chartjs-2";

// https://stackoverflow.com/questions/67727603/error-category-is-not-a-registered-scale
// import { Chart as ChartJS } from 'chart.js/auto';

export class RadarChart extends React.Component {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
  }

  componentDidMount() {
    console.log(this.chartRef);
  }
//   https://stackoverflow.com/questions/57385938/how-to-get-react-chartjs-to-resize-back-down-with-window
  render() {
    return (
        <div style={{position: "relative", width: "400px", height: "400px"}}>
            

            <Radar ref={this.chartRef} data={RadarData} options={RadarOptions} 
            />
      </div>
    );
  }
}