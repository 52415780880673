import React from 'react'

export default function CookiePolicy() {
  return (
    <div style={{color: "white", backgroundColor:"#f2f3f3", color:"black", justifyContent:"center", fontFamily:"Arial", paddingLeft:"25%", paddingRight:"25%",
    
    margin: "-2em"}}>

      <h1 style={{justifyContent:"center", display:"flex"}}>
        UDL-BOE Self Evaluation Tool Cookie Policy
      </h1>

      
    
      <p style={{textAlign:"justify"}}>
      UDL-BOE is an Erasmus+ project. Erasmus+ projects are funded by the European Commission. Erasmus+ fund organisations across Europe to work and learn together to improve education and training in their individual countries. The UDL-BOE project team is comprised of organisations from four countries, Ireland, Greece, Spain, and Belgium; Trinity College Dublin (Ireland), St Joseph's Secondary School (Ireland), CVO Vitant (Belgium), Ellinogermaniki Agogi (Greece), and Universidad Autónoma de Madrid (Spain).
     

      
      "The UDL-BOE project team respect the rights of users of the UDL-BOE Self Evaluation Tool. The UDL-BOE Self Evaluation Tool is currently hosted at the following domains:

        <br/>
        <br/>

        https://udl-boe.eu/
      
        <br/>
        <br/>

     
      We are committed to protecting your privacy in line with the Data Protection Acts 1988 and 2003 and General Data Protection Regulation (GDPR) 2018 at all times. We will not collect any personal data ( information) about you on this site without your permission or otherwise. This is in line with the Data Protection Acts 1988 and 2003 and General Data Protection Regulation (GDPR) 2018.
     

      
        <h2>Technical data we collect</h2>
        By simply visiting our site, you do not disclose (share), nor do we collect, personal data about you. The information collected about your visit is limited to technical data like:

        <ul>
          <li>the Internet address (or IP address) of the device you used to access our site;</li>
          <li>whether you reached our site using a search engine or if you clicked a link on another website; and</li>
          <li>the type of web browser you used and the type of device you used to access the Internet.</li>
        </ul>

        <h2>How we use technical information</h2>
        We use technical data for administrative and statistical purposes as well as to help us improve our site. This technical data does not provide us with the personal data of visitors to our site.

        <h2>Cookies</h2>
        <h2>Cookies we use</h2>
        A cookie is a small piece of data (text file) that a website – when visited by a user – asks your browser to store on your device in order to remember information about you, such as your language preference or login information. Those cookies are set by us and called first-party cookies. More specifically, we use cookies and other tracking technologies for the following purposes:

        <h2>Strictly Necessary Cookies</h2>
        These cookies are necessary for the UDL-BOE Self Evaluation Tool to function and cannot be switched off in our systems. They are usually only set in response to actions made by you, such as setting your privacy preferences. You can set your browser to block or alert you about these cookies, but if you do so some parts of the site will not work. These cookies do not store any personally identifiable information.

        <br/>

        <table className="table">
          <tr>
            <th>Cookie Subgroup</th>
            <th>Cookies</th>
            <th>Cookies used</th>
            <th>Lifespan</th>
          </tr>

          <tr>
            <td>udl-boe.eu</td>
            <td>cookieconsent_status</td>
            <td>First party</td>
            <td>Session</td>
          </tr>

          {/* <tr>
            <td>?</td>
            <td>?</td>
            <td>?</td>
            <td>?</td>
          </tr>

          <tr>
            <td>?</td>
            <td>?</td>
            <td>?</td>
            <td>?</td>
          </tr>

          <tr>
            <td>?</td>
            <td>?</td>
            <td>?</td>
            <td>?</td>
          </tr> */}
        </table>

        <h2>Performance Cookies</h2>
        These cookies allow us to count visits and traffic sources (how you ended up on the UDL-BOE Self Evaluation Tool) so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site. All information these cookies collect is aggregated and therefore anonymous. If you do not allow these cookies we will not know when you have visited our site, and will not be able to monitor its performance.

        {/* <table className="table">
          <tr>
            <th>Cookie Subgroup</th>
            <th>Cookies</th>
            <th>Cookies used</th>
            <th>Lifespan</th>
          </tr>

          <tr>
            <td>udl-boe.eu</td>
            <td>cookieconsent_status</td>
            <td>First party</td>
            <td>Session</td>
          </tr>

          <tr>
            <td>?</td>
            <td>?</td>
            <td>?</td>
            <td>?</td>
          </tr>

          <tr>
            <td>?</td>
            <td>?</td>
            <td>?</td>
            <td>?</td>
          </tr>

          <tr>
            <td>?</td>
            <td>?</td>
            <td>?</td>
            <td>?</td>
          </tr>
        </table> */}

        <br/>
        <br/>
        <br/>


      </p>

      


    </div>
  )
}
