import React, {useState} from 'react';

import { ImInfo } from 'react-icons/im';



export default function Recommendation(props) {


const [cb, setCB] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
   
console.log("Batinda" +props.item)

  return (
    <div>

        <div style={{display: "flex", paddingTop:"10px",}}>
            <div>
               <label className="rec-label"> Recommendation {props.item} </label>
            </div>

            <div>
                {/* 10 Boxes */}

                <div style={{display: "flex"}}>
                {
                    cb.map((item,i)=> {
                        return (
                            <div className='box-style'>
                            <label className='tick-style'>&#10004;</label> 
                            </div>
                        )
                    })
                }
                </div>

            </div>
        </div>

        <div style={{display:"flex", backgroundColor: "white", width: "68%", marginTop:"10px", border: "1px solid black",
    padding: "20px"}}>
            <div >
                <ImInfo size="3em"/>
            </div>

            <div style={{paddingLeft: "10px"}}>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. 
            </div>

        </div>

    </div>
  )
}
