export var json = {



  pages: [
    {
      name: "page1",



      elements: [



        {
          "type": "radiogroup",
          "name": "udlboeqsample",
          "title" : {
            "en" : "Sample question",
            "es" : "Ejemplo de pregunta",
            "gr" : "Δείγμα ερώτησης",
            "du" : "Voorbeeldvraag"
          },
          "description": {
            "en" : "I can bake a chocolate cake.",
            "es" : "Puedo hornear un pastel de chocolate.",
            "gr": "Μπορώ να ψήσω ένα κέικ σοκολάτας.",
            "du" : "Ik kan een chocoladetaart bakken."
          },
          "choices": [
           {
            "value": "10",
            "text": {
              "en" : "Not At All Confident",
              "es" : "Nada seguro",
              "gr" : "Καθόλου σίγουρος/η",
              "du" : "Helemaal niet zeker"
           }
          },
           {
            "value": "20",
            "text": {
              "en" : "Slightly Confident",
              "es" : "Ligeramente seguro",
              "gr" : "Ελαφρώς σίγουρος/η",
              "du" : "Een klein beetje zelfverzekerd"
        }
           },
           {
            "value": "30",
            "text": {
              "en" : "Somewhat Confident",
              "es" : "Algo seguro",
              "gr" : "Κάπως σίγουρος/η",
              "du" : "Enigszins zelfverzekerd"
            }
           },
           {
            "value": "40",
            "text": {
              "en" : "Moderately Confident",
              "es" : "Moderadamente seguro",
              "gr" : "Με μέτρια αυτοπεποίθηση",
              "du" : "Matig zelfverzekerd"
            }
           },
           {
            "value": "50",
            "text": {
              "en" : "Extremely Confident",
              "es" : "Extremadamente seguro",
              "gr": "Εξαιρετική αυτοπεποίθηση",
              "du" : "Uiterst zelfverzekerd"
            }
           },

          ]
         }


      ]
    },

  //




     //   ----------------------------------------------Start of Carousel Question
     {
      "name" : "page 4",

      "elements" :

      [

        {
          "type": "radiogroup",
          "name": "udlboeq9",
          "title" : {
            "en" : "For each of the following statements about how you plan and run your classes, please select the response that best reflects your confidence level",
            "es" : "Para cada una de las siguientes afirmaciones sobre cómo planifica y dirige sus clases, seleccione la respuesta que mejor refleje su nivel de confianza",
            "gr" : "Για καθεμία από τις ακόλουθες δηλώσεις σχετικά με τον τρόπο που σχεδιάζετε και υλοποιείτε τα μαθήματά σας, επιλέξτε την απάντηση που αντικατοπτρίζει καλύτερα το επίπεδο εμπιστοσύνης σας",
            "du" : "Volgende stellingen hebben betrekking op hoe u uw lessen plant en hoe ze verlopen. Selecteer voor elk van de volgende uitspraken het antwoord dat het beste past bij de mate u zelfverzekerd bent."
          },
          "description": {
            "en" : "I can help my students to see the connections of what they are learning to what they already know (e.g. by brainstorming prior knowledge in class, using graphic organisers to show the connections between topics, etc.).",
            "es" : "Puedo ayudar a mis alumnos a ver las conexiones entre lo que están aprendiendo y lo que ya saben.",
            "gr" : "Μπορώ να βοηθήσω τους μαθητές μου να κάνουν τη σύνδεση της νέας γνώσης με αυτά που ήδη γνωρίζουν (π.χ. καταιγισμός ιδεών σχετικά με πρότερη γνώηση, χρησιμοποιώντας γραφικούς οργανωτές για να δείξουν τις συνδέσεις μεταξύ θεμάτων κ.λπ.).",
            "du" : "Ik kan mijn studenten helpen de verbanden te zien tussen wat ze leren en wat ze al weten"
          },
          "category" : "comprehension",
          "choices": [
           {
            "value": "10",
            "text": {
              "en" : "Not At All Confident",
              "es" : "Nada seguro",
              "gr" : "Καθόλου σίγουρος/η",
              "du" : "Helemaal niet zeker"
           }
          },
           {
            "value": "20",
            "text": {
              "en" : "Slightly Confident",
              "es" : "Ligeramente seguro",
              "gr" : "Ελαφρώς σίγουρος/η",
              "du" : "Een klein beetje zelfverzekerd"
        }
           },
           {
            "value": "30",
            "text": {
              "en" : "Somewhat Confident",
              "es" : "Algo seguro",
              "gr" : "Κάπως σίγουρος/η",
              "du" : "Enigszins zelfverzekerd"
            }
           },
           {
            "value": "40",
            "text": {
              "en" : "Moderately Confident",
              "es" : "Moderadamente seguro",
              "gr" : "Με μέτρια αυτοπεποίθηση",
              "du" : "Matig zelfverzekerd"
            }
           },
           {
            "value": "50",
            "text": {
              "en" : "Extremely Confident",
              "es" : "Extremadamente seguro",
              "gr": "Εξαιρετική αυτοπεποίθηση",
              "du" : "Uiterst zelfverzekerd"
            }
           },
           {
            "value": "0",
            "text": {
            "en" : "Not applicable or feasible where I teach",
            "es" : "No aplicable o factible donde enseño",
            "gr" : "Δεν ισχύει ή δεν είναι εφικτό  εκεί που διδάσκω",
            "du" : "Niet van toepassing of haalbaar waar ik lesgeef"
           }
           }
          ]
         }

      ]

  },

//   ----------------------------------------------End of Carousel Question



 //   ----------------------------------------------Start of Carousel Question Page 5
 {
  "name" : "page 5",

  "elements" :

  [

    {
      "type": "radiogroup",
      "name": "udlboeq10",
      "title" : {
        "en" : "For each of the following statements about how you plan and run your classes, please select the response that best reflects your confidence level",
        "es" : "Para cada una de las siguientes afirmaciones sobre cómo planifica y dirige sus clases, seleccione la respuesta que mejor refleje su nivel de confianza",
        "gr" : "Για καθεμία από τις ακόλουθες δηλώσεις σχετικά με τον τρόπο που σχεδιάζετε και υλοποιείτε τα μαθήματά σας, επιλέξτε την απάντηση που αντικατοπτρίζει καλύτερα το επίπεδο εμπιστοσύνης σας",
        "du" : "Volgende stellingen hebben betrekking op hoe u uw lessen plant en hoe ze verlopen. Selecteer voor elk van de volgende uitspraken het antwoord dat het beste past bij de mate u zelfverzekerd bent."
      },
      "description": {
        "en" : "I can provide my students with graduated and varied scaffolding as needed (e.g. different approaches, models, examples, feedback, prompts, etc.).",
        "es" : "Puedo proporcionar a mis alumnos un andamiaje gradual y variado según sea necesario (por ejemplo, diferentes enfoques, modelos, ejemplos, comentarios, etc.)",
        "gr" : "Μπορώ να παρέχω στους μαθητές μου διαβαθμισμένη και ποικίλη υποστήριξη ανάλογα με τις ανάγκες τους (π.χ. διαφορετικές προσεγγίσεις, μοντέλα, παραδείγματα, ανατροφοδότηση κ.λπ.)",
        "du" : "Ik kan mijn studenten graduele en gevarieerde ondersteuning bieden als dat nodig is (bijv. verschillende benaderingen, modellen, voorbeelden, feedback, enz.)"
      },

      "category" : "expression",
      "choices": [
       {
        "value": "10",
        "text": {
          "en" : "Not At All Confident",
          "es" : "Nada seguro",
          "gr" : "Καθόλου σίγουρος/η",
          "du" : "Helemaal niet zeker"
       }
      },
       {
        "value": "20",
        "text": {
          "en" : "Slightly Confident",
          "es" : "Ligeramente seguro",
          "gr" : "Ελαφρώς σίγουρος/η",
          "du" : "Een klein beetje zelfverzekerd"
    }
       },
       {
        "value": "30",
        "text": {
          "en" : "Somewhat Confident",
          "es" : "Algo seguro",
          "gr" : "Κάπως σίγουρος/η",
          "du" : "Enigszins zelfverzekerd"
        }
       },
       {
        "value": "40",
        "text": {
          "en" : "Moderately Confident",
          "es" : "Moderadamente seguro",
          "gr" : "Με μέτρια αυτοπεποίθηση",
          "du" : "Matig zelfverzekerd"
        }
       },
       {
        "value": "50",
        "text": {
          "en" : "Extremely Confident",
          "es" : "Extremadamente seguro",
          "gr": "Εξαιρετική αυτοπεποίθηση",
          "du" : "Uiterst zelfverzekerd"
        }
       },
       {
        "value": "0",
        "text": {
        "en" : "Not applicable or feasible where I teach",
        "es" : "No aplicable o factible donde enseño",
        "gr" : "Δεν ισχύει ή δεν είναι εφικτό  εκεί που διδάσκω",
        "du" : "Niet van toepassing of haalbaar waar ik lesgeef"
       }
       }
      ]
     }

  ]

},

//   ----------------------------------------------End of Carousel Question


//   ----------------------------------------------Start of Carousel Question Page 6
{
"name" : "page 6",

"elements" :

[

  {
    "type": "radiogroup",
    "name": "udlboeq11",
    "title" : {
      "en" : "For each of the following statements about how you plan and run your classes, please select the response that best reflects your confidence level",
      "es" : "Para cada una de las siguientes afirmaciones sobre cómo planifica y dirige sus clases, seleccione la respuesta que mejor refleje su nivel de confianza",
      "gr" : "Για καθεμία από τις ακόλουθες δηλώσεις σχετικά με τον τρόπο που σχεδιάζετε και υλοποιείτε τα μαθήματά σας, επιλέξτε την απάντηση που αντικατοπτρίζει καλύτερα το επίπεδο εμπιστοσύνης σας",
      "du" : "Volgende stellingen hebben betrekking op hoe u uw lessen plant en hoe ze verlopen. Selecteer voor elk van de volgende uitspraken het antwoord dat het beste past bij de mate u zelfverzekerd bent."
    },
    "description": {
      "en" : "I can make the meaning of syntax and structure clear and explicit for my students (e.g. using graphic representations or other options to clarify grammar, equations, musical notation, etc.).",
      "es" : "Puedo hacer que el significado de la sintaxis y la estructura sea claro y explícito para mis alumnos (por ejemplo, gramática, ecuaciones, notación musical, etc.).",
      "gr" : "Μπορώ να κάνω σαφή και ξεκάθαρη τη σημασία της σύνταξης και της δομής για τους μαθητές μου (π.χ. με χρήση γραφικών αναπαραστάσεων ή άλλων επιλογών για αποσαφήνιση γραμματικής, εξισώσεων, μουσικής σημειογραφίας κ.λπ.)",
      "du" : "Ik kan de betekenis van syntaxis en structuur duidelijk en expliciet maken voor mijn leerlingen (bijv. grammatica, vergelijkingen, muzieknotatie, enz.)"
    },

    "category" : "language",
    "choices": [
     {
      "value": "10",
      "text": {
        "en" : "Not At All Confident",
        "es" : "Nada seguro",
        "gr" : "Καθόλου σίγουρος/η",
        "du" : "Helemaal niet zeker"
     }
    },
     {
      "value": "20",
      "text": {
        "en" : "Slightly Confident",
        "es" : "Ligeramente seguro",
        "gr" : "Ελαφρώς σίγουρος/η",
        "du" : "Een klein beetje zelfverzekerd"
  }
     },
     {
      "value": "30",
      "text": {
        "en" : "Somewhat Confident",
        "es" : "Algo seguro",
        "gr" : "Κάπως σίγουρος/η",
        "du" : "Enigszins zelfverzekerd"
      }
     },
     {
      "value": "40",
      "text": {
        "en" : "Moderately Confident",
        "es" : "Moderadamente seguro",
        "gr" : "Με μέτρια αυτοπεποίθηση",
        "du" : "Matig zelfverzekerd"
      }
     },
     {
      "value": "50",
      "text": {
        "en" : "Extremely Confident",
        "es" : "Extremadamente seguro",
        "gr": "Εξαιρετική αυτοπεποίθηση",
        "du" : "Uiterst zelfverzekerd"
      }
     },
     {
      "value": "0",
      "text": {
      "en" : "Not applicable or feasible where I teach",
      "es" : "No aplicable o factible donde enseño",
      "gr" : "Δεν ισχύει ή δεν είναι εφικτό  εκεί που διδάσκω",
      "du" : "Niet van toepassing of haalbaar waar ik lesgeef"
     }
     }
    ]
   }

]

},

//   ----------------------------------------------End of Carousel Question


//   ----------------------------------------------Start of Carousel Question Page 7
{
"name" : "page 7",

"elements" :

[

  {
    "type": "radiogroup",
    "name": "udlboeq12",
    "title" : {
      "en" : "For each of the following statements about how you plan and run your classes, please select the response that best reflects your confidence level",
      "es" : "Para cada una de las siguientes afirmaciones sobre cómo planifica y dirige sus clases, seleccione la respuesta que mejor refleje su nivel de confianza",
      "gr" : "Για καθεμία από τις ακόλουθες δηλώσεις σχετικά με τον τρόπο που σχεδιάζετε και υλοποιείτε τα μαθήματά σας, επιλέξτε την απάντηση που αντικατοπτρίζει καλύτερα το επίπεδο εμπιστοσύνης σας",
      "du" : "Volgende stellingen hebben betrekking op hoe u uw lessen plant en hoe ze verlopen. Selecteer voor elk van de volgende uitspraken het antwoord dat het beste past bij de mate u zelfverzekerd bent."
    },
    "description": {
      "en" : "I can make the meaning of vocabulary and symbols clear for my students (e.g. using a glossary, etc.).",
      "es" : "Puedo aclarar el significado del vocabulario y los símbolos para mis alumnos.",
      "gr" : "Μπορώ να καταστήσω σαφή τη σημασία του λεξιλογίου και των συμβόλων στους μαθητές μου",
      "du" : "Ik kan de betekenis van woordenschat en symbolen duidelijk maken voor mijn leerlingen "
    },

    "category" : "language",
    "choices": [
     {
      "value": "10",
      "text": {
        "en" : "Not At All Confident",
        "es" : "Nada seguro",
        "gr" : "Καθόλου σίγουρος/η",
        "du" : "Helemaal niet zeker"
     }
    },
     {
      "value": "20",
      "text": {
        "en" : "Slightly Confident",
        "es" : "Ligeramente seguro",
        "gr" : "Ελαφρώς σίγουρος/η",
        "du" : "Een klein beetje zelfverzekerd"
  }
     },
     {
      "value": "30",
      "text": {
        "en" : "Somewhat Confident",
        "es" : "Algo seguro",
        "gr" : "Κάπως σίγουρος/η",
        "du" : "Enigszins zelfverzekerd"
      }
     },
     {
      "value": "40",
      "text": {
        "en" : "Moderately Confident",
        "es" : "Moderadamente seguro",
        "gr" : "Με μέτρια αυτοπεποίθηση",
        "du" : "Matig zelfverzekerd"
      }
     },
     {
      "value": "50",
      "text": {
        "en" : "Extremely Confident",
        "es" : "Extremadamente seguro",
        "gr": "Εξαιρετική αυτοπεποίθηση",
        "du" : "Uiterst zelfverzekerd"
      }
     },
     {
      "value": "0",
      "text": {
      "en" : "Not applicable or feasible where I teach",
      "es" : "No aplicable o factible donde enseño",
      "gr" : "Δεν ισχύει ή δεν είναι εφικτό  εκεί που διδάσκω",
      "du" : "Niet van toepassing of haalbaar waar ik lesgeef"
     }
     }
    ]
   }

]

},

//   ----------------------------------------------End of Carousel Question

//   ----------------------------------------------Start of Carousel Question Page 8
{
"name" : "page 8",

"elements" :

[

  {
    "type": "radiogroup",
    "name": "udlboeq13",
    "title" : {
      "en" : "For each of the following statements about how you plan and run your classes, please select the response that best reflects your confidence level",
      "es" : "Para cada una de las siguientes afirmaciones sobre cómo planifica y dirige sus clases, seleccione la respuesta que mejor refleje su nivel de confianza",
      "gr" : "Για καθεμία από τις ακόλουθες δηλώσεις σχετικά με τον τρόπο που σχεδιάζετε και υλοποιείτε τα μαθήματά σας, επιλέξτε την απάντηση που αντικατοπτρίζει καλύτερα το επίπεδο εμπιστοσύνης σας",
      "du" : "Volgende stellingen hebben betrekking op hoe u uw lessen plant en hoe ze verlopen. Selecteer voor elk van de volgende uitspraken het antwoord dat het beste past bij de mate u zelfverzekerd bent."
    },
    "description": {
      "en" : "I can help my students identify and chart their progress in learning (e.g. using KWL charts, portfolios with reflections).",
      "es" : "Puedo ayudar a mis alumnos a identificar y trazar su progreso en el aprendizaje.",
      "gr" : "Μπορώ να βοηθήσω τους μαθητές μου να αναγνωρίσουν και να χαράξουν την πρόοδό τους  (π.χ. με χρήση διαγραμμάτων KWL, προσωπικών φακέλων με ανασκόπηση)",
      "du" : "Ik kan mijn studenten helpen hun leervorderingen te identificeren en in kaart te brengen"
    },

    "category" : "selfregulation",
    "choices": [
     {
      "value": "10",
      "text": {
        "en" : "Not At All Confident",
        "es" : "Nada seguro",
        "gr" : "Καθόλου σίγουρος/η",
        "du" : "Helemaal niet zeker"
     }
    },
     {
      "value": "20",
      "text": {
        "en" : "Slightly Confident",
        "es" : "Ligeramente seguro",
        "gr" : "Ελαφρώς σίγουρος/η",
        "du" : "Een klein beetje zelfverzekerd"
  }
     },
     {
      "value": "30",
      "text": {
        "en" : "Somewhat Confident",
        "es" : "Algo seguro",
        "gr" : "Κάπως σίγουρος/η",
        "du" : "Enigszins zelfverzekerd"
      }
     },
     {
      "value": "40",
      "text": {
        "en" : "Moderately Confident",
        "es" : "Moderadamente seguro",
        "gr" : "Με μέτρια αυτοπεποίθηση",
        "du" : "Matig zelfverzekerd"
      }
     },
     {
      "value": "50",
      "text": {
        "en" : "Extremely Confident",
        "es" : "Extremadamente seguro",
        "gr": "Εξαιρετική αυτοπεποίθηση",
        "du" : "Uiterst zelfverzekerd"
      }
     },
     {
      "value": "0",
      "text": {
      "en" : "Not applicable or feasible where I teach",
      "es" : "No aplicable o factible donde enseño",
      "gr" : "Δεν ισχύει ή δεν είναι εφικτό  εκεί που διδάσκω",
      "du" : "Niet van toepassing of haalbaar waar ik lesgeef"
     }
     }
    ]
   }

]

},

//   ----------------------------------------------End of Carousel Question

//   ----------------------------------------------Start of Carousel Question Page 9
{
"name" : "page 9",

"elements" :

[

  {
    "type": "radiogroup",
    "name": "udlboeq14",
    "title" : {
      "en" : "For each of the following statements about how you plan and run your classes, please select the response that best reflects your confidence level",
      "es" : "Para cada una de las siguientes afirmaciones sobre cómo planifica y dirige sus clases, seleccione la respuesta que mejor refleje su nivel de confianza",
      "gr" : "Για καθεμία από τις ακόλουθες δηλώσεις σχετικά με τον τρόπο που σχεδιάζετε και υλοποιείτε τα μαθήματά σας, επιλέξτε την απάντηση που αντικατοπτρίζει καλύτερα το επίπεδο εμπιστοσύνης σας",
      "du" : "Volgende stellingen hebben betrekking op hoe u uw lessen plant en hoe ze verlopen. Selecteer voor elk van de volgende uitspraken het antwoord dat het beste past bij de mate u zelfverzekerd bent."
    },
    "description": {
      "en" : "I can provide formative feedback that helps my students get a clear view of their own progress and how to improve (e.g. using rubrics, exemplars, chart progress over time, etc).",
      "es" : "Puedo proporcionar comentarios formativos que ayuden a mis alumnos a obtener una visión clara de su propio progreso y cómo mejorar (por ejemplo, usando rúbricas, ejemplos, gráficos de progreso a lo largo del tiempo, etc.)",
      "gr" : "Μπορώ να παρέχω διαμορφωτική ανατροφοδότηση που βοηθά τους μαθητές μου να έχουν μια σαφή εικόνα της προόδου τους και του τρόπου βελτίωσης (π.χ. χρησιμοποιώντας ρουμπρίκες, υποδείγματα, διαγράμματα προόδου με την πάροδο του χρόνου, κ.λπ.).",
      "du" : "Ik kan formatieve feedback geven die mijn studenten helpt een duidelijk beeld te krijgen van hun eigen voortgang en hoe ze kunnen verbeteren (bijvoorbeeld met behulp van rubrieken, voorbeelden, de voortgang in de loop van de tijd in kaart brengen, enz.)"
    },

    "category" : "executive",
    "choices": [
     {
      "value": "10",
      "text": {
        "en" : "Not At All Confident",
        "es" : "Nada seguro",
        "gr" : "Καθόλου σίγουρος/η",
        "du" : "Helemaal niet zeker"
     }
    },
     {
      "value": "20",
      "text": {
        "en" : "Slightly Confident",
        "es" : "Ligeramente seguro",
        "gr" : "Ελαφρώς σίγουρος/η",
        "du" : "Een klein beetje zelfverzekerd"
  }
     },
     {
      "value": "30",
      "text": {
        "en" : "Somewhat Confident",
        "es" : "Algo seguro",
        "gr" : "Κάπως σίγουρος/η",
        "du" : "Enigszins zelfverzekerd"
      }
     },
     {
      "value": "40",
      "text": {
        "en" : "Moderately Confident",
        "es" : "Moderadamente seguro",
        "gr" : "Με μέτρια αυτοπεποίθηση",
        "du" : "Matig zelfverzekerd"
      }
     },
     {
      "value": "50",
      "text": {
        "en" : "Extremely Confident",
        "es" : "Extremadamente seguro",
        "gr": "Εξαιρετική αυτοπεποίθηση",
        "du" : "Uiterst zelfverzekerd"
      }
     },
     {
      "value": "0",
      "text": {
      "en" : "Not applicable or feasible where I teach",
      "es" : "No aplicable o factible donde enseño",
      "gr" : "Δεν ισχύει ή δεν είναι εφικτό  εκεί που διδάσκω",
      "du" : "Niet van toepassing of haalbaar waar ik lesgeef"
     }
     }
    ]
   }

]

},

//   ----------------------------------------------End of Carousel

//   ----------------------------------------------Start of Carousel Question Page 10
{
"name" : "page 10",

"elements" :

[

  {
    "type": "radiogroup",
    "name": "udlboeq15",
    "title" : {
      "en" : "For each of the following statements about how you plan and run your classes, please select the response that best reflects your confidence level",
      "es" : "Para cada una de las siguientes afirmaciones sobre cómo planifica y dirige sus clases, seleccione la respuesta que mejor refleje su nivel de confianza",
      "gr" : "Για καθεμία από τις ακόλουθες δηλώσεις σχετικά με τον τρόπο που σχεδιάζετε και υλοποιείτε τα μαθήματά σας, επιλέξτε την απάντηση που αντικατοπτρίζει καλύτερα το επίπεδο εμπιστοσύνης σας",
      "du" : "Volgende stellingen hebben betrekking op hoe u uw lessen plant en hoe ze verlopen. Selecteer voor elk van de volgende uitspraken het antwoord dat het beste past bij de mate u zelfverzekerd bent."
    },
    "description": {
      "en" : "I can help my students to manage information and resources (e.g. using graphic organizers, folders, cloud storage solutions, etc.).",
      "es" : "Puedo ayudar a mis alumnos a gestionar información y recursos (por ejemplo, utilizando organizadores gráficos, carpetas, soluciones de almacenamiento en la nube, etc.)",
      "gr" : "Μπορώ να βοηθήσω τους μαθητές μου να διαχειριστούν πληροφορίες και πόρους (π.χ. χρησιμοποιώντας γραφικούς οργανωτές, φακέλους, λύσεις αποθήκευσης στο σύννεφο (cloud) κ.λπ.)",
      "du" : "Ik kan mijn studenten helpen bij het beheren van informatie en bronnen (bijvoorbeeld met behulp van grafische organisatoren, mappen, cloudopslagoplossingen, enz.) "
    },

    "category" : "executive",
    "choices": [
     {
      "value": "10",
      "text": {
        "en" : "Not At All Confident",
        "es" : "Nada seguro",
        "gr" : "Καθόλου σίγουρος/η",
        "du" : "Helemaal niet zeker"
     }
    },
     {
      "value": "20",
      "text": {
        "en" : "Slightly Confident",
        "es" : "Ligeramente seguro",
        "gr" : "Ελαφρώς σίγουρος/η",
        "du" : "Een klein beetje zelfverzekerd"
  }
     },
     {
      "value": "30",
      "text": {
        "en" : "Somewhat Confident",
        "es" : "Algo seguro",
        "gr" : "Κάπως σίγουρος/η",
        "du" : "Enigszins zelfverzekerd"
      }
     },
     {
      "value": "40",
      "text": {
        "en" : "Moderately Confident",
        "es" : "Moderadamente seguro",
        "gr" : "Με μέτρια αυτοπεποίθηση",
        "du" : "Matig zelfverzekerd"
      }
     },
     {
      "value": "50",
      "text": {
        "en" : "Extremely Confident",
        "es" : "Extremadamente seguro",
        "gr": "Εξαιρετική αυτοπεποίθηση",
        "du" : "Uiterst zelfverzekerd"
      }
     },
     {
      "value": "0",
      "text": {
      "en" : "Not applicable or feasible where I teach",
      "es" : "No aplicable o factible donde enseño",
      "gr" : "Δεν ισχύει ή δεν είναι εφικτό  εκεί που διδάσκω",
      "du" : "Niet van toepassing of haalbaar waar ik lesgeef"
     }
     }
    ]
   }

]

},

//   ----------------------------------------------End of Carousel Question


//   ----------------------------------------------Start of Carousel Question Page 11
{
"name" : "page 11",

"elements" :

[

  {
    "type": "radiogroup",
    "name": "udlboeq16",
    "title" : {
      "en" : "For each of the following statements about how you plan and run your classes, please select the response that best reflects your confidence level",
      "es" : "Para cada una de las siguientes afirmaciones sobre cómo planifica y dirige sus clases, seleccione la respuesta que mejor refleje su nivel de confianza",
      "gr" : "Για καθεμία από τις ακόλουθες δηλώσεις σχετικά με τον τρόπο που σχεδιάζετε και υλοποιείτε τα μαθήματά σας, επιλέξτε την απάντηση που αντικατοπτρίζει καλύτερα το επίπεδο εμπιστοσύνης σας",
      "du" : "Volgende stellingen hebben betrekking op hoe u uw lessen plant en hoe ze verlopen. Selecteer voor elk van de volgende uitspraken het antwoord dat het beste past bij de mate u zelfverzekerd bent."
    },
    "description": {
      "en" : "I can help students develop coping skills and strategies (e.g. for managing frustration, fear, subject-specific anxiety, etc.).",
      "es" : "Puedo ayudar a mis alumnos a desarrollar habilidades y estrategias de afrontamiento (por ejemplo, para manejar la frustración, el miedo, la ansiedad específica de la materia, etc.) ",
      "gr" : "Μπορώ να βοηθήσω τους μαθητές μου να αναπτύξουν δεξιότητες και στρατηγικές αντιμετώπισης (π.χ. για την διαχείριση απογοήτευσης, φόβου, άγχους για συγκεκριμένες θεματικές κ.λπ.)",
      "du" : "Ik kan mijn leerlingen helpen bij het ontwikkelen van copingvaardigheden en -strategieën (bijv. voor het omgaan met frustratie, angst, vakspecifieke angst, enz.)"
    },

    "category" : "selfregulation",
    "choices": [
     {
      "value": "10",
      "text": {
        "en" : "Not At All Confident",
        "es" : "Nada seguro",
        "gr" : "Καθόλου σίγουρος/η",
        "du" : "Helemaal niet zeker"
     }
    },
     {
      "value": "20",
      "text": {
        "en" : "Slightly Confident",
        "es" : "Ligeramente seguro",
        "gr" : "Ελαφρώς σίγουρος/η",
        "du" : "Een klein beetje zelfverzekerd"
  }
     },
     {
      "value": "30",
      "text": {
        "en" : "Somewhat Confident",
        "es" : "Algo seguro",
        "gr" : "Κάπως σίγουρος/η",
        "du" : "Enigszins zelfverzekerd"
      }
     },
     {
      "value": "40",
      "text": {
        "en" : "Moderately Confident",
        "es" : "Moderadamente seguro",
        "gr" : "Με μέτρια αυτοπεποίθηση",
        "du" : "Matig zelfverzekerd"
      }
     },
     {
      "value": "50",
      "text": {
        "en" : "Extremely Confident",
        "es" : "Extremadamente seguro",
        "gr": "Εξαιρετική αυτοπεποίθηση",
        "du" : "Uiterst zelfverzekerd"
      }
     },
     {
      "value": "0",
      "text": {
      "en" : "Not applicable or feasible where I teach",
      "es" : "No aplicable o factible donde enseño",
      "gr" : "Δεν ισχύει ή δεν είναι εφικτό  εκεί που διδάσκω",
      "du" : "Niet van toepassing of haalbaar waar ik lesgeef"
     }
     }
    ]
   }

]

},

//   ----------------------------------------------End of Carousel Question

//   ----------------------------------------------Start of Carousel Question Page 12
{
"name" : "page 12",

"elements" :

[

  {
    "type": "radiogroup",
    "name": "udlboeq17",
    "title" : {
      "en" : "For each of the following statements about how you plan and run your classes, please select the response that best reflects your confidence level",
      "es" : "Para cada una de las siguientes afirmaciones sobre cómo planifica y dirige sus clases, seleccione la respuesta que mejor refleje su nivel de confianza",
      "gr" : "Για καθεμία από τις ακόλουθες δηλώσεις σχετικά με τον τρόπο που σχεδιάζετε και υλοποιείτε τα μαθήματά σας, επιλέξτε την απάντηση που αντικατοπτρίζει καλύτερα το επίπεδο εμπιστοσύνης σας",
      "du" : "Volgende stellingen hebben betrekking op hoe u uw lessen plant en hoe ze verlopen. Selecteer voor elk van de volgende uitspraken het antwoord dat het beste past bij de mate u zelfverzekerd bent."
    },
    "description": {
      "en" : "I can foster collaboration and community among my students (e.g. using cooperative learning methodologies).",
      "es" : "Puedo fomentar la colaboración y la comunidad entre mis alumnos.",
      "gr" : "Μπορώ να προωθήσω τη συνεργασία και την ανάπτυξη σχέσεων μεταξύ των μαθητών μου (π.χ. χρησιμοποιώντας συνεργατικές μαθησιακές τεχνικές)",
      "du" : "Ik kan samenwerking en samenhorigheid tussen mijn studenten bevorderen"
    },

    "category" : "sustain",
    "choices": [
     {
      "value": "10",
      "text": {
        "en" : "Not At All Confident",
        "es" : "Nada seguro",
        "gr" : "Καθόλου σίγουρος/η",
        "du" : "Helemaal niet zeker"
     }
    },
     {
      "value": "20",
      "text": {
        "en" : "Slightly Confident",
        "es" : "Ligeramente seguro",
        "gr" : "Ελαφρώς σίγουρος/η",
        "du" : "Een klein beetje zelfverzekerd"
  }
     },
     {
      "value": "30",
      "text": {
        "en" : "Somewhat Confident",
        "es" : "Algo seguro",
        "gr" : "Κάπως σίγουρος/η",
        "du" : "Enigszins zelfverzekerd"
      }
     },
     {
      "value": "40",
      "text": {
        "en" : "Moderately Confident",
        "es" : "Moderadamente seguro",
        "gr" : "Με μέτρια αυτοπεποίθηση",
        "du" : "Matig zelfverzekerd"
      }
     },
     {
      "value": "50",
      "text": {
        "en" : "Extremely Confident",
        "es" : "Extremadamente seguro",
        "gr": "Εξαιρετική αυτοπεποίθηση",
        "du" : "Uiterst zelfverzekerd"
      }
     },
     {
      "value": "0",
      "text": {
      "en" : "Not applicable or feasible where I teach",
      "es" : "No aplicable o factible donde enseño",
      "gr" : "Δεν ισχύει ή δεν είναι εφικτό  εκεί που διδάσκω",
      "du" : "Niet van toepassing of haalbaar waar ik lesgeef"
     }
     }
    ]
   }

]

},

//   ----------------------------------------------End of Carousel Question

//   ----------------------------------------------Start of Carousel Question Page 13
{
"name" : "page 13",

"elements" :

[

  {
    "type": "radiogroup",
    "name": "udlboeq18",
    "title" : {
      "en" : "For each of the following statements about how you plan and run your classes, please select the response that best reflects your confidence level",
      "es" : "Para cada una de las siguientes afirmaciones sobre cómo planifica y dirige sus clases, seleccione la respuesta que mejor refleje su nivel de confianza",
      "gr" : "Για καθεμία από τις ακόλουθες δηλώσεις σχετικά με τον τρόπο που σχεδιάζετε και υλοποιείτε τα μαθήματά σας, επιλέξτε την απάντηση που αντικατοπτρίζει καλύτερα το επίπεδο εμπιστοσύνης σας",
      "du" : "Volgende stellingen hebben betrekking op hoe u uw lessen plant en hoe ze verlopen. Selecteer voor elk van de volgende uitspraken het antwoord dat het beste past bij de mate u zelfverzekerd bent."
    },
    "description": {
      "en" : "I can guide my students to set challenging and authentic goals (e.g. using goal prompts, KWL charts, etc).",
      "es" : "Puedo guiar a mis alumnos para que establezcan objetivos adecuados y auténticos.",
      "gr" : "Μπορώ να καθοδηγήσω τους μαθητές μου να θέσουν απαιτητικούς και αυθεντικούς στόχους (π.χ. χρησιμοποιώντας προτροπές ως προς το στόχο, διαγράμματα KWL, κλπ)",
      "du" : "Ik kan mijn studenten begeleiden om uitdagende en authentieke doelen te stellen"
    },

    "category" : "executive",
    "choices": [
     {
      "value": "10",
      "text": {
        "en" : "Not At All Confident",
        "es" : "Nada seguro",
        "gr" : "Καθόλου σίγουρος/η",
        "du" : "Helemaal niet zeker"
     }
    },
     {
      "value": "20",
      "text": {
        "en" : "Slightly Confident",
        "es" : "Ligeramente seguro",
        "gr" : "Ελαφρώς σίγουρος/η",
        "du" : "Een klein beetje zelfverzekerd"
  }
     },
     {
      "value": "30",
      "text": {
        "en" : "Somewhat Confident",
        "es" : "Algo seguro",
        "gr" : "Κάπως σίγουρος/η",
        "du" : "Enigszins zelfverzekerd"
      }
     },
     {
      "value": "40",
      "text": {
        "en" : "Moderately Confident",
        "es" : "Moderadamente seguro",
        "gr" : "Με μέτρια αυτοπεποίθηση",
        "du" : "Matig zelfverzekerd"
      }
     },
     {
      "value": "50",
      "text": {
        "en" : "Extremely Confident",
        "es" : "Extremadamente seguro",
        "gr": "Εξαιρετική αυτοπεποίθηση",
        "du" : "Uiterst zelfverzekerd"
      }
     },
     {
      "value": "0",
      "text": {
      "en" : "Not applicable or feasible where I teach",
      "es" : "No aplicable o factible donde enseño",
      "gr" : "Δεν ισχύει ή δεν είναι εφικτό  εκεί που διδάσκω",
      "du" : "Niet van toepassing of haalbaar waar ik lesgeef"
     }
     }
    ]
   }

]

},

//   ----------------------------------------------End of Carousel Question

//   ----------------------------------------------Start of Carousel Question Page 14
{
"name" : "page 14",

"elements" :

[

  {
    "type": "radiogroup",
    "name": "udlboeq19",
    "title" : {
      "en" : "For each of the following statements about how you plan and run your classes, please select the response that best reflects your confidence level",
      "es" : "Para cada una de las siguientes afirmaciones sobre cómo planifica y dirige sus clases, seleccione la respuesta que mejor refleje su nivel de confianza",
      "gr" : "Για καθεμία από τις ακόλουθες δηλώσεις σχετικά με τον τρόπο που σχεδιάζετε και υλοποιείτε τα μαθήματά σας, επιλέξτε την απάντηση που αντικατοπτρίζει καλύτερα το επίπεδο εμπιστοσύνης σας",
      "du" : "Volgende stellingen hebben betrekking op hoe u uw lessen plant en hoe ze verlopen. Selecteer voor elk van de volgende uitspraken het antwoord dat het beste past bij de mate u zelfverzekerd bent."
    },
    "description": {
      "en" : "I can guide my students to process and make sense of information (e.g. using prompts, models, scaffolds, chunking, analogies, etc.).",
      "es" : "Puedo guiar a mis alumnos para que procesen y den sentido a la información (p. ej., usando indicaciones, modelos, andamios, fragmentación, analogías, etc.) ",
      "gr" : "Μπορώ να καθοδηγήσω τους μαθητές μου να επεξεργαστούν και να κατανοήσουν τις πληροφορίες (π.χ. προτροπές, μοντέλα, υποστήριξη, αποδόμηση, αναλογίες κ.λπ.)",
      "du" : "Ik kan mijn studenten begeleiden om informatie te verwerken en te begrijpen (bijvoorbeeld door aanwijzingen, modellen, steigers, chunking, analogieën, enz.)"
    },

    "category" : "comprehension",
    "choices": [
     {
      "value": "10",
      "text": {
        "en" : "Not At All Confident",
        "es" : "Nada seguro",
        "gr" : "Καθόλου σίγουρος/η",
        "du" : "Helemaal niet zeker"
     }
    },
     {
      "value": "20",
      "text": {
        "en" : "Slightly Confident",
        "es" : "Ligeramente seguro",
        "gr" : "Ελαφρώς σίγουρος/η",
        "du" : "Een klein beetje zelfverzekerd"
  }
     },
     {
      "value": "30",
      "text": {
        "en" : "Somewhat Confident",
        "es" : "Algo seguro",
        "gr" : "Κάπως σίγουρος/η",
        "du" : "Enigszins zelfverzekerd"
      }
     },
     {
      "value": "40",
      "text": {
        "en" : "Moderately Confident",
        "es" : "Moderadamente seguro",
        "gr" : "Με μέτρια αυτοπεποίθηση",
        "du" : "Matig zelfverzekerd"
      }
     },
     {
      "value": "50",
      "text": {
        "en" : "Extremely Confident",
        "es" : "Extremadamente seguro",
        "gr": "Εξαιρετική αυτοπεποίθηση",
        "du" : "Uiterst zelfverzekerd"
      }
     },
     {
      "value": "0",
      "text": {
      "en" : "Not applicable or feasible where I teach",
      "es" : "No aplicable o factible donde enseño",
      "gr" : "Δεν ισχύει ή δεν είναι εφικτό  εκεί που διδάσκω",
      "du" : "Niet van toepassing of haalbaar waar ik lesgeef"
     }
     }
    ]
   }

]

},

//   ----------------------------------------------End of Carousel Question


//   ----------------------------------------------Start of Carousel Question Page 15
{
"name" : "page 15",

"elements" :

[

  {
    "type": "radiogroup",
    "name": "udlboeq20",
    "title" : {
      "en" : "For each of the following statements about how you plan and run your classes, please select the response that best reflects your confidence level",
      "es" : "Para cada una de las siguientes afirmaciones sobre cómo planifica y dirige sus clases, seleccione la respuesta que mejor refleje su nivel de confianza",
      "gr" : "Για καθεμία από τις ακόλουθες δηλώσεις σχετικά με τον τρόπο που σχεδιάζετε και υλοποιείτε τα μαθήματά σας, επιλέξτε την απάντηση που αντικατοπτρίζει καλύτερα το επίπεδο εμπιστοσύνης σας",
      "du" : "Volgende stellingen hebben betrekking op hoe u uw lessen plant en hoe ze verlopen. Selecteer voor elk van de volgende uitspraken het antwoord dat het beste past bij de mate u zelfverzekerd bent."
    },
    "description": {
      "en" : "I can help my students retain their focus on their learning goal and its value (e.g. through articulating, visualising, breaking down, prompting and reiterating the goals).",
      "es" : "Puedo ayudar a mis alumnos a mantenerse focalizados en su meta de aprendizaje y su importancia (por ejemplo, articulando, visualizando, desglosando, incitando y reiterando las metas) ",
      "gr" : "Μπορώ να βοηθήσω τους μαθητές μου να διατηρήσουν την εστίασή τους στον μαθησιακό τους στόχο και την αξία του (π.χ. μέσω της σαφούς διατύπωσης, οπτικοποίησης, απoδόμησης,  προτροπής και επανάληψης των στόχων) ",
      "du" : "Ik kan mijn studenten helpen hun focus op hun leerdoel te behouden en de waarde ervan te appreciëren (bijvoorbeeld door de doelen te articuleren, visualiseren, afbreken, aansporen en herhalen) "
    },

    "category" : "sustain",
    "choices": [
     {
      "value": "10",
      "text": {
        "en" : "Not At All Confident",
        "es" : "Nada seguro",
        "gr" : "Καθόλου σίγουρος/η",
        "du" : "Helemaal niet zeker"
     }
    },
     {
      "value": "20",
      "text": {
        "en" : "Slightly Confident",
        "es" : "Ligeramente seguro",
        "gr" : "Ελαφρώς σίγουρος/η",
        "du" : "Een klein beetje zelfverzekerd"
  }
     },
     {
      "value": "30",
      "text": {
        "en" : "Somewhat Confident",
        "es" : "Algo seguro",
        "gr" : "Κάπως σίγουρος/η",
        "du" : "Enigszins zelfverzekerd"
      }
     },
     {
      "value": "40",
      "text": {
        "en" : "Moderately Confident",
        "es" : "Moderadamente seguro",
        "gr" : "Με μέτρια αυτοπεποίθηση",
        "du" : "Matig zelfverzekerd"
      }
     },
     {
      "value": "50",
      "text": {
        "en" : "Extremely Confident",
        "es" : "Extremadamente seguro",
        "gr": "Εξαιρετική αυτοπεποίθηση",
        "du" : "Uiterst zelfverzekerd"
      }
     },
     {
      "value": "0",
      "text": {
      "en" : "Not applicable or feasible where I teach",
      "es" : "No aplicable o factible donde enseño",
      "gr" : "Δεν ισχύει ή δεν είναι εφικτό  εκεί που διδάσκω",
      "du" : "Niet van toepassing of haalbaar waar ik lesgeef"
     }
     }
    ]
   }

]

},

//   ----------------------------------------------End of Carousel Question

//   ----------------------------------------------Start of Carousel Question Page 16
{
"name" : "page 16",

"elements" :

[

  {
    "type": "radiogroup",
    "name": "udlboeq21",
    "title" : {
      "en" : "For each of the following statements about how you plan and run your classes, please select the response that best reflects your confidence level",
      "es" : "Para cada una de las siguientes afirmaciones sobre cómo planifica y dirige sus clases, seleccione la respuesta que mejor refleje su nivel de confianza",
      "gr" : "Για καθεμία από τις ακόλουθες δηλώσεις σχετικά με τον τρόπο που σχεδιάζετε και υλοποιείτε τα μαθήματά σας, επιλέξτε την απάντηση που αντικατοπτρίζει καλύτερα το επίπεδο εμπιστοσύνης σας",
      "du" : "Volgende stellingen hebben betrekking op hoe u uw lessen plant en hoe ze verlopen. Selecteer voor elk van de volgende uitspraken het antwoord dat het beste past bij de mate u zelfverzekerd bent."
    },
    "description": {
      "en" : "I can help my students to identify important information and link this to their learning goal (e.g. using cues, prompts, graphic organizers, etc.).",
      "es" : "Puedo ayudar a mis alumnos a identificar información importante y relacionarla con su objetivo de aprendizaje (p. ej., utilizando pistas, indicaciones, organizadores gráficos, etc.)",
      "gr" : "Μπορώ να βοηθήσω τους μαθητές μου να εντοπίσουν σημαντικές πληροφορίες και να τις συνδέσουν με το μαθησιακό τους στόχο (π.χ. χρησιμοποιώντας συνθήματα, προτροπές, οργανωτές γραφικών κ.λπ.)",
      "du" : "Ik kan mijn leerlingen helpen om belangrijke informatie te identificeren en dit te koppelen aan hun leerdoel (bijv. met behulp van cues, prompts, grafische organisatoren, enz.) "
    },

    "category" : "comprehension",
    "choices": [
     {
      "value": "10",
      "text": {
        "en" : "Not At All Confident",
        "es" : "Nada seguro",
        "gr" : "Καθόλου σίγουρος/η",
        "du" : "Helemaal niet zeker"
     }
    },
     {
      "value": "20",
      "text": {
        "en" : "Slightly Confident",
        "es" : "Ligeramente seguro",
        "gr" : "Ελαφρώς σίγουρος/η",
        "du" : "Een klein beetje zelfverzekerd"
  }
     },
     {
      "value": "30",
      "text": {
        "en" : "Somewhat Confident",
        "es" : "Algo seguro",
        "gr" : "Κάπως σίγουρος/η",
        "du" : "Enigszins zelfverzekerd"
      }
     },
     {
      "value": "40",
      "text": {
        "en" : "Moderately Confident",
        "es" : "Moderadamente seguro",
        "gr" : "Με μέτρια αυτοπεποίθηση",
        "du" : "Matig zelfverzekerd"
      }
     },
     {
      "value": "50",
      "text": {
        "en" : "Extremely Confident",
        "es" : "Extremadamente seguro",
        "gr": "Εξαιρετική αυτοπεποίθηση",
        "du" : "Uiterst zelfverzekerd"
      }
     },
     {
      "value": "0",
      "text": {
      "en" : "Not applicable or feasible where I teach",
      "es" : "No aplicable o factible donde enseño",
      "gr" : "Δεν ισχύει ή δεν είναι εφικτό  εκεί που διδάσκω",
      "du" : "Niet van toepassing of haalbaar waar ik lesgeef"
     }
     }
    ]
   }

]
},

//   ----------------------------------------------End of Carousel Question


//   ----------------------------------------------Start of Carousel Question Page 17
{
"name" : "page 17",

"elements" :

[

  {
    "type": "radiogroup",
    "name": "udlboeq22",
    "title" : {
      "en" : "For each of the following statements about how you plan and run your classes, please select the response that best reflects your confidence level",
      "es" : "Para cada una de las siguientes afirmaciones sobre cómo planifica y dirige sus clases, seleccione la respuesta que mejor refleje su nivel de confianza",
      "gr" : "Για καθεμία από τις ακόλουθες δηλώσεις σχετικά με τον τρόπο που σχεδιάζετε και υλοποιείτε τα μαθήματά σας, επιλέξτε την απάντηση που αντικατοπτρίζει καλύτερα το επίπεδο εμπιστοσύνης σας",
      "du" : "Volgende stellingen hebben betrekking op hoe u uw lessen plant en hoe ze verlopen. Selecteer voor elk van de volgende uitspraken het antwoord dat het beste past bij de mate u zelfverzekerd bent."
    },
    "description": {
      "en" : "I can use multiple forms of available media to make learning more accessible for my students (e.g. using simulations, graphics, activities, videos).",
      "es" : "Puedo usar múltiples formas de medios disponibles para hacer que el aprendizaje sea más accesible para mis alumnos (por ejemplo, usando simulaciones, gráficos, actividades, videos).",
      "gr" : "Μπορώ να χρησιμοποιήσω πολλαπλές μορφές διαθέσιμων μέσων για να κάνω τη μάθηση πιο προσιτή στους μαθητές μου (π.χ. χρησιμοποιώντας προσομοιώσεις, γραφικά, δραστηριότητες, βίντεο)",
      "du" : "Ik kan meerdere vormen van beschikbare media gebruiken om leren toegankelijker te maken voor mijn leerlingen (bijv. door gebruik te maken van simulaties, afbeeldingen, activiteiten, video's)"
    },

    "category" : "language",
    "choices": [
     {
      "value": "10",
      "text": {
        "en" : "Not At All Confident",
        "es" : "Nada seguro",
        "gr" : "Καθόλου σίγουρος/η",
        "du" : "Helemaal niet zeker"
     }
    },
     {
      "value": "20",
      "text": {
        "en" : "Slightly Confident",
        "es" : "Ligeramente seguro",
        "gr" : "Ελαφρώς σίγουρος/η",
        "du" : "Een klein beetje zelfverzekerd"
  }
     },
     {
      "value": "30",
      "text": {
        "en" : "Somewhat Confident",
        "es" : "Algo seguro",
        "gr" : "Κάπως σίγουρος/η",
        "du" : "Enigszins zelfverzekerd"
      }
     },
     {
      "value": "40",
      "text": {
        "en" : "Moderately Confident",
        "es" : "Moderadamente seguro",
        "gr" : "Με μέτρια αυτοπεποίθηση",
        "du" : "Matig zelfverzekerd"
      }
     },
     {
      "value": "50",
      "text": {
        "en" : "Extremely Confident",
        "es" : "Extremadamente seguro",
        "gr": "Εξαιρετική αυτοπεποίθηση",
        "du" : "Uiterst zelfverzekerd"
      }
     },
     {
      "value": "0",
      "text": {
      "en" : "Not applicable or feasible where I teach",
      "es" : "No aplicable o factible donde enseño",
      "gr" : "Δεν ισχύει ή δεν είναι εφικτό  εκεί που διδάσκω",
      "du" : "Niet van toepassing of haalbaar waar ik lesgeef"
     }
     }
    ]
   }

]

},

//   ----------------------------------------------End of Carousel Question


//   ----------------------------------------------Start of Carousel Question Page 18
{
"name" : "page 18",

"elements" :

[

  {
    "type": "radiogroup",
    "name": "udlboeq23",
    "title" : {
      "en" : "For each of the following statements about how you plan and run your classes, please select the response that best reflects your confidence level",
      "es" : "Para cada una de las siguientes afirmaciones sobre cómo planifica y dirige sus clases, seleccione la respuesta que mejor refleje su nivel de confianza",
      "gr" : "Για καθεμία από τις ακόλουθες δηλώσεις σχετικά με τον τρόπο που σχεδιάζετε και υλοποιείτε τα μαθήματά σας, επιλέξτε την απάντηση που αντικατοπτρίζει καλύτερα το επίπεδο εμπιστοσύνης σας",
      "du" : "Volgende stellingen hebben betrekking op hoe u uw lessen plant en hoe ze verlopen. Selecteer voor elk van de volgende uitspraken het antwoord dat het beste past bij de mate u zelfverzekerd bent."
    },
    "description": {
      "en" : "I can help my students understand the importance of effort in their learning to ensure successful long term learning habits and practices (e.g. giving feedback on effort as well as attainment, taking time to reflect on and review learning and study habits).",
      "es" : "Puedo ayudar a mis alumnos a comprender la importancia del esfuerzo en su aprendizaje para garantizar hábitos y prácticas de aprendizaje exitosos a largo plazo.",
      "gr" : "Μπορώ να βοηθήσω τους μαθητές μου να κατανοήσουν τη σημασία της προσπάθειας στη μάθησή τους για να εξασφαλίσουν επιτυχημένες μαθησιακές συνήθειες και πρακτικές μακροπρόθεσμα (π.χ. παροχή ανατροφοδότησης για την προσπάθεια καθώς και για το αποτέλεσμα, αφιερώνοντας χρόνο για προβληματισμό και επανεξέταση των συνηθειών μάθησης και μελέτης).",
      "du" : "Ik kan mijn studenten helpen het belang van inspanning bij hun leerproces te begrijpen om te zorgen voor succesvolle leergewoonten en -praktijken op de lange termijn "
    },

    "category" : "sustain",
    "choices": [
     {
      "value": "10",
      "text": {
        "en" : "Not At All Confident",
        "es" : "Nada seguro",
        "gr" : "Καθόλου σίγουρος/η",
        "du" : "Helemaal niet zeker"
     }
    },
     {
      "value": "20",
      "text": {
        "en" : "Slightly Confident",
        "es" : "Ligeramente seguro",
        "gr" : "Ελαφρώς σίγουρος/η",
        "du" : "Een klein beetje zelfverzekerd"
  }
     },
     {
      "value": "30",
      "text": {
        "en" : "Somewhat Confident",
        "es" : "Algo seguro",
        "gr" : "Κάπως σίγουρος/η",
        "du" : "Enigszins zelfverzekerd"
      }
     },
     {
      "value": "40",
      "text": {
        "en" : "Moderately Confident",
        "es" : "Moderadamente seguro",
        "gr" : "Με μέτρια αυτοπεποίθηση",
        "du" : "Matig zelfverzekerd"
      }
     },
     {
      "value": "50",
      "text": {
        "en" : "Extremely Confident",
        "es" : "Extremadamente seguro",
        "gr": "Εξαιρετική αυτοπεποίθηση",
        "du" : "Uiterst zelfverzekerd"
      }
     },
     {
      "value": "0",
      "text": {
      "en" : "Not applicable or feasible where I teach",
      "es" : "No aplicable o factible donde enseño",
      "gr" : "Δεν ισχύει ή δεν είναι εφικτό  εκεί που διδάσκω",
      "du" : "Niet van toepassing of haalbaar waar ik lesgeef"
     }
     }
    ]
   }

]

},

//   ----------------------------------------------End of Carousel Question

//   ----------------------------------------------Start of Carousel Question Page 19
{
"name" : "page 19",

"elements" :

[

  {
    "type": "radiogroup",
    "name": "udlboeq24",
    "title" : {
      "en" : "For each of the following statements about how you plan and run your classes, please select the response that best reflects your confidence level",
      "es" : "Para cada una de las siguientes afirmaciones sobre cómo planifica y dirige sus clases, seleccione la respuesta que mejor refleje su nivel de confianza",
      "gr" : "Για καθεμία από τις ακόλουθες δηλώσεις σχετικά με τον τρόπο που σχεδιάζετε και υλοποιείτε τα μαθήματά σας, επιλέξτε την απάντηση που αντικατοπτρίζει καλύτερα το επίπεδο εμπιστοσύνης σας",
      "du" : "Volgende stellingen hebben betrekking op hoe u uw lessen plant en hoe ze verlopen. Selecteer voor elk van de volgende uitspraken het antwoord dat het beste past bij de mate u zelfverzekerd bent."
    },
    "description": {
      "en" : "I can help my students generalise and transfer what they have learned to other contexts (e.g. applying a technique to a real-world context (e.g. use maths techniques for budgeting, use language learnt in language learning class for a real communicative task like doing a social media profile).",
      "es" : "Puedo ayudar a mis alumnos a generalizar y transferir lo que han aprendido a otros contextos.",
      "gr" : "Μπορώ να βοηθήσω τους μαθητές μου να γενικεύσουν και να μεταφέρουν αυτά που έμαθαν σε άλλα πλαίσια (π.χ. εφαρμόζοντας μια τεχνική στο πλαίσιο της καθημερινότητας, π.χ. χρήση μαθηματικών τεχνικών για διαχείριση προϋπολογισμού, χρήση γλωσσικών στοιχείων για πραγματική επικοινωνία όπως για παράδειγμα σε μέσα κοινωνικής δικτύωσης)",
      "du" : "Ik kan mijn leerlingen helpen het geleerde te generaliseren en over te dragen naar andere contexten"
    },

    "category" : "comprehension",
    "choices": [
     {
      "value": "10",
      "text": {
        "en" : "Not At All Confident",
        "es" : "Nada seguro",
        "gr" : "Καθόλου σίγουρος/η",
        "du" : "Helemaal niet zeker"
     }
    },
     {
      "value": "20",
      "text": {
        "en" : "Slightly Confident",
        "es" : "Ligeramente seguro",
        "gr" : "Ελαφρώς σίγουρος/η",
        "du" : "Een klein beetje zelfverzekerd"
  }
     },
     {
      "value": "30",
      "text": {
        "en" : "Somewhat Confident",
        "es" : "Algo seguro",
        "gr" : "Κάπως σίγουρος/η",
        "du" : "Enigszins zelfverzekerd"
      }
     },
     {
      "value": "40",
      "text": {
        "en" : "Moderately Confident",
        "es" : "Moderadamente seguro",
        "gr" : "Με μέτρια αυτοπεποίθηση",
        "du" : "Matig zelfverzekerd"
      }
     },
     {
      "value": "50",
      "text": {
        "en" : "Extremely Confident",
        "es" : "Extremadamente seguro",
        "gr": "Εξαιρετική αυτοπεποίθηση",
        "du" : "Uiterst zelfverzekerd"
      }
     },
     {
      "value": "0",
      "text": {
      "en" : "Not applicable or feasible where I teach",
      "es" : "No aplicable o factible donde enseño",
      "gr" : "Δεν ισχύει ή δεν είναι εφικτό  εκεί που διδάσκω",
      "du" : "Niet van toepassing of haalbaar waar ik lesgeef"
     }
     }
    ]
   }

]
},

//   ----------------------------------------------End of Carousel Question

//   ----------------------------------------------Start of Carousel Question Page 20
{
"name" : "page 20",

"elements" :

[

  {
    "type": "radiogroup",
    "name": "udlboeq25",
    "title" : {
      "en" : "For each of the following statements about how you plan and run your classes, please select the response that best reflects your confidence level",
      "es" : "Para cada una de las siguientes afirmaciones sobre cómo planifica y dirige sus clases, seleccione la respuesta que mejor refleje su nivel de confianza",
      "gr" : "Για καθεμία από τις ακόλουθες δηλώσεις σχετικά με τον τρόπο που σχεδιάζετε και υλοποιείτε τα μαθήματά σας, επιλέξτε την απάντηση που αντικατοπτρίζει καλύτερα το επίπεδο εμπιστοσύνης σας",
      "du" : "Volgende stellingen hebben betrekking op hoe u uw lessen plant en hoe ze verlopen. Selecteer voor elk van de volgende uitspraken het antwoord dat het beste past bij de mate u zelfverzekerd bent."
    },
    "description": {
      "en" : "I can create a safe and supportive classroom climate that minimises insecurity and distractions (e.g. providing clear routines and schedules, managing sensory stimulation, managing any demands of public displays or evaluation).",
      "es" : "Puedo crear un clima de aula seguro y de apoyo que minimice la inseguridad y las distracciones (p. ej., proporcionando rutinas y horarios claros, gestionando la estimulación sensorial, gestionando cualquier demanda de exhibición pública o evaluación).",
      "gr" : "Μπορώ να δημιουργήσω ένα ασφαλές και υποστηρικτικό κλίμα στην τάξη που ελαχιστοποιεί την ανασφάλεια και τους περισπασμούς (π.χ. παρέχοντας σαφείς ρουτίνες και χρονοδιαγράμματα, διαχείριση αισθητηριακής διέγερσης, διαχείριση τυχόν απαιτήσεων για δημόσιες παρουσιάσεις ή αξιολόγηση) ",
      "du" : "Ik kan een veilig en ondersteunend klasklimaat creëren dat onzekerheid en afleiding minimaliseert (bijv. duidelijke routines en schema's bieden, zintuiglijke stimulatie beheersen, eventuele vereisten van presentatie of evaluatie sturen "
    },

    "category" : "recruit",
    "choices": [
     {
      "value": "10",
      "text": {
        "en" : "Not At All Confident",
        "es" : "Nada seguro",
        "gr" : "Καθόλου σίγουρος/η",
        "du" : "Helemaal niet zeker"
     }
    },
     {
      "value": "20",
      "text": {
        "en" : "Slightly Confident",
        "es" : "Ligeramente seguro",
        "gr" : "Ελαφρώς σίγουρος/η",
        "du" : "Een klein beetje zelfverzekerd"
  }
     },
     {
      "value": "30",
      "text": {
        "en" : "Somewhat Confident",
        "es" : "Algo seguro",
        "gr" : "Κάπως σίγουρος/η",
        "du" : "Enigszins zelfverzekerd"
      }
     },
     {
      "value": "40",
      "text": {
        "en" : "Moderately Confident",
        "es" : "Moderadamente seguro",
        "gr" : "Με μέτρια αυτοπεποίθηση",
        "du" : "Matig zelfverzekerd"
      }
     },
     {
      "value": "50",
      "text": {
        "en" : "Extremely Confident",
        "es" : "Extremadamente seguro",
        "gr": "Εξαιρετική αυτοπεποίθηση",
        "du" : "Uiterst zelfverzekerd"
      }
     },
     {
      "value": "0",
      "text": {
      "en" : "Not applicable or feasible where I teach",
      "es" : "No aplicable o factible donde enseño",
      "gr" : "Δεν ισχύει ή δεν είναι εφικτό  εκεί που διδάσκω",
      "du" : "Niet van toepassing of haalbaar waar ik lesgeef"
     }
     }
    ]
   }

]

},

//   ----------------------------------------------End of Carousel Question

//   ----------------------------------------------Start of Carousel Question Page 21
{
"name" : "page 21",

"elements" :

[

  {
    "type": "radiogroup",
    "name": "udlboeq26",
    "title" : {
      "en" : "For each of the following statements about how you plan and run your classes, please select the response that best reflects your confidence level",
      "es" : "Para cada una de las siguientes afirmaciones sobre cómo planifica y dirige sus clases, seleccione la respuesta que mejor refleje su nivel de confianza",
      "gr" : "Για καθεμία από τις ακόλουθες δηλώσεις σχετικά με τον τρόπο που σχεδιάζετε και υλοποιείτε τα μαθήματά σας, επιλέξτε την απάντηση που αντικατοπτρίζει καλύτερα το επίπεδο εμπιστοσύνης σας",
      "du" : "Volgende stellingen hebben betrekking op hoe u uw lessen plant en hoe ze verlopen. Selecteer voor elk van de volgende uitspraken het antwoord dat het beste past bij de mate u zelfverzekerd bent."
    },
    "description": {
      "en" : "I can offer alternatives for auditory information (e.g. diagrams or charts, transcripts or captioning, etc.).",
      "es" : "Puedo ofrecer alternativas para la información auditiva (por ejemplo, diagramas o gráficos, transcripciones o subtítulos, etc.)",
      "gr" : "Μπορώ να προσφέρω εναλλακτικές για ακουστικές πληροφορίες (π.χ. διαγράμματα ή γραφήματα, υπότιτλους ή λεζάντες κ.λπ.)",
      "du" : "Ik kan alternatieven bieden voor auditieve informatie (bijv. diagrammen of grafieken, transcripties of ondertiteling, enz.)"
    },

    "category" : "perception",
    "choices": [
     {
      "value": "10",
      "text": {
        "en" : "Not At All Confident",
        "es" : "Nada seguro",
        "gr" : "Καθόλου σίγουρος/η",
        "du" : "Helemaal niet zeker"
     }
    },
     {
      "value": "20",
      "text": {
        "en" : "Slightly Confident",
        "es" : "Ligeramente seguro",
        "gr" : "Ελαφρώς σίγουρος/η",
        "du" : "Een klein beetje zelfverzekerd"
  }
     },
     {
      "value": "30",
      "text": {
        "en" : "Somewhat Confident",
        "es" : "Algo seguro",
        "gr" : "Κάπως σίγουρος/η",
        "du" : "Enigszins zelfverzekerd"
      }
     },
     {
      "value": "40",
      "text": {
        "en" : "Moderately Confident",
        "es" : "Moderadamente seguro",
        "gr" : "Με μέτρια αυτοπεποίθηση",
        "du" : "Matig zelfverzekerd"
      }
     },
     {
      "value": "50",
      "text": {
        "en" : "Extremely Confident",
        "es" : "Extremadamente seguro",
        "gr": "Εξαιρετική αυτοπεποίθηση",
        "du" : "Uiterst zelfverzekerd"
      }
     },
     {
      "value": "0",
      "text": {
      "en" : "Not applicable or feasible where I teach",
      "es" : "No aplicable o factible donde enseño",
      "gr" : "Δεν ισχύει ή δεν είναι εφικτό  εκεί που διδάσκω",
      "du" : "Niet van toepassing of haalbaar waar ik lesgeef"
     }
     }
    ]
   }

]

},

//   ----------------------------------------------End of Carousel Question

//   ----------------------------------------------Start of Carousel Question Page 22
{
"name" : "page 22",

"elements" :

[

  {
    "type": "radiogroup",
    "name": "udlboeq27",
    "title" : {
      "en" : "For each of the following statements about how you plan and run your classes, please select the response that best reflects your confidence level",
      "es" : "Para cada una de las siguientes afirmaciones sobre cómo planifica y dirige sus clases, seleccione la respuesta que mejor refleje su nivel de confianza",
      "gr" : "Για καθεμία από τις ακόλουθες δηλώσεις σχετικά με τον τρόπο που σχεδιάζετε και υλοποιείτε τα μαθήματά σας, επιλέξτε την απάντηση που αντικατοπτρίζει καλύτερα το επίπεδο εμπιστοσύνης σας",
      "du" : "Volgende stellingen hebben betrekking op hoe u uw lessen plant en hoe ze verlopen. Selecteer voor elk van de volgende uitspraken het antwoord dat het beste past bij de mate u zelfverzekerd bent."
    },
    "description": {
      "en" : "I can provide alternatives for visual information (e.g. by describing images, videos and graphs with audio or text or poviding audio versions of text).",
      "es" : "Puedo proporcionar alternativas para la información visual (por ejemplo, describiendo imágenes, videos y gráficos con audio o texto o proporcionando versiones de texto en audio).",
      "gr" : "Μπορώ να παρέχω εναλλακτικές για οπτικές πληροφορίες (π.χ. περιγράφοντας εικόνες, βίντεο και γραφήματα με ήχο ή κείμενο ή παρέχοντας ηχητικές εκδόσεις κειμένου) ",
      "du" : "Ik kan alternatieven bieden voor visuele informatie (bijvoorbeeld door afbeeldingen, video's en grafieken met audio of tekst te beschrijven of audioversies van tekst aan te bieden)"
    },

    "category" : "perception",
    "choices": [
     {
      "value": "10",
      "text": {
        "en" : "Not At All Confident",
        "es" : "Nada seguro",
        "gr" : "Καθόλου σίγουρος/η",
        "du" : "Helemaal niet zeker"
     }
    },
     {
      "value": "20",
      "text": {
        "en" : "Slightly Confident",
        "es" : "Ligeramente seguro",
        "gr" : "Ελαφρώς σίγουρος/η",
        "du" : "Een klein beetje zelfverzekerd"
  }
     },
     {
      "value": "30",
      "text": {
        "en" : "Somewhat Confident",
        "es" : "Algo seguro",
        "gr" : "Κάπως σίγουρος/η",
        "du" : "Enigszins zelfverzekerd"
      }
     },
     {
      "value": "40",
      "text": {
        "en" : "Moderately Confident",
        "es" : "Moderadamente seguro",
        "gr" : "Με μέτρια αυτοπεποίθηση",
        "du" : "Matig zelfverzekerd"
      }
     },
     {
      "value": "50",
      "text": {
        "en" : "Extremely Confident",
        "es" : "Extremadamente seguro",
        "gr": "Εξαιρετική αυτοπεποίθηση",
        "du" : "Uiterst zelfverzekerd"
      }
     },
     {
      "value": "0",
      "text": {
      "en" : "Not applicable or feasible where I teach",
      "es" : "No aplicable o factible donde enseño",
      "gr" : "Δεν ισχύει ή δεν είναι εφικτό  εκεί που διδάσκω",
      "du" : "Niet van toepassing of haalbaar waar ik lesgeef"
     }
     }
    ]
   }

]

},

//   ----------------------------------------------End of Carousel Question


//   ----------------------------------------------Start of Carousel Question Page 23
{
"name" : "page 23",

"elements" :

[

  {
    "type": "radiogroup",
    "name": "udlboeq28",
    "title" : {
      "en" : "For each of the following statements about how you plan and run your classes, please select the response that best reflects your confidence level",
      "es" : "Para cada una de las siguientes afirmaciones sobre cómo planifica y dirige sus clases, seleccione la respuesta que mejor refleje su nivel de confianza",
      "gr" : "Για καθεμία από τις ακόλουθες δηλώσεις σχετικά με τον τρόπο που σχεδιάζετε και υλοποιείτε τα μαθήματά σας, επιλέξτε την απάντηση που αντικατοπτρίζει καλύτερα το επίπεδο εμπιστοσύνης σας",
      "du" : "Volgende stellingen hebben betrekking op hoe u uw lessen plant en hoe ze verlopen. Selecteer voor elk van de volgende uitspraken het antwoord dat het beste past bij de mate u zelfverzekerd bent."
    },
    "description": {
      "en" : "I can be flexible in the formats I use to display information, offering options to vary size, colour, contrast, volume, etc., based on individual student needs.",
      "es" : "Puedo ser flexible en los formatos que utilizo para mostrar la información, ofreciendo opciones para variar el tamaño, el color, el contraste, el volumen, etc., según las necesidades individuales de los estudiantes.",
      "gr" : "Μπορώ να είμαι ευέλικτος/η στις μορφές που χρησιμοποιώ για να εμφανίζω πληροφορίες, προσφέροντας επιλογές για διαφοροποίηση μεγέθους, χρώματος, αντίθεσης, έντασης κ.λπ. με βάση τις ατομικές ανάγκες των μαθητών ",
      "du" : "Ik kan flexibel zijn in de formaten die ik gebruik om informatie weer te geven, en bied opties om de grootte, kleur, contrast, volume, enz. te variëren op basis van de individuele behoeften van de student"
    },

    "category" : "perception",
    "choices": [
     {
      "value": "10",
      "text": {
        "en" : "Not At All Confident",
        "es" : "Nada seguro",
        "gr" : "Καθόλου σίγουρος/η",
        "du" : "Helemaal niet zeker"
     }
    },
     {
      "value": "20",
      "text": {
        "en" : "Slightly Confident",
        "es" : "Ligeramente seguro",
        "gr" : "Ελαφρώς σίγουρος/η",
        "du" : "Een klein beetje zelfverzekerd"
  }
     },
     {
      "value": "30",
      "text": {
        "en" : "Somewhat Confident",
        "es" : "Algo seguro",
        "gr" : "Κάπως σίγουρος/η",
        "du" : "Enigszins zelfverzekerd"
      }
     },
     {
      "value": "40",
      "text": {
        "en" : "Moderately Confident",
        "es" : "Moderadamente seguro",
        "gr" : "Με μέτρια αυτοπεποίθηση",
        "du" : "Matig zelfverzekerd"
      }
     },
     {
      "value": "50",
      "text": {
        "en" : "Extremely Confident",
        "es" : "Extremadamente seguro",
        "gr": "Εξαιρετική αυτοπεποίθηση",
        "du" : "Uiterst zelfverzekerd"
      }
     },
     {
      "value": "0",
      "text": {
      "en" : "Not applicable or feasible where I teach",
      "es" : "No aplicable o factible donde enseño",
      "gr" : "Δεν ισχύει ή δεν είναι εφικτό  εκεί που διδάσκω",
      "du" : "Niet van toepassing of haalbaar waar ik lesgeef"
     }
     }
    ]
   }

]
},

//   ----------------------------------------------End of Carousel Question

//   ----------------------------------------------Start of Carousel Question Page 24
{
"name" : "page 24",

"elements" :

[

  {
    "type": "radiogroup",
    "name": "udlboeq29",
    "title" : {
      "en" : "For each of the following statements about how you plan and run your classes, please select the response that best reflects your confidence level",
      "es" : "Para cada una de las siguientes afirmaciones sobre cómo planifica y dirige sus clases, seleccione la respuesta que mejor refleje su nivel de confianza",
      "gr" : "Για καθεμία από τις ακόλουθες δηλώσεις σχετικά με τον τρόπο που σχεδιάζετε και υλοποιείτε τα μαθήματά σας, επιλέξτε την απάντηση που αντικατοπτρίζει καλύτερα το επίπεδο εμπιστοσύνης σας",
      "du" : "Volgende stellingen hebben betrekking op hoe u uw lessen plant en hoe ze verlopen. Selecteer voor elk van de volgende uitspraken het antwoord dat het beste past bij de mate u zelfverzekerd bent."
    },
    "description": {
      "en" : "I can provide tools or assistive technologies to make sure that my lessons are physically accessible to my students (e.g. alternative  keyboards, alternate keyboard commands for mouse actions, etc.).",
      "es" : "Puedo proporcionar herramientas o tecnologías de ayuda para asegurarme de que mis lecciones sean físicamente accesibles para mis alumnos (por ejemplo, teclados alternativos, comandos de teclado alternativos para acciones del mouse, etc.)",
      "gr" : "Μπορώ να παρέχω εργαλεία ή βοηθητικές τεχνολογίες για να βεβαιωθώ ότι τα μαθήματά μου είναι προσβάσιμα από τους μαθητές μου (π.χ. εναλλακτικά πληκτρολόγια, εναλλακτικές εντολές πληκτρολογίου για ενέργειες του ποντικιού κ.λπ.) ",
      "du" : "Ik kan hulpmiddelen of ondersteunende technologieën bieden om ervoor te zorgen dat mijn lessen fysiek toegankelijk zijn voor mijn studenten (bijv. alternatieve toetsenborden, alternatieve toetsenbordopdrachten voor muisacties, enz.)"
    },

    "category" : "physical",
    "choices": [
     {
      "value": "10",
      "text": {
        "en" : "Not At All Confident",
        "es" : "Nada seguro",
        "gr" : "Καθόλου σίγουρος/η",
        "du" : "Helemaal niet zeker"
     }
    },
     {
      "value": "20",
      "text": {
        "en" : "Slightly Confident",
        "es" : "Ligeramente seguro",
        "gr" : "Ελαφρώς σίγουρος/η",
        "du" : "Een klein beetje zelfverzekerd"
  }
     },
     {
      "value": "30",
      "text": {
        "en" : "Somewhat Confident",
        "es" : "Algo seguro",
        "gr" : "Κάπως σίγουρος/η",
        "du" : "Enigszins zelfverzekerd"
      }
     },
     {
      "value": "40",
      "text": {
        "en" : "Moderately Confident",
        "es" : "Moderadamente seguro",
        "gr" : "Με μέτρια αυτοπεποίθηση",
        "du" : "Matig zelfverzekerd"
      }
     },
     {
      "value": "50",
      "text": {
        "en" : "Extremely Confident",
        "es" : "Extremadamente seguro",
        "gr": "Εξαιρετική αυτοπεποίθηση",
        "du" : "Uiterst zelfverzekerd"
      }
     },
     {
      "value": "0",
      "text": {
      "en" : "Not applicable or feasible where I teach",
      "es" : "No aplicable o factible donde enseño",
      "gr" : "Δεν ισχύει ή δεν είναι εφικτό  εκεί που διδάσκω",
      "du" : "Niet van toepassing of haalbaar waar ik lesgeef"
     }
     }
    ]
   }

]

},

//   ----------------------------------------------End of Carousel Question


//   ----------------------------------------------Start of Carousel Question Page 25
{
"name" : "page 25",

"elements" :

[

  {
    "type": "radiogroup",
    "name": "udlboeq30",
    "title" : {
      "en" : "For each of the following statements about how you plan and run your classes, please select the response that best reflects your confidence level",
      "es" : "Para cada una de las siguientes afirmaciones sobre cómo planifica y dirige sus clases, seleccione la respuesta que mejor refleje su nivel de confianza",
      "gr" : "Για καθεμία από τις ακόλουθες δηλώσεις σχετικά με τον τρόπο που σχεδιάζετε και υλοποιείτε τα μαθήματά σας, επιλέξτε την απάντηση που αντικατοπτρίζει καλύτερα το επίπεδο εμπιστοσύνης σας",
      "du" : "Volgende stellingen hebben betrekking op hoe u uw lessen plant en hoe ze verlopen. Selecteer voor elk van de volgende uitspraken het antwoord dat het beste past bij de mate u zelfverzekerd bent."
    },
    "description": {
      "en" : "I can provide my students with as much choice and autonomy as possible in how to meet learning objectives (e.g. vary the level of challenge, the content, context, tools, etc.).",
      "es" : "Puedo proporcionar a mis alumnos tantas opciones y autonomía como sea posible sobre cómo cumplir los objetivos de aprendizaje (por ejemplo, nivel de desafío, contenido, contexto, herramientas, etc.).",
      "gr" : "Μπορώ να παρέχω στους μαθητές μου όσο το δυνατόν περισσότερες επιλογές και αυτονομία όσον αφορά τον τρόπο επίτευξης των μαθησιακών στόχων (π.χ. επίπεδο πρόκλησης, περιεχόμενο, πλαίσιο, εργαλεία κ.λπ.).",
      "du" : "Ik kan mijn studenten zoveel mogelijk keuze en autonomie bieden bij het bereiken van leerdoelen (bijv. niveau van uitdaging, inhoud, context, hulpmiddelen, enz.)"
    },

    "category" : "recruit",
    "choices": [
     {
      "value": "10",
      "text": {
        "en" : "Not At All Confident",
        "es" : "Nada seguro",
        "gr" : "Καθόλου σίγουρος/η",
        "du" : "Helemaal niet zeker"
     }
    },
     {
      "value": "20",
      "text": {
        "en" : "Slightly Confident",
        "es" : "Ligeramente seguro",
        "gr" : "Ελαφρώς σίγουρος/η",
        "du" : "Een klein beetje zelfverzekerd"
  }
     },
     {
      "value": "30",
      "text": {
        "en" : "Somewhat Confident",
        "es" : "Algo seguro",
        "gr" : "Κάπως σίγουρος/η",
        "du" : "Enigszins zelfverzekerd"
      }
     },
     {
      "value": "40",
      "text": {
        "en" : "Moderately Confident",
        "es" : "Moderadamente seguro",
        "gr" : "Με μέτρια αυτοπεποίθηση",
        "du" : "Matig zelfverzekerd"
      }
     },
     {
      "value": "50",
      "text": {
        "en" : "Extremely Confident",
        "es" : "Extremadamente seguro",
        "gr": "Εξαιρετική αυτοπεποίθηση",
        "du" : "Uiterst zelfverzekerd"
      }
     },
     {
      "value": "0",
      "text": {
      "en" : "Not applicable or feasible where I teach",
      "es" : "No aplicable o factible donde enseño",
      "gr" : "Δεν ισχύει ή δεν είναι εφικτό  εκεί που διδάσκω",
      "du" : "Niet van toepassing of haalbaar waar ik lesgeef"
     }
     }
    ]
   }

]

},

//   ----------------------------------------------End of Carousel Question


//   ----------------------------------------------Start of Carousel Question Page 26
{
"name" : "page 26",

"elements" :

[

  {
    "type": "radiogroup",
    "name": "udlboeq31",
    "title" : {
      "en" : "For each of the following statements about how you plan and run your classes, please select the response that best reflects your confidence level",
      "es" : "Para cada una de las siguientes afirmaciones sobre cómo planifica y dirige sus clases, seleccione la respuesta que mejor refleje su nivel de confianza",
      "gr" : "Για καθεμία από τις ακόλουθες δηλώσεις σχετικά με τον τρόπο που σχεδιάζετε και υλοποιείτε τα μαθήματά σας, επιλέξτε την απάντηση που αντικατοπτρίζει καλύτερα το επίπεδο εμπιστοσύνης σας",
      "du" : "Volgende stellingen hebben betrekking op hoe u uw lessen plant en hoe ze verlopen. Selecteer voor elk van de volgende uitspraken het antwoord dat het beste past bij de mate u zelfverzekerd bent."
    },
    "description": {
      "en" : "I can ensure that the content of my lessons is relevant and engaging for my students, through the use of meaningful and interesting contexts (e.g. providing real world contexts for using language, following the areas of interest of the class for materials, etc.).",
      "es" : "Puedo asegurarme de que el contenido de mis lecciones sea relevante y atractivo para mis alumnos mediante el uso de contextos significativos e interesantes.",
      "gr" : "Μπορώ να διασφαλίσω ότι το περιεχόμενο των μαθημάτων μου είναι σχετικό και ελκυστικό για τους μαθητές μου, μέσω της χρήσης ουσιαστικών και ενδιαφερόντων για αυτούς πλαισίων (π.χ. παροχή πραγματικών πλαισίων για την χρήση της γλώσσας, με βάση τις περιοχές ενδιαφέροντος της τάξης, κ.λπ.).",
      "du" : "Ik kan ervoor zorgen dat de inhoud van mijn lessen relevant en boeiend is voor mijn studenten, door het gebruik van zinvolle en interessante contexten "
    },

    "category" : "recruit",
    "choices": [
     {
      "value": "10",
      "text": {
        "en" : "Not At All Confident",
        "es" : "Nada seguro",
        "gr" : "Καθόλου σίγουρος/η",
        "du" : "Helemaal niet zeker"
     }
    },
     {
      "value": "20",
      "text": {
        "en" : "Slightly Confident",
        "es" : "Ligeramente seguro",
        "gr" : "Ελαφρώς σίγουρος/η",
        "du" : "Een klein beetje zelfverzekerd"
  }
     },
     {
      "value": "30",
      "text": {
        "en" : "Somewhat Confident",
        "es" : "Algo seguro",
        "gr" : "Κάπως σίγουρος/η",
        "du" : "Enigszins zelfverzekerd"
      }
     },
     {
      "value": "40",
      "text": {
        "en" : "Moderately Confident",
        "es" : "Moderadamente seguro",
        "gr" : "Με μέτρια αυτοπεποίθηση",
        "du" : "Matig zelfverzekerd"
      }
     },
     {
      "value": "50",
      "text": {
        "en" : "Extremely Confident",
        "es" : "Extremadamente seguro",
        "gr": "Εξαιρετική αυτοπεποίθηση",
        "du" : "Uiterst zelfverzekerd"
      }
     },
     {
      "value": "0",
      "text": {
      "en" : "Not applicable or feasible where I teach",
      "es" : "No aplicable o factible donde enseño",
      "gr" : "Δεν ισχύει ή δεν είναι εφικτό  εκεί που διδάσκω",
      "du" : "Niet van toepassing of haalbaar waar ik lesgeef"
     }
     }
    ]
   }

]

},

//   ----------------------------------------------End of Carousel Question

//   ----------------------------------------------Start of Carousel Question Page 27
{
"name" : "page 27",

"elements" :

[

  {
    "type": "radiogroup",
    "name": "udlboeq32",
    "title" : {
      "en" : "For each of the following statements about how you plan and run your classes, please select the response that best reflects your confidence level",
      "es" : "Para cada una de las siguientes afirmaciones sobre cómo planifica y dirige sus clases, seleccione la respuesta que mejor refleje su nivel de confianza",
      "gr" : "Για καθεμία από τις ακόλουθες δηλώσεις σχετικά με τον τρόπο που σχεδιάζετε και υλοποιείτε τα μαθήματά σας, επιλέξτε την απάντηση που αντικατοπτρίζει καλύτερα το επίπεδο εμπιστοσύνης σας",
      "du" : "Volgende stellingen hebben betrekking op hoe u uw lessen plant en hoe ze verlopen. Selecteer voor elk van de volgende uitspraken het antwoord dat het beste past bij de mate u zelfverzekerd bent."
    },
    "description": {
      "en" : "I can help my students to set appropriate personal goals to keep themselves motivated (e.g. link goals to rewards relevant to the students, use prompts for goal setting like the next step I want to take is ..., etc.).",
      "es" : "Puedo ayudar a mis alumnos a establecer metas personales apropiadas para mantenerse motivados.",
      "gr" : "Μπορώ να βοηθήσω τους μαθητές μου να θέσουν κατάλληλους προσωπικούς στόχους για να μένουν κινητοποιημένοι (π.χ. συνδέοντας στόχους με ανταμοιβές, χρησιμοποιώντας  προτροπές για τον καθορισμό στόχων, όπως το επόμενο βήμα που θέλω να κάνω είναι ... κ.λπ.)",
      "du" : "Ik kan mijn leerlingen helpen om passende persoonlijke doelen te stellen om zichzelf gemotiveerd te houden"
    },

    "category" : "selfregulation",
    "choices": [
     {
      "value": "10",
      "text": {
        "en" : "Not At All Confident",
        "es" : "Nada seguro",
        "gr" : "Καθόλου σίγουρος/η",
        "du" : "Helemaal niet zeker"
     }
    },
     {
      "value": "20",
      "text": {
        "en" : "Slightly Confident",
        "es" : "Ligeramente seguro",
        "gr" : "Ελαφρώς σίγουρος/η",
        "du" : "Een klein beetje zelfverzekerd"
  }
     },
     {
      "value": "30",
      "text": {
        "en" : "Somewhat Confident",
        "es" : "Algo seguro",
        "gr" : "Κάπως σίγουρος/η",
        "du" : "Enigszins zelfverzekerd"
      }
     },
     {
      "value": "40",
      "text": {
        "en" : "Moderately Confident",
        "es" : "Moderadamente seguro",
        "gr" : "Με μέτρια αυτοπεποίθηση",
        "du" : "Matig zelfverzekerd"
      }
     },
     {
      "value": "50",
      "text": {
        "en" : "Extremely Confident",
        "es" : "Extremadamente seguro",
        "gr": "Εξαιρετική αυτοπεποίθηση",
        "du" : "Uiterst zelfverzekerd"
      }
     },
     {
      "value": "0",
      "text": {
      "en" : "Not applicable or feasible where I teach",
      "es" : "No aplicable o factible donde enseño",
      "gr" : "Δεν ισχύει ή δεν είναι εφικτό  εκεί που διδάσκω",
      "du" : "Niet van toepassing of haalbaar waar ik lesgeef"
     }
     }
    ]
   }

]

},

//   ----------------------------------------------End of Carousel Question

//   ----------------------------------------------Start of Carousel Question Page 28
{
"name" : "page 28",

"elements" :

[

  {
    "type": "radiogroup",
    "name": "udlboeq33",
    "title" : {
      "en" : "For each of the following statements about how you plan and run your classes, please select the response that best reflects your confidence level",
      "es" : "Para cada una de las siguientes afirmaciones sobre cómo planifica y dirige sus clases, seleccione la respuesta que mejor refleje su nivel de confianza",
      "gr" : "Για καθεμία από τις ακόλουθες δηλώσεις σχετικά με τον τρόπο που σχεδιάζετε και υλοποιείτε τα μαθήματά σας, επιλέξτε την απάντηση που αντικατοπτρίζει καλύτερα το επίπεδο εμπιστοσύνης σας",
      "du" : "Volgende stellingen hebben betrekking op hoe u uw lessen plant en hoe ze verlopen. Selecteer voor elk van de volgende uitspraken het antwoord dat het beste past bij de mate u zelfverzekerd bent."
    },
    "description": {
      "en" : "I can help the multilingual students in my lessons access the language of the classroom by providing supports for their other language(s), (e.g. translations, vocabulary and definitions, mime, visuals, language software, etc.).",
      "es" : "Puedo ayudar a mis estudiantes  con el acceso al idioma del aula brindándoles apoyo para su(s) otro(s) idioma(s), (por ejemplo, traducciones, vocabulario y definiciones, mímica, imágenes, software de idiomas, etc.)",
      "gr" : "Μπορώ να βοηθήσω τους πολύγλωσσους μαθητές μου να έχουν πρόσβαση στη γλώσσα που χρησιμοποιείται στην τάξη παρέχοντας υποστήριξη για τις άλλες γλώσσες τους (π.χ. μεταφράσεις, λεξιλόγιο και ορισμούς, μίμηση, γραφικά, σχετικό λογισμικό κ.λπ.) ",
      "du" : "Ik kan de meertalige studenten in mijn lessen helpen toegang te krijgen tot de taal van het klaslokaal door ondersteuning te bieden voor hun andere taal (talen), (bijv. vertalingen, woordenschat en definities, mime, visuals, taalsoftware, enz.)"
    },

    "category" : "language",
    "choices": [
     {
      "value": "10",
      "text": {
        "en" : "Not At All Confident",
        "es" : "Nada seguro",
        "gr" : "Καθόλου σίγουρος/η",
        "du" : "Helemaal niet zeker"
     }
    },
     {
      "value": "20",
      "text": {
        "en" : "Slightly Confident",
        "es" : "Ligeramente seguro",
        "gr" : "Ελαφρώς σίγουρος/η",
        "du" : "Een klein beetje zelfverzekerd"
  }
     },
     {
      "value": "30",
      "text": {
        "en" : "Somewhat Confident",
        "es" : "Algo seguro",
        "gr" : "Κάπως σίγουρος/η",
        "du" : "Enigszins zelfverzekerd"
      }
     },
     {
      "value": "40",
      "text": {
        "en" : "Moderately Confident",
        "es" : "Moderadamente seguro",
        "gr" : "Με μέτρια αυτοπεποίθηση",
        "du" : "Matig zelfverzekerd"
      }
     },
     {
      "value": "50",
      "text": {
        "en" : "Extremely Confident",
        "es" : "Extremadamente seguro",
        "gr": "Εξαιρετική αυτοπεποίθηση",
        "du" : "Uiterst zelfverzekerd"
      }
     },
     {
      "value": "0",
      "text": {
      "en" : "Not applicable or feasible where I teach",
      "es" : "No aplicable o factible donde enseño",
      "gr" : "Δεν ισχύει ή δεν είναι εφικτό  εκεί που διδάσκω",
      "du" : "Niet van toepassing of haalbaar waar ik lesgeef"
     }
     }
    ]
   }

]
},

//   ----------------------------------------------End of Carousel Question

//   ----------------------------------------------Start of Carousel Question Page 29
{
"name" : "page 29",

"elements" :

[

  {
    "type": "radiogroup",
    "name": "udlboeq34",
    "title" : {
      "en" : "For each of the following statements about how you plan and run your classes, please select the response that best reflects your confidence level",
      "es" : "Para cada una de las siguientes afirmaciones sobre cómo planifica y dirige sus clases, seleccione la respuesta que mejor refleje su nivel de confianza",
      "gr" : "Για καθεμία από τις ακόλουθες δηλώσεις σχετικά με τον τρόπο που σχεδιάζετε και υλοποιείτε τα μαθήματά σας, επιλέξτε την απάντηση που αντικατοπτρίζει καλύτερα το επίπεδο εμπιστοσύνης σας",
      "du" : "Volgende stellingen hebben betrekking op hoe u uw lessen plant en hoe ze verlopen. Selecteer voor elk van de volgende uitspraken het antwoord dat het beste past bij de mate u zelfverzekerd bent."
    },
    "description": {
      "en" : "I can help my students to decode textual symbols such as words, maths notation, scientific symbols (e.g. by using text-to-speech software, automatic translation, etc.).",
      "es" : "Puedo ayudar a mis alumnos a decodificar símbolos textuales como palabras, notación matemática, símbolos científicos (por ejemplo, mediante el uso de software de texto a voz, traducción automática, etc.)",
      "gr" : "Μπορώ να βοηθήσω τους μαθητές μου να αποκωδικοποιήσουν κειμενικά σύμβολα όπως λέξεις, μαθηματικές σημειώσεις, επιστημονικά σύμβολα (π.χ. χρησιμοποιώντας λογισμικό μετατροπής κειμένου σε ομιλία, αυτόματη μετάφραση κ.λπ.) ",
      "du" : "Ik kan mijn leerlingen helpen bij het decoderen van tekstsymbolen zoals woorden, wiskundige notatie, wetenschappelijke symbolen (bijvoorbeeld door tekst-naar-spraaksoftware, automatische vertaling, enz.)"
    },

    "category" : "language",
    "choices": [
     {
      "value": "10",
      "text": {
        "en" : "Not At All Confident",
        "es" : "Nada seguro",
        "gr" : "Καθόλου σίγουρος/η",
        "du" : "Helemaal niet zeker"
     }
    },
     {
      "value": "20",
      "text": {
        "en" : "Slightly Confident",
        "es" : "Ligeramente seguro",
        "gr" : "Ελαφρώς σίγουρος/η",
        "du" : "Een klein beetje zelfverzekerd"
  }
     },
     {
      "value": "30",
      "text": {
        "en" : "Somewhat Confident",
        "es" : "Algo seguro",
        "gr" : "Κάπως σίγουρος/η",
        "du" : "Enigszins zelfverzekerd"
      }
     },
     {
      "value": "40",
      "text": {
        "en" : "Moderately Confident",
        "es" : "Moderadamente seguro",
        "gr" : "Με μέτρια αυτοπεποίθηση",
        "du" : "Matig zelfverzekerd"
      }
     },
     {
      "value": "50",
      "text": {
        "en" : "Extremely Confident",
        "es" : "Extremadamente seguro",
        "gr": "Εξαιρετική αυτοπεποίθηση",
        "du" : "Uiterst zelfverzekerd"
      }
     },
     {
      "value": "0",
      "text": {
      "en" : "Not applicable or feasible where I teach",
      "es" : "No aplicable o factible donde enseño",
      "gr" : "Δεν ισχύει ή δεν είναι εφικτό  εκεί που διδάσκω",
      "du" : "Niet van toepassing of haalbaar waar ik lesgeef"
     }
     }
    ]
   }

]

},

//   ----------------------------------------------End of Carousel Question

//   ----------------------------------------------Start of Carousel Question Page 30
{
"name" : "page 30",

"elements" :

[

  {
    "type": "radiogroup",
    "name": "udlboeq35",
    "title" : {
      "en" : "For each of the following statements about how you plan and run your classes, please select the response that best reflects your confidence level",
      "es" : "Para cada una de las siguientes afirmaciones sobre cómo planifica y dirige sus clases, seleccione la respuesta que mejor refleje su nivel de confianza",
      "gr" : "Για καθεμία από τις ακόλουθες δηλώσεις σχετικά με τον τρόπο που σχεδιάζετε και υλοποιείτε τα μαθήματά σας, επιλέξτε την απάντηση που αντικατοπτρίζει καλύτερα το επίπεδο εμπιστοσύνης σας",
      "du" : "Volgende stellingen hebben betrekking op hoe u uw lessen plant en hoe ze verlopen. Selecteer voor elk van de volgende uitspraken het antwoord dat het beste past bij de mate u zelfverzekerd bent."
    },
    "description": {
      "en" : "I can help my students develop their skills in planning for projects and learning (e.g. by using planning prompts, checklists and templates).",
      "es" : "Puedo ayudar a mis alumnos a desarrollar sus habilidades en la planificación de proyectos y el aprendizaje (por ejemplo, mediante el uso de indicaciones de planificación, listas de verificación y plantillas)",
      "gr" : "Μπορώ να βοηθήσω τους μαθητές μου να αναπτύξουν τις δεξιότητές τους στην οργάνωση εργασιών και στη μάθηση (π.χ. χρησιμοποιώντας αντίστοιχες προτροπές, λίστες ελέγχου και πρότυπα) ",
      "du" : "Ik kan mijn studenten helpen hun vaardigheden te ontwikkelen in het plannen van projecten en leren (bijvoorbeeld door planningsprompts, checklists en sjablonen te gebruiken)"
    },

    "category" : "executive",
    "choices": [
     {
      "value": "10",
      "text": {
        "en" : "Not At All Confident",
        "es" : "Nada seguro",
        "gr" : "Καθόλου σίγουρος/η",
        "du" : "Helemaal niet zeker"
     }
    },
     {
      "value": "20",
      "text": {
        "en" : "Slightly Confident",
        "es" : "Ligeramente seguro",
        "gr" : "Ελαφρώς σίγουρος/η",
        "du" : "Een klein beetje zelfverzekerd"
  }
     },
     {
      "value": "30",
      "text": {
        "en" : "Somewhat Confident",
        "es" : "Algo seguro",
        "gr" : "Κάπως σίγουρος/η",
        "du" : "Enigszins zelfverzekerd"
      }
     },
     {
      "value": "40",
      "text": {
        "en" : "Moderately Confident",
        "es" : "Moderadamente seguro",
        "gr" : "Με μέτρια αυτοπεποίθηση",
        "du" : "Matig zelfverzekerd"
      }
     },
     {
      "value": "50",
      "text": {
        "en" : "Extremely Confident",
        "es" : "Extremadamente seguro",
        "gr": "Εξαιρετική αυτοπεποίθηση",
        "du" : "Uiterst zelfverzekerd"
      }
     },
     {
      "value": "0",
      "text": {
      "en" : "Not applicable or feasible where I teach",
      "es" : "No aplicable o factible donde enseño",
      "gr" : "Δεν ισχύει ή δεν είναι εφικτό  εκεί που διδάσκω",
      "du" : "Niet van toepassing of haalbaar waar ik lesgeef"
     }
     }
    ]
   }

]
},

//   ----------------------------------------------End of Carousel Question

//   ----------------------------------------------Start of Carousel Question Page 31
{
"name" : "page 31",

"elements" :

[

  {
    "type": "radiogroup",
    "name": "udlboeq36",
    "title" : {
      "en" : "For each of the following statements about how you plan and run your classes, please select the response that best reflects your confidence level",
      "es" : "Para cada una de las siguientes afirmaciones sobre cómo planifica y dirige sus clases, seleccione la respuesta que mejor refleje su nivel de confianza",
      "gr" : "Για καθεμία από τις ακόλουθες δηλώσεις σχετικά με τον τρόπο που σχεδιάζετε και υλοποιείτε τα μαθήματά σας, επιλέξτε την απάντηση που αντικατοπτρίζει καλύτερα το επίπεδο εμπιστοσύνης σας",
      "du" : "Volgende stellingen hebben betrekking op hoe u uw lessen plant en hoe ze verlopen. Selecteer voor elk van de volgende uitspraken het antwoord dat het beste past bij de mate u zelfverzekerd bent."
    },
    "description": {
      "en" : "I can facilitate my students to use a wide range of media to communicate (e.g. text, image, video, movement, storyboards, etc).",
      "es" : "Puedo facilitar a mis alumnos el uso de una amplia gama de medios para comunicarse (por ejemplo, texto, imagen, video, movimiento, guiones gráficos, etc.)",
      "gr" : "Μπορώ να διευκολύνω τους μαθητές μου να χρησιμοποιήσουν ένα ευρύ φάσμα μέσων για να επικοινωνήσουν (π.χ. κείμενο, εικόνα, βίντεο, κίνηση, σενάρια κ.λπ.) ",
      "du" : "Ik kan mijn studenten helpen om een breed scala aan media te gebruiken om te communiceren (bijv. tekst, beeld, video, beweging, storyboards, enz.) "
    },

    "category" : "expression",
    "choices": [
     {
      "value": "10",
      "text": {
        "en" : "Not At All Confident",
        "es" : "Nada seguro",
        "gr" : "Καθόλου σίγουρος/η",
        "du" : "Helemaal niet zeker"
     }
    },
     {
      "value": "20",
      "text": {
        "en" : "Slightly Confident",
        "es" : "Ligeramente seguro",
        "gr" : "Ελαφρώς σίγουρος/η",
        "du" : "Een klein beetje zelfverzekerd"
  }
     },
     {
      "value": "30",
      "text": {
        "en" : "Somewhat Confident",
        "es" : "Algo seguro",
        "gr" : "Κάπως σίγουρος/η",
        "du" : "Enigszins zelfverzekerd"
      }
     },
     {
      "value": "40",
      "text": {
        "en" : "Moderately Confident",
        "es" : "Moderadamente seguro",
        "gr" : "Με μέτρια αυτοπεποίθηση",
        "du" : "Matig zelfverzekerd"
      }
     },
     {
      "value": "50",
      "text": {
        "en" : "Extremely Confident",
        "es" : "Extremadamente seguro",
        "gr": "Εξαιρετική αυτοπεποίθηση",
        "du" : "Uiterst zelfverzekerd"
      }
     },
     {
      "value": "0",
      "text": {
      "en" : "Not applicable or feasible where I teach",
      "es" : "No aplicable o factible donde enseño",
      "gr" : "Δεν ισχύει ή δεν είναι εφικτό  εκεί που διδάσκω",
      "du" : "Niet van toepassing of haalbaar waar ik lesgeef"
     }
     }
    ]
   }

]

},

//   ----------------------------------------------End of Carousel Question

//   ----------------------------------------------Start of Carousel Question Page 32
{
"name" : "page 32",

"elements" :

[

  {
    "type": "radiogroup",
    "name": "udlboeq37",
    "title" : {
      "en" : "For each of the following statements about how you plan and run your classes, please select the response that best reflects your confidence level",
      "es" : "Para cada una de las siguientes afirmaciones sobre cómo planifica y dirige sus clases, seleccione la respuesta que mejor refleje su nivel de confianza",
      "gr" : "Για καθεμία από τις ακόλουθες δηλώσεις σχετικά με τον τρόπο που σχεδιάζετε και υλοποιείτε τα μαθήματά σας, επιλέξτε την απάντηση που αντικατοπτρίζει καλύτερα το επίπεδο εμπιστοσύνης σας",
      "du" : "Volgende stellingen hebben betrekking op hoe u uw lessen plant en hoe ze verlopen. Selecteer voor elk van de volgende uitspraken het antwoord dat het beste past bij de mate u zelfverzekerd bent."
    },
    "description": {
      "en" : "I can facilitate my students to use a wide range of tools to best demonstrate what they know (e.g. using spellcheckers, voice recognition software, machine translation, concept mapping tools, sentence starters, etc.).",
      "es" : "Puedo facilitar a mis alumnos el uso de una amplia gama de herramientas para demostrar mejor lo que saben (p. ej., usar correctores ortográficos, software de reconocimiento de voz, traducción automática, herramientas de creación de mapas conceptuales, iniciadores de oraciones, etc.)",
      "gr" : "Μπορώ να διευκολύνω τους μαθητές μου να χρησιμοποιήσουν ένα ευρύ φάσμα εργαλείων για να δείξουν καλύτερα τι γνωρίζουν (π.χ. ορθογραφικό έλεγχο, λογισμικό αναγνώρισης φωνής, μηχανική μετάφραση, εργαλεία χαρτογράφησης εννοιών, αρχή πρoτάσεων κ.λπ.) ",
      "du" : "Ik kan mijn studenten helpen om een breed scala aan tools te gebruiken om het beste te demonstreren wat ze weten (bijv. met behulp van spellingcontrole, spraakherkenningssoftware, machinevertaling, concept mapping-tools, zinstarters, enz.) "
    },

    "category" : "expression",
    "choices": [
     {
      "value": "10",
      "text": {
        "en" : "Not At All Confident",
        "es" : "Nada seguro",
        "gr" : "Καθόλου σίγουρος/η",
        "du" : "Helemaal niet zeker"
     }
    },
     {
      "value": "20",
      "text": {
        "en" : "Slightly Confident",
        "es" : "Ligeramente seguro",
        "gr" : "Ελαφρώς σίγουρος/η",
        "du" : "Een klein beetje zelfverzekerd"
  }
     },
     {
      "value": "30",
      "text": {
        "en" : "Somewhat Confident",
        "es" : "Algo seguro",
        "gr" : "Κάπως σίγουρος/η",
        "du" : "Enigszins zelfverzekerd"
      }
     },
     {
      "value": "40",
      "text": {
        "en" : "Moderately Confident",
        "es" : "Moderadamente seguro",
        "gr" : "Με μέτρια αυτοπεποίθηση",
        "du" : "Matig zelfverzekerd"
      }
     },
     {
      "value": "50",
      "text": {
        "en" : "Extremely Confident",
        "es" : "Extremadamente seguro",
        "gr": "Εξαιρετική αυτοπεποίθηση",
        "du" : "Uiterst zelfverzekerd"
      }
     },
     {
      "value": "0",
      "text": {
      "en" : "Not applicable or feasible where I teach",
      "es" : "No aplicable o factible donde enseño",
      "gr" : "Δεν ισχύει ή δεν είναι εφικτό  εκεί που διδάσκω",
      "du" : "Niet van toepassing of haalbaar waar ik lesgeef"
     }
     }
    ]
   }

]

},

//   ----------------------------------------------End of Carousel Question

//   ----------------------------------------------Start of Carousel Question Page 33
{
"name" : "page 33",

"elements" :

[

  {
    "type": "radiogroup",
    "name": "udlboeq38",
    "title" : {
      "en" : "For each of the following statements about how you plan and run your classes, please select the response that best reflects your confidence level",
      "es" : "Para cada una de las siguientes afirmaciones sobre cómo planifica y dirige sus clases, seleccione la respuesta que mejor refleje su nivel de confianza",
      "gr" : "Για καθεμία από τις ακόλουθες δηλώσεις σχετικά με τον τρόπο που σχεδιάζετε και υλοποιείτε τα μαθήματά σας, επιλέξτε την απάντηση που αντικατοπτρίζει καλύτερα το επίπεδο εμπιστοσύνης σας",
      "du" : "Volgende stellingen hebben betrekking op hoe u uw lessen plant en hoe ze verlopen. Selecteer voor elk van de volgende uitspraken het antwoord dat het beste past bij de mate u zelfverzekerd bent."
    },
    "description": {
      "en" : "I can provide the most appropriate challenge for students by varying the demands and resources of tasks (e.g. offering different levels of difficulty and complexity and different tools).",
      "es" : "Puedo proporcionar el desafío más apropiado para mis alumnos variando las demandas y los recursos para las tareas (por ejemplo, ofreciendo diferentes niveles de dificultad y complejidad y diferentes herramientas).",
      "gr" : "Μπορώ να παρέχω την πιο κατάλληλη πρόκληση για τους μαθητές μου διαφοροποιώντας τις απαιτήσεις και τους πόρους για τις αναθέσεις εργασιών (π.χ. προσφέροντας διαφορετικά επίπεδα δυσκολίας και πολυπλοκότητας και διαφορετικά εργαλεία) ",
      "du" : "Ik kan mijn leerlingen de meest geschikte uitdaging bieden door de eisen en middelen voor taken te variëren (bijvoorbeeld door verschillende moeilijkheidsgraden en complexiteit en verschillende hulpmiddelen aan te bieden) "
    },

    "category" : "sustain",
    "choices": [
     {
      "value": "10",
      "text": {
        "en" : "Not At All Confident",
        "es" : "Nada seguro",
        "gr" : "Καθόλου σίγουρος/η",
        "du" : "Helemaal niet zeker"
     }
    },
     {
      "value": "20",
      "text": {
        "en" : "Slightly Confident",
        "es" : "Ligeramente seguro",
        "gr" : "Ελαφρώς σίγουρος/η",
        "du" : "Een klein beetje zelfverzekerd"
  }
     },
     {
      "value": "30",
      "text": {
        "en" : "Somewhat Confident",
        "es" : "Algo seguro",
        "gr" : "Κάπως σίγουρος/η",
        "du" : "Enigszins zelfverzekerd"
      }
     },
     {
      "value": "40",
      "text": {
        "en" : "Moderately Confident",
        "es" : "Moderadamente seguro",
        "gr" : "Με μέτρια αυτοπεποίθηση",
        "du" : "Matig zelfverzekerd"
      }
     },
     {
      "value": "50",
      "text": {
        "en" : "Extremely Confident",
        "es" : "Extremadamente seguro",
        "gr": "Εξαιρετική αυτοπεποίθηση",
        "du" : "Uiterst zelfverzekerd"
      }
     },
     {
      "value": "0",
      "text": {
      "en" : "Not applicable or feasible where I teach",
      "es" : "No aplicable o factible donde enseño",
      "gr" : "Δεν ισχύει ή δεν είναι εφικτό  εκεί που διδάσκω",
      "du" : "Niet van toepassing of haalbaar waar ik lesgeef"
     }
     }
    ]
   }

]

},

//   ----------------------------------------------End of Carousel Question

//   ----------------------------------------------Start of Carousel Question Page 34
{
"name" : "page 34",

"elements" :

[

  {
    "type": "radiogroup",
    "name": "udlboeq39",
    "title" : {
      "en" : "For each of the following statements about how you plan and run your classes, please select the response that best reflects your confidence level",
      "es" : "Para cada una de las siguientes afirmaciones sobre cómo planifica y dirige sus clases, seleccione la respuesta que mejor refleje su nivel de confianza",
      "gr" : "Για καθεμία από τις ακόλουθες δηλώσεις σχετικά με τον τρόπο που σχεδιάζετε και υλοποιείτε τα μαθήματά σας, επιλέξτε την απάντηση που αντικατοπτρίζει καλύτερα το επίπεδο εμπιστοσύνης σας",
      "du" : "Volgende stellingen hebben betrekking op hoe u uw lessen plant en hoe ze verlopen. Selecteer voor elk van de volgende uitspraken het antwoord dat het beste past bij de mate u zelfverzekerd bent."
    },
    "description": {
      "en" : "I can provide options for how my students can interact with materials and respond (e.g. voice, pen and paper, mouse control, etc.).",
      "es" : "Puedo proporcionar opciones sobre cómo mis alumnos pueden interactuar con los materiales y responder (por ejemplo, voz, lápiz y papel, control del mouse, etc.)",
      "gr" : "Μπορώ να παρέχω επιλογές για το πώς οι μαθητές μου μπορούν να αλληλεπιδράσουν και να ανταποκριθούν με τα μέσα (π.χ. φωνή, στυλό και χαρτί, έλεγχος ποντικιού κ.λπ.) ",
      "du" : "Ik kan opties bieden voor hoe mijn leerlingen met materialen kunnen omgaan en reageren (bijv. stem, pen en papier, muisbesturing, enz.) "
    },

    "category" : "physical",
    "choices": [
     {
      "value": "10",
      "text": {
        "en" : "Not At All Confident",
        "es" : "Nada seguro",
        "gr" : "Καθόλου σίγουρος/η",
        "du" : "Helemaal niet zeker"
     }
    },
     {
      "value": "20",
      "text": {
        "en" : "Slightly Confident",
        "es" : "Ligeramente seguro",
        "gr" : "Ελαφρώς σίγουρος/η",
        "du" : "Een klein beetje zelfverzekerd"
  }
     },
     {
      "value": "30",
      "text": {
        "en" : "Somewhat Confident",
        "es" : "Algo seguro",
        "gr" : "Κάπως σίγουρος/η",
        "du" : "Enigszins zelfverzekerd"
      }
     },
     {
      "value": "40",
      "text": {
        "en" : "Moderately Confident",
        "es" : "Moderadamente seguro",
        "gr" : "Με μέτρια αυτοπεποίθηση",
        "du" : "Matig zelfverzekerd"
      }
     },
     {
      "value": "50",
      "text": {
        "en" : "Extremely Confident",
        "es" : "Extremadamente seguro",
        "gr": "Εξαιρετική αυτοπεποίθηση",
        "du" : "Uiterst zelfverzekerd"
      }
     },
     {
      "value": "0",
      "text": {
      "en" : "Not applicable or feasible where I teach",
      "es" : "No aplicable o factible donde enseño",
      "gr" : "Δεν ισχύει ή δεν είναι εφικτό  εκεί που διδάσκω",
      "du" : "Niet van toepassing of haalbaar waar ik lesgeef"
     }
     }
    ]
   }

]

},

//   ----------------------------------------------End of Carousel Question






  ],


  showQuestionNumbers: "off",
  // "title": "UDL BOE REAL",

  // "description" : "Welcome to the Universal Design for Learning survey. Universal Design for Learning (UDL) is an approach to teaching and learning that aims to give all students equal opportunity to succeed. Are you a school teacher? Are you interested in inclusive education? We would love to hear from you. What is involved?  We would like to ask you some questions about how you plan and run the classes you teach using a Universal Design for Learning framework. The questionnaire is fully anonymised and we will not ask you for your name or any other identifying information.  This will take about 10 minutes. This project is part of the UDL-BOE Erasmus+ project which is funded by the European Union to support post-primary teachers in building inclusive digital learning experiences. If you want any information about the project and how the results will be used, please contact: info@udl-boe.eu or Ann Devitt, devittan@tcd.ie.",
  "showProgressBar": "top",
  "showCompletedPage" : "false",
  "goNextPageAutomatic": "false",

  "pageNextText" : {
    "en" : "Next",
    "es" : "Siguiente",
    "gr" : "Επόμενο",
    "du" : "Volgende"
  },
  
  "pagePrevText" : {
    "en" : "Previous",
    "es" : "Anterior",
    "gr" : "Προηγούμενο",
    "du" : "Vorige"
  },
  "completeText"  : {
    "en" : "Complete",
    "es" : "Completado",
    "gr" : "Ολοκληρωμένο",
    "du" : "Voltooid"
  }

};
