import React from 'react';
import { Link } from "react-router-dom";
import Flags from './Flags';

export default function RightIntro() {
  return (
    <div className='right-intro-div'>
        
       <p
       className="white-label">Invite the User to start</p> 

      <p
       className="white-label">Choose Your Language</p>
    
        
        <Flags/>
        
        <br/>




        <Link to="/udlintro"
        className='yellowButton yellowButtonText'>
            Start
        </Link>

    </div>
  )
}
