import React from 'react';

import styles from '../styles/style.css'

export default function TestSass() {
  return (
    <div>
        
        TestSass


        <header>
        <button>Button 1</button>
        </header>
        
        <div className='container'>
        <button>Button 2</button>
        <button>Button 3</button>
        </div>
        
    </div>
  )
}
