import React, {useState} from 'react';
import classes from './Modal.module.css';
import { Link } from "react-router-dom";




const Modal = props => {

    const [email, setEmail] = useState("")

   

    return (
        <div
            className={classes.Modal}
            style={{
                transform:props.show ? 'translateY(0)' : 'translateY(-100vh)',
                opacity : props.show ? 1 : 0,
                
            }}
        >

            <div className={classes.Heading}>
                    <label
                    className={classes.HeadingText}>{props.heading}</label>
            </div>

            {
                props.type == "email" ? 
                <div> 

                <div style={{ display:"flex", justifyContent:"center"
                , marginTop:"2em"}}>
                <input 
                type="text" name="email"
                value={email}
                onChange={event => setEmail(event.target.value)}
                />
                </div>

                
                <br/>
                <br/>


                <div >
                <Link to="/"
                className="yellowButton-sm"
                style={{ marginLeft:"30%", marginTop:"2em"}}
                onClick={event => {props.sendCustomEmail(event, email)}}>
                  Submit
                </Link>

                <Link to="/"
                className="yellowButton-sm"
                style={{ marginLeft:"5%", marginTop:"2em"}}
                onClick={event => {props.cancelEmailDialog(event);setEmail("") }}>
                  Cancel
                </Link>
                
                <br/><br/>

            {
            props.showValidationMessage?
            <h2 style={{color: "white"
            , marginLeft:"2em"}}>Invalid email: Please enter correct email address.</h2>
                : ""
            }
                
                </div>

                

                </div>
                :
                ""
            }


                <div className={classes.DescriptionText}>
                        {props.children}
                 </div>
        </div>
    );
};


export default Modal;