import React from 'react';

import { Trans, useTranslation, withTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import udllogo from '../images/udl-boe-logo-new.png';
import Flags from './Flags';

function Navbar() {

  // get t function from the useTranslation hook
  const {t} = useTranslation();

  return (
  <div className="nav-bar-div">

     
      {/* Logo */}
      <div style={{display:"flex"}}>
      
          <img src={udllogo} className="udl-logo" /> 

          {/* Title */}

          <label className="udl-title">UDL-BOE Support Tool</label>

      </div>

    </div>

)
}

export default withTranslation()(Navbar);

