
import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import {useParams, useLocation} from 'react-router-dom';
import { RadarChart } from './RadarChart';


import { useTranslation } from 'react-i18next';


import donut1 from '../images/donut1.png';
import donut2 from '../images/donut2.png';
import donut3 from '../images/donut3.png';

import bar1 from '../images/bar1.PNG';
import bar2 from '../images/bar2.PNG';
import bar3 from '../images/bar3.PNG';


import { Link } from "react-router-dom";
import Donut from './Donut';
import Gauge from './Gauge';

import BarChart from './BarChart';

import OER1 from '../pdfs/OER1.pdf';
import CustomProgressBar from './CustomProgressBar';
import CustomCircleProgress from './CustomCircleProgress';
import DynamicOers from './DynamicOers';

import {jsPDF} from 'jspdf';
import html2canvas from 'html2canvas';
import Affiliation from './Affiliation';
import Footer from './Footer';

import Modal from './Modal'


const Mailto = ({ email, subject, body, children, id }) => {
  
  
 
  
  return (
    // <a 
    // className="yellowButton-sm"
    // href={`mailto:${email}?subject=${encodeURIComponent(subject) || ''}&body=${encodeURIComponent(body+""+id) || ''}`}>{children}
    // </a>

    <div>

      {/* <button onClick={sendCustomEmail}
      /> */}

    </div>
  );
};

export default function Results() {

    const {id} = useParams();

    let lang =  localStorage.getItem("key")
    

    const location = useLocation()
   

    

    // teacher data retrieved from database
    const [teacher, setTeacher] = useState(null)

    const [country, setCountry] = useState("");

    const [showEmailDialog, setShowEmailDialog] = useState(false)
    const [showValidationMessage, setShowValidationMessage] = useState(false);
    
    
    const { t } = useTranslation();
    const { i18n } = useTranslation();

    
    

    // 1. 
    const [recuritingInterest, setRecruitingInterest] = useState(0)
    // 2.
    const [susEffort, setSusEffort] = useState(0);
    // 3.
    const [selfRegulation, setSelfRegulation] = useState(0);

    // 4.
    const [perception, setPerception] = useState(0)
    // 5.
    const [lngSymbol, setLngSymbol] = useState(0)
    // 6.
    const [comprehension, setComprehension] = useState(0)

    // 7.
    const [physicalAction, setPhysicalAction] = useState(0)

    // 8.
    const [expression, setExpression] = useState(0);

    // 9. 
    const [execFunction, setExecFunction] = useState(0);

    // Engagement
    const [engagement, setEngagement] = useState(0);
    // Representation
    const [representation, setRepresentation] = useState(0);
    // ActionExpression
    const [actExpression, setActExpression] = useState(0);


    const resultsRef = useRef(null);

    // OERS Div Ref
    const oersRef = useRef(null);


      // res.data looks like below
      //  Uth Oye{"_id":"62b254d5f39cb368be5b12a5","email":"test@gmail.com","unique_id":"14df76de-e1b7-449c-8c16-39debdb5abc3","locale":null,"question1":"item1","country":"Estonia","q1new":"item3","q2new":"female","udlboeq9":"item3","udlboeq10":"item4","udlboeq11":"item3","udlboeq12":"item4","udlboeq13":"item4","udlboeq14":"item2","udlboeq15":"item3","udlboeq16":"item1","udlboeq17":"item3","udlboeq18":"item3","udlboeq19":null,"udlboeq20":"item3","udlboeq21":"item2","udlboeq22":"item4","udlboeq23":"item3","udlboeq24":"item3","udlboeq25":"item2","udlboeq26":"item1","udlboeq27":"item2","udlboeq28":"item3","udlboeq29":"item5","udlboeq30":"item2","udlboeq31":"item2","udlboeq32":"item3","udlboeq33":"item2","udlboeq34":"item3","udlboeq35":"item3","udlboeq36":"item4","udlboeq37":"item3","udlboeq38":"item3","udlboeq39":"item3"}
      //
      

    useEffect(()=>{
      
      
        console.log("$$$$$$$$$Results Start$$$$$$$$$")
      

        console.log("useEffect start" +id)


        i18n.changeLanguage(lang);
       
        


        
           
          // axios.get('http://localhost:5000/api/teacherrecord/'+id)
    
          axios.get('https://app.udl-boe.eu/api/teacherrecord/'+id)
           .then(res => {
            console.log("Uth Oye" + JSON.stringify(res.data))
             setTeacher(res.data)
            //  setCountry(res.data.country ? res.data.country : "")

            i18n.changeLanguage(res.data.locale);

            })
          .catch(err => console.log(err));

       
      
      






    


       
      
       

       

       
          console.log("$$$$$$$$$Results End$$$$$$$$$")

       
      





        
      
      }, [])

      useEffect(()=>{
        
           // udlboe29 and 39 are Physical action
        // udlboeq29 udlboeq39

       console.log("Loading Test start")

        if(teacher != null)
        {

          // 1st start 
        // udlboeq25  udlboeq30 udlboeq31
          setRecruitingInterest(
            (
              
              parseInt(teacher.udlboeq25 ? teacher.udlboeq25 : 0) +
              parseInt(teacher.udlboeq30 ? teacher.udlboeq30 : 0) +
              parseInt(teacher.udlboeq31 ? teacher.udlboeq31 : 0))/3
          )

          setSusEffort(
            (
              parseInt(teacher.udlboeq17 ? teacher.udlboeq17 : 0) + 
              parseInt(teacher.udlboeq20 ? teacher.udlboeq20 : 0) + 
              parseInt(teacher.udlboeq23 ? teacher.udlboeq23 : 0) + 
              parseInt(teacher.udlboeq38 ? teacher.udlboeq38 : 0))/4
            )

          setSelfRegulation(
            (
            parseInt(teacher.udlboeq13? teacher.udlboeq13: 0) + 
            parseInt(teacher.udlboeq16 ? teacher.udlboeq16 : 0) + 
            parseInt(teacher.udlboeq32 ? teacher.udlboeq32 : 0)) / 3 
          )


          setPerception(
            (
            parseInt(teacher.udlboeq26? teacher.udlboeq26: 0) + 
            parseInt(teacher.udlboeq27 ? teacher.udlboeq27 : 0) + 
            parseInt(teacher.udlboeq28 ? teacher.udlboeq28 : 0)) / 3 
          )


          setLngSymbol(
              
            (
            parseInt(teacher.udlboeq11? teacher.udlboeq11: 0) + 
            parseInt(teacher.udlboeq12 ? teacher.udlboeq12 : 0) + 
            parseInt(teacher.udlboeq22 ? teacher.udlboeq22 : 0) + 
            parseInt(teacher.udlboeq33 ? teacher.udlboeq33 : 0) +
            parseInt(teacher.udlboeq34 ? teacher.udlboeq34 : 0) 
            ) / 5
          )

          

          setComprehension(
            (
            parseInt(teacher.udlboeq9? teacher.udlboeq9: 0) + 
            parseInt(teacher.udlboeq19 ? teacher.udlboeq19 : 0) + 
            parseInt(teacher.udlboeq21 ? teacher.udlboeq21 : 0) + 
            parseInt(teacher.udlboeq24 ? teacher.udlboeq24 : 0)
            ) / 4 
          )

          setPhysicalAction( 
            (parseInt(teacher.udlboeq29? teacher.udlboeq29: 0) + 
             parseInt(teacher.udlboeq39 ? teacher.udlboeq39 : 0)
            )/2
           )


           setExpression(
      
             (
              parseInt(teacher.udlboeq10? teacher.udlboeq10: 0) + 
              parseInt(teacher.udlboeq36 ? teacher.udlboeq36 : 0) + 
              parseInt(teacher.udlboeq37 ? teacher.udlboeq37 : 0)) / 3 
           
           )

           setExecFunction(
             (
              parseInt(teacher.udlboeq14? teacher.udlboeq14: 0) + 
              parseInt(teacher.udlboeq15 ? teacher.udlboeq15 : 0) + 
              parseInt(teacher.udlboeq18 ? teacher.udlboeq18 : 0) + 
              parseInt(teacher.udlboeq35 ? teacher.udlboeq35 : 0)
              ) / 4
           )

       


        
          
         

        // 1st end





      }

        console.log("Dimension : Recuriting Interest" + recuritingInterest)
        console.log("Dimension : Sustaining Effort" + susEffort)
        console.log("Dimension : Self Regulation" + selfRegulation)

        console.log("Dimension : Perception" + perception)
        console.log("Dimension : Language and Symbols" + lngSymbol)
        console.log("Dimension : Comprehension" + comprehension)


        console.log("Dimension : Physical Action" + physicalAction)
        console.log("Dimension : Expression" + expression)
        console.log("Dimension : Executive Functions" + execFunction)
        
        const engagementVar =  
        (
          // 2nd porblem was wrong physicalAction used here 
        (recuritingInterest ? recuritingInterest : 0) +
            
        (susEffort ? susEffort : 0) +
        
        (selfRegulation ?  selfRegulation : 0)

        ) / 3;


       

        setEngagement(
          
          (Math.round(engagementVar * 200) / 100).toFixed(0)

        )


        const representationVar =  
        (
        (perception ? perception : 0) +
            
        (lngSymbol ? lngSymbol : 0) +
        
        (comprehension ?  comprehension : 0)

        ) / 3;


        setRepresentation(
          
          (Math.round(representationVar * 200) / 100).toFixed(0)

        )


        const actExpressionVar =  
        (
        (physicalAction ? physicalAction : 0) +
            
        (expression ? expression : 0) +
        
        (execFunction ?  execFunction : 0)

        ) / 3;


        setActExpression(
          
          (Math.round(actExpressionVar * 200) / 100).toFixed(0)

        )


     
          console.log("Engagement" + engagement)
          console.log("Representation" + representation)
          console.log("Action Expression" + actExpression)
       

          console.log("Loading Test en")
      
        })
      
      

      

   



    
    // const downloadPdfMultiPage = async (e) => {
    //     e.preventDefault()
      

    //     var quotes = document.getElementById('container-fluid');
    //     html2canvas(quotes).then((canvas) => {
    //          //! MAKE YOUR PDF
    //          var pdf = new jsPDF('p', 'pt', 'letter');
     
    //          for (var i = 0; i <= quotes.clientHeight/980; i++) {
    //              //! This is all just html2canvas stuff
    //              var srcImg  = canvas;
    //              var sX      = 0;
    //              var sY      = 980*i; // start 980 pixels down for every new page
    //              var sWidth  = 900;
    //              var sHeight = 980;
    //              var dX      = 0;
    //              var dY      = 0;
    //              var dWidth  = 900;
    //              var dHeight = 980;
     
    //              window.onePageCanvas = document.createElement("canvas");
    //              onePageCanvas.setAttribute('width', 900);
    //              onePageCanvas.setAttribute('height', 980);
    //              var ctx = onePageCanvas.getContext('2d');
    //              // details on this usage of this function: 
    //              // https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Using_images#Slicing
    //              ctx.drawImage(srcImg,sX,sY,sWidth,sHeight,dX,dY,dWidth,dHeight);
     
    //              // document.body.appendChild(canvas);
    //              var canvasDataURL = onePageCanvas.toDataURL("image/png", 1.0);
     
    //              var width         = onePageCanvas.width;
    //              var height        = onePageCanvas.clientHeight;
     
    //              //! If we're on anything other than the first page,
    //              // add another page
    //              if (i > 0) {
    //                  pdf.addPage(612, 791); //8.5" x 11" in pts (in*72)
    //              }
    //              //! now we declare that we're working on that page
    //              pdf.setPage(i+1);
    //              //! now we add content to that page!
    //              pdf.addImage(canvasDataURL, 'PNG', 20, 40, (width*.62), (height*.62));
     
    //          }
    //          //! after the for loop is finished running, we save the pdf.
    //          pdf.save('Test.pdf');
    //    });
    //  }
    //   =========

   
    const cancelEmailDialog = e => {
      e.preventDefault();
      console.log("Cancel Email Dialog methodsss")

      setShowEmailDialog(false)
      setShowValidationMessage(false)
  }

    const downloadPdf = async (e) => {
      e.preventDefault()
      console.log("Start pdf downloading");

    const input = resultsRef.current
    // const element = oersRef.current;
    const canvas = await html2canvas(input);
    const data = canvas.toDataURL('image/png');

    const pdf = new jsPDF("landscape", 'mm', 'a4');
   
    
  
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight =
      (imgProperties.height * pdfWidth) / imgProperties.width;

    pdf.addImage(data, 'PNG', 0, 25, pdfWidth, pdfHeight);
    
      let y = 10;
      if(y>pdfHeight){
        pdf.addPage() // adds another page to pdf
        y= 0;
    }
    
    // pdf.addPage()
   
    pdf.setTextColor('black')
    
    // pdf.text(100, 10, "UDL Evaluation Results").setFontSize(24).setFont("Ubuntu", "bold")

    pdf.setFont("Ubuntu", "bold").setFontSize(30).text(90, 15, "UDL Evaluation Results").setFont("Ubuntu", "bold");

    // pdf.text(40, 135, '..................................................................................................................')
   
    // pdf.text(10, 145, 'Here is the Link to your Results and OERS: https://app.udl-boe.eu/results/'+id).setFontSize(12);
    
    pdf.setFont("Ubuntu", "bold").setFontSize(20).text(0, 155, "............................................................................................................................................................................").setFont("Ubuntu", "bold");


    pdf.setFont("Ubuntu", "bold").setFontSize(14).text(10, 145, 'Here is the Link to your Results and OERS: https://app.udl-boe.eu/results/'+id).setFont("Ubuntu", "normal")

    
    // pdf.text(40, 150, '..................................................................................................................')
    // pdf.setFillColor("pink");
    // pdf.rect(0, 140, 50, 50, "FD");

    
    
    pdf.save('My_UDL_Results.pdf');

      console.log("End pdf downloading");
    }


    const sendCustomEmail = async (e, email) => 
    {
      // console.log("Emailee send starts" +teacher.email
      // + "... Engagement : " +engagement + "Representation " +representation
      // + ".... Action and Expression" +actExpression);

      
      e.preventDefault()
      console.log("Your email is " + email)

      const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

      if(email!="" && email.match(isValidEmail))
      {
        console.log("Valid email");

        console.log("SEND EMAIL")
      
      // Send email, Overall score (average of 3),
      // Percentage of 3 individuals
      // Calucate the average of 3 on server side rather
      // const element = resultsRef.current;
      // const canvas = await html2canvas(element);
      // const dataObj = canvas.toDataURL('image/png');

      const dataj = {
        email : email,
        engagment : engagement,
        representation : representation,
        actExpression : actExpression,
        id : id
      }

      console.log("SEND EMAIL" + JSON.stringify(dataj))

      // console.log("Humtv" +JSON.stringify(dataj.data))
      
      // axios.post('https://app.udl-boe.eu/api/mail/'+dataj).
      // axios.post('http://52.209.244.61:5000/mail', dataj).
      axios.post('https://app.udl-boe.eu/api/mail/',dataj).
      // axios.post('http://localhost:5000/api/mail/',dataj).
      then(res => {
        console.log("Puply" + res)
      })
      .catch(err =>{
        console.log(err)
      })
     
      console.log("Email send end")

      setShowEmailDialog(false);
      setShowValidationMessage(false)
      }
      else
      {
        console.log("Invalid email")
        setShowValidationMessage(true)
        return;
      }


    
    }

  return (

    <div

    style={{ backgroundColor:"#525252",
     height:"124em",
   }}>


    {/* Modal for getting email as input start */}

    <Modal 
    show = {showEmailDialog}
    heading={"Please enter your email"}
    type = "email"
    cancelEmailDialog = {cancelEmailDialog}
    sendCustomEmail = {sendCustomEmail}
    showValidationMessage = {showValidationMessage}/>
    
   {/* Modal for getting email as input ends */}
  

  


    <div>


    {/* <div className="results-head" style={{ height:"3.5em"}}>

   


          <label className='title-style-unbold'>
            Here is your results report. You can also download as pdf or send it as email. 
          
            
          </label>

          
    </div> */}

    <div className="results-head">
          
          
          <label className='title-style'>{t('resultshead')}</label>
    
          <br/>

          <label className='title-style-unbold'>

              {t('resultsdesc')}
          
            
          </label>
{/* 
          <div style={{position:"absolute", right:20, top:"15em"}}>
          <div>
                <Link 
                to={`/demographics/${id}`}
                className="yellowButton-sm">
                  Research Survey
                </Link>
                </div>

          </div> */}

          

         
           
          

          <div style={{backgroundColor:"#5aaeb5", height:"50px", marginTop:"5px"}}>
          <label
          className="overview-lbl-unb" style={{color:"white"}}>{t('overview')}</label>


        </div>

        
    </div>

    

    <div className="full-results-pdf" ref={resultsRef}>

        <h1 style={{display:"none"}}>Hells</h1>

        <div className="results-donut">

          {/* <div style={{display:"flex", justifyContent:"center"}}>
          <label
          className="overview-lbl-unb">Overviews </label>
          </div> */}

          <div style={{display:"flex", columnGap:"7em", justifyContent:"center"
          }}>
          <div className="donut-box" style={{textAlign:"center"}}>
            <div
            className="overview-lbl-sm">{t('confeng')}</div>
            <div style={{marginTop:"20px"}}>
              {/* <img src={donut1}
              className="donut-style"/> */}

            {/* <Gauge

              bgColor="#DC3232"
              id="gauge2"
            /> */}


            <CustomCircleProgress
            strokeWidth={8} percentage={engagement? engagement: 10}
            color = {"#488a56"}/>


            </div>

          </div>

          <div className="donut-box" style={{textAlign:"center"}}>
            <div
            className="overview-lbl-sm">{t('confrep')}</div>
            <div style={{marginTop:"20px"}}>
              {/* <img src={donut1}
              className="donut-style"/> */}

            {/* <Gauge

              bgColor="#DC3232"
              id="gauge2"
            /> */}

            <CustomCircleProgress
            strokeWidth={8} percentage={representation? representation: 20}
            color = {"#705c98"}/>

            </div>

          </div>

          <div className="donut-box" style={{textAlign:"center"}}>
            <div
            className="overview-lbl-sm">{t('confact')}</div>
            <div style={{marginTop:"20px"}}>
              {/* <img src={donut1}
              className="donut-style"/> */}

            {/* <Gauge

              bgColor="#DC3232"
              id="gauge2"
            /> */}

            <CustomCircleProgress
            strokeWidth={8} percentage={actExpression ? actExpression : 20}
            color = {"#4280b6"}/>

            </div>

          </div>
          </div>


        </div>

        <div className="results-bar"
        style={{display:"flex", justifyContent:"center",
         paddingTop:"2%", textAlign:"center"}}
         >


              <div className="bar-div" >
                {/* <img src={bar1}
                style={{width:"400px", height:"180px"}}/> */}

               {/* <BarChart/> */}


                <CustomProgressBar
                bgcolor= {"#b7d9c1"}
                bgcolorcomp = {"#488a56"}
                completed={recuritingInterest * 2}
                label = {"Recruiting Interest"}/>

                <br/>

                <CustomProgressBar
                bgcolor= {"#b7d9c1"}
                bgcolorcomp = {"#488a56"}
                completed={susEffort * 2}
                label = {"Sustaining Effort & Persistence"}/>

                <br/>

                <CustomProgressBar
                bgcolor= {"#b7d9c1"}
                bgcolorcomp = {"#488a56"}
                completed={selfRegulation * 2}
                label = {"Self-Regulation"}/>


                {/* <br/>
                <br/>

                <Link to="/"
                className="yellowButton-sm"
                style={{padding: "14px 155px 13px"}}>
                  OER1
                </Link>

                <br/>
                <br/>
                <br/>


                <Link to="/"
                className="yellowButton-sm">
                  Go to Learning Resources
                </Link> */}

              </div>
              <div className="bar-div">
              {/* <BarChart/> */}

                <CustomProgressBar
                bgcolor= {"#ccc4d9"}
                bgcolorcomp = {"#705c98"}
                completed={perception * 2}
                label = {"Perception"}/>

                <br/>

                <CustomProgressBar
                bgcolor= {"#ccc4d9"}
                bgcolorcomp = {"#705c98"}
                completed={lngSymbol * 2}
                label = {"Language & Symbols"}/>

                <br/>

                <CustomProgressBar
                bgcolor= {"#ccc4d9"}
                bgcolorcomp = {"#705c98"}
                completed={comprehension * 2}
                label = {"Comprehension"}/>

                {/* <br/>
                <br/>

                <Link to="/"
                className="yellowButton-sm">
                  Go to Learning Resources
                </Link> */}
              </div>
              <div className="bar-div">
              {/* <img src={bar3}
                style={{width:"400px", height:"180px"}}/> */}

                      {/* <BarChart/> */}

                      <CustomProgressBar
                bgcolor= {"#b5daf0"}
                bgcolorcomp = {"#4280b6"}
                completed={physicalAction * 2}
                label = {"Physical Action"}/>

                <br/>

                <CustomProgressBar
                bgcolor= {"#b5daf0"}
                bgcolorcomp = {"#4280b6"}
                completed={expression * 2}
                label = {"Expression & Communication"}/>

                <br/>

                <CustomProgressBar
               bgcolor= {"#b5daf0"}
               bgcolorcomp = {"#4280b6"}
                completed={execFunction * 2}
                label = {"Executive Functions"}/>


                {/* <br/>
                <br/>

                <Link to="/"
                className="yellowButton-sm">
                  Go to Learning Resources
                </Link> */}


              </div>


              

              
            


        </div>

              
            
        


        </div>

        
        <div className="results-head" style={{display:"flex", justifyContent:"center", columnGap:"50px", paddingTop:"50px",
      backgroundColor:"#b0d8dc"}}>
          
          <div style={{marginTop:"-14px"}}>
              {/* <Link to="/"
                className="yellowButton-sm"> 
                  Email my Results
                 
              </Link> */}

              {/* <Mailto email="bilal.ahmad@learnovatecentre.org" subject="Your OER Results" 
              body="http://app.udl-boe.eu:3000/results/"
              id={id}>
              Email my Resultss
              </Mailto> */}

              <button
              className="yellowButton-sm" 
              onClick={event=> setShowEmailDialog(true)}
              // disabled
              //  onClick={()=> alert("Available in next version")}
              > 
                {t('emailresults')}
              </button>



              </div>

                <div>
                <Link to="/"
                className="yellowButton-sm"
                onClick={e=>downloadPdf(e)}>
                  {t('downloadpdf')}
                </Link>
                </div>

                <div>
                <Link 
                to={`/demographics/${id}`}
                className="yellowButton-sm">
                  {t('rsrchsurveybtn')}
                </Link>
                </div>

                

                
        </div>


        <div className="results-head" style={{height:"1em"}}>
        <label className='title-style-unbold'>
           
            
           
            <label className='title-style'> {t('rcmnddoers')} </label>
            
          </label>
         

        </div>

        <div className="results-head" style={{height:"3em"}}>
        <label className='title-style-unbold'>
           
            
           {t('rcmnddoersdesc')}
            
          </label>
        </div>


    <div className="results-bar-2"
        style={{
     }}

     ref={oersRef}
        >
           {/* 4 boxes */}

           <DynamicOers
                id = {id}
                recuritingInterest = {recuritingInterest}
                susEffort = {susEffort}
                selfRegulation = {selfRegulation}
                perception =  {perception}
                lngSymbol = {lngSymbol}
                comprehension = {comprehension}
                physicalAction = {physicalAction}
                expression = {expression}
                execFunction = {execFunction}
           />
    </div>

        


        

    </div>

            {/* <div style={{display:"inline-flex", columnGap:"50px"
          ,marginTop:"2%", position:"absolute", right:"50px"}}> */}


          <div>
            {/* <Affiliation/> */}
            <Footer/>

          </div>


    </div>
  )
}
