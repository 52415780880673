
import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import {useParams} from 'react-router-dom';
import { RadarChart } from './RadarChart';

import donut1 from '../images/donut1.png';
import donut2 from '../images/donut2.png';
import donut3 from '../images/donut3.png';

import bar1 from '../images/bar1.PNG';
import bar2 from '../images/bar2.PNG';
import bar3 from '../images/bar3.PNG';


import { Link, useLocation } from "react-router-dom";
import Donut from './Donut';
import Gauge from './Gauge';

import BarChart from './BarChart';

import OER1 from '../pdfs/OER1.pdf';
import CustomProgressBar from './CustomProgressBar';
import CustomCircleProgress from './CustomCircleProgress';
import DynamicOers from './DynamicOers';

import {jsPDF} from 'jspdf';
import html2canvas from 'html2canvas';
import { useMediaQuery } from 'react-responsive'
import DynamicOerssm from './DynamicOerssm';

export default function Resultssm() {

    const {id} = useParams();
    const [teacher, setTeacher] = useState(null)

    const [country, setCountry] = useState("");

    const [physicalAction, setPhysicalAction] = useState(0)

    const resultsRef = useRef(null);


      // res.data looks like below
      //  Uth Oye{"_id":"62b254d5f39cb368be5b12a5","email":"test@gmail.com","unique_id":"14df76de-e1b7-449c-8c16-39debdb5abc3","locale":null,"question1":"item1","country":"Estonia","q1new":"item3","q2new":"female","udlboeq9":"item3","udlboeq10":"item4","udlboeq11":"item3","udlboeq12":"item4","udlboeq13":"item4","udlboeq14":"item2","udlboeq15":"item3","udlboeq16":"item1","udlboeq17":"item3","udlboeq18":"item3","udlboeq19":null,"udlboeq20":"item3","udlboeq21":"item2","udlboeq22":"item4","udlboeq23":"item3","udlboeq24":"item3","udlboeq25":"item2","udlboeq26":"item1","udlboeq27":"item2","udlboeq28":"item3","udlboeq29":"item5","udlboeq30":"item2","udlboeq31":"item2","udlboeq32":"item3","udlboeq33":"item2","udlboeq34":"item3","udlboeq35":"item3","udlboeq36":"item4","udlboeq37":"item3","udlboeq38":"item3","udlboeq39":"item3"}
      //
      const isLaptop = useMediaQuery({
        query: "(min-device-width: 1280px)",
      });
    
      const isBigScreen = useMediaQuery({
        query: "(min-device-width: 1920px )",
      });

    useEffect(()=>{
      console.log("useEffect start")
      axios.get('http://52.209.244.61:5000/teacherrecord/'+id)
       .then(res => {
        console.log("Uth Oye" + JSON.stringify(res.data))
         setTeacher(res.data)
         setCountry(res.data.country)
        })
      .catch(err => console.log(err));

        // udlboe29 and 39 are Physical action
        // udlboeq29 udlboeq39
        if(teacher != null)
        {
        if(teacher.udlboeq29 == "item1")
        {
          if(teacher.udlboeq39 == "item1")
          {
            setPhysicalAction(20)
          }
          else
          if(teacher.udlboeq39 == "item2")
          {
            setPhysicalAction(30)
          }
          else
          if(teacher.udlboeq39 == "item3")
          {
            setPhysicalAction(40)
          }
          else
          if(teacher.udlboeq39 == "item4")
          {
            setPhysicalAction(50)
          }
          else
          if(teacher.udlboeq39 == "item5")
          {
            setPhysicalAction(60)
          }

        }

        if(teacher.udlboeq29 == "item2")
        {
          if(teacher.udlboeq39 == "item1")
          {
            setPhysicalAction(30)
          }
          else
          if(teacher.udlboeq39 == "item2")
          {
            setPhysicalAction(40)
          }
          else
          if(teacher.udlboeq39 == "item3")
          {
            setPhysicalAction(50)
          }
          else
          if(teacher.udlboeq39 == "item4")
          {
            setPhysicalAction(60)
          }
          else
          if(teacher.udlboeq39 == "item5")
          {
            setPhysicalAction(70)
          }

        }

        if(teacher.udlboeq29 == "item3")
        {
          if(teacher.udlboeq39 == "item1")
          {
            setPhysicalAction(40)
          }
          else
          if(teacher.udlboeq39 == "item2")
          {
            setPhysicalAction(50)
          }
          else
          if(teacher.udlboeq39 == "item3")
          {
            setPhysicalAction(60)
          }
          else
          if(teacher.udlboeq39 == "item4")
          {
            setPhysicalAction(70)
          }
          else
          if(teacher.udlboeq39 == "item5")
          {
            setPhysicalAction(80)
          }

        }

        if(teacher.udlboeq29 == "item4")
        {
          if(teacher.udlboeq39 == "item1")
          {
            setPhysicalAction(50)
          }
          else
          if(teacher.udlboeq39 == "item2")
          {
            setPhysicalAction(60)
          }
          else
          if(teacher.udlboeq39 == "item3")
          {
            setPhysicalAction(70)
          }
          else
          if(teacher.udlboeq39 == "item4")
          {
            setPhysicalAction(80)
          }
          else
          if(teacher.udlboeq39 == "item5")
          {
            setPhysicalAction(90)
          }

        }

        if(teacher.udlboeq29 == "item5")
        {
          if(teacher.udlboeq39 == "item1")
          {
            setPhysicalAction(60)
          }
          else
          if(teacher.udlboeq39 == "item2")
          {
            setPhysicalAction(70)
          }
          else
          if(teacher.udlboeq39 == "item3")
          {
            setPhysicalAction(80)
          }
          else
          if(teacher.udlboeq39 == "item4")
          {
            setPhysicalAction(90)
          }
          else
          if(teacher.udlboeq39 == "item5")
          {
            setPhysicalAction(100)
          }

        }

      }

        console.log("Panchi" +physicalAction)



    })


    const downloadPdf = async (e) => {
      e.preventDefault()
      console.log("Start pdf downloading");

    // const input = resultsRef.current
    const element = resultsRef.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL('image/png');

    const pdf = new jsPDF("landscape", 'mm', 'a4');
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight =
      (imgProperties.height * pdfWidth) / imgProperties.width;

    pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
    pdf.save('My_UDL_Results.pdf');

      console.log("End pdf downloading");
    }

  return (

    <div

    style={{ backgroundColor:"#525252",
    padding:"50px", height:"2100px"}}>



    <div>


    <div className="results-head">
          <label className='title-style-unbold'>
            Here is your results report. You can also download as pdf or send it as email. 
            Based on your results, we recommend the below Open Educational Resources (OERs) which you may find useful in better understanding UDL.
            
          </label>
    </div>

    <div className="results-head" style={{paddingTop:"5%"}}>
          <label className='title-style'>UDL Evaluation Results </label>
    </div>

    <div className="full-results-pdf" ref={resultsRef}>

        <div className="results-donut">

          <div style={{display:"flex", justifyContent:"center"}}>
          <label
          className="overview-lbl">Overview </label>
          </div>

          <div style={{display:"flex", columnGap:"20px", justifyContent:"center"
        , paddingTop:"1%", marginLeft:"1%", marginRight:"1%"}}>
          <div className="donut-box" style={{textAlign:"center"}}>
            <div
            className="overview-lbl-sm">Confidence in Providng Multiple Means of Engagement</div>
            <div style={{marginTop:"20px"}}>
              {/* <img src={donut1}
              className="donut-style"/> */}

            {/* <Gauge

              bgColor="#DC3232"
              id="gauge2"
            /> */}


            <CustomCircleProgress
            strokeWidth={8} percentage={82.05}
            color = {"#488a56"}/>


            </div>

          </div>

          <div className="donut-box" style={{textAlign:"center"}}>
            <div
            className="overview-lbl-sm">Confidence in Proving Multiple Means of Representation</div>
            <div style={{marginTop:"20px"}}>
              {/* <img src={donut1}
              className="donut-style"/> */}

            {/* <Gauge

              bgColor="#DC3232"
              id="gauge2"
            /> */}

            <CustomCircleProgress
            strokeWidth={8} percentage={61.35}
            color = {"#705c98"}/>

            </div>

          </div>

          <div className="donut-box" style={{textAlign:"center"}}>
            <div
            className="overview-lbl-sm">Confidence in Proving Multiple Means of Action and Expression</div>
            <div style={{marginTop:"20px"}}>
              {/* <img src={donut1}
              className="donut-style"/> */}

            {/* <Gauge

              bgColor="#DC3232"
              id="gauge2"
            /> */}

            <CustomCircleProgress
            strokeWidth={8} percentage={88.75}
            color = {"#4280b6"}/>

            </div>

          </div>
          </div>


        </div>

        <div className="results-bar"
        style={{display:"flex", justifyContent:"center",
         paddingTop:"2%", textAlign:"center",  paddingLeft:"0.10%",
        paddingRight:"0.10%", }}
         >


              <div className="bar-div" >
                {/* <img src={bar1}
                style={{width:"400px", height:"180px"}}/> */}

               {/* <BarChart/> */}


                <CustomProgressBar
                bgcolor= {"#b7d9c1"}
                bgcolorcomp = {"#488a56"}
                completed={30}
                label = {"Recruiting Interest"}/>

                <br/>

                <CustomProgressBar
                bgcolor= {"#b7d9c1"}
                bgcolorcomp = {"#488a56"}
                completed={50}
                label = {"Sustaining Effort & Persistence"}/>

                <br/>

                <CustomProgressBar
                bgcolor= {"#b7d9c1"}
                bgcolorcomp = {"#488a56"}
                completed={30}
                label = {"Self-Regulation"}/>


                {/* <br/>
                <br/>

                <Link to="/"
                className="yellowButton-sm"
                style={{padding: "14px 155px 13px"}}>
                  OER1
                </Link>

                <br/>
                <br/>
                <br/>


                <Link to="/"
                className="yellowButton-sm">
                  Go to Learning Resources
                </Link> */}

              </div>
              <div className="bar-div">
              {/* <BarChart/> */}

                <CustomProgressBar
                bgcolor= {"#ccc4d9"}
                bgcolorcomp = {"#705c98"}
                completed={40}
                label = {"Perception"}/>

                <br/>

                <CustomProgressBar
                bgcolor= {"#ccc4d9"}
                bgcolorcomp = {"#705c98"}
                completed={20}
                label = {"Language & Symbols"}/>

                <br/>

                <CustomProgressBar
                bgcolor= {"#ccc4d9"}
                bgcolorcomp = {"#705c98"}
                completed={60}
                label = {"Comprehension"}/>

                {/* <br/>
                <br/>

                <Link to="/"
                className="yellowButton-sm">
                  Go to Learning Resources
                </Link> */}
              </div>
              <div className="bar-div">
              {/* <img src={bar3}
                style={{width:"400px", height:"180px"}}/> */}

                      {/* <BarChart/> */}

                      <CustomProgressBar
                bgcolor= {"#b5daf0"}
                bgcolorcomp = {"#4280b6"}
                completed={physicalAction}
                label = {"Physical Action"}/>

                <br/>

                <CustomProgressBar
                bgcolor= {"#b5daf0"}
                bgcolorcomp = {"#4280b6"}
                completed={60}
                label = {"Expression & Communication"}/>

                <br/>

                <CustomProgressBar
               bgcolor= {"#b5daf0"}
               bgcolorcomp = {"#4280b6"}
                completed={100}
                label = {"Executive Functions"}/>


                {/* <br/>
                <br/>

                <Link to="/"
                className="yellowButton-sm">
                  Go to Learning Resources
                </Link> */}


              </div>


              

              
            


        </div>

              
            
        


        </div>

        
        <div className="results-head" style={{display:"flex", justifyContent:"center", columnGap:"50px", paddingTop:"50px",
      backgroundColor:"#b0d8dc"}}>
          
          <div>
              <Link to="/"
                className="yellowButton-sm"> 
                  Email my Results
                  {/* <a href="mailto:bilal.ahmad@learnovatecentre.org?subject=look at this website&body=Hi,I found this website and thought you might like it http://www.geocities.com/wowhtml/">tell a friend</a> */}
              </Link>
              </div>

              <div>
                <Link to="/"
                className="yellowButton-sm"
                onClick={e=>downloadPdf(e)}>
                  Download PDF
                </Link>
                </div>
        </div>

        <div className="results-head">
          <label className='title-style'>Your OERs</label>
        </div>


    <div className="results-bar-2"
        style={{height:"800px", overflowY:"auto", overflowX:"hidden", }}
        >
           {/* 4 boxes */}

           

           {
               isBigScreen ?
               <DynamicOers/>:
               isLaptop?
               <DynamicOerssm/>:
               <DynamicOers/>

           }
    </div>

        


        

    </div>

            {/* <div style={{display:"inline-flex", columnGap:"50px"
          ,marginTop:"2%", position:"absolute", right:"50px"}}> */}


    </div>
  )
}
