import React, { useState, Component } from "react";
import * as Survey from "survey-core";
import * as SurveyReact from "survey-react-ui";
//Import localization
import "survey-core/survey.i18n.js";
//Import Survey styles
import "survey-core/defaultV2.css";
import * as widgets from "surveyjs-widgets";
import "nouislider/dist/nouislider.css";
import { Link, useLocation, useHistory } from "react-router-dom";
import { v4 as uuid } from 'uuid';
import axios from 'axios';
import Modal from './components/Modal';
import { json } from "./survey_json.js";
import { send } from "process";
import ProgressBar from "./components/ProgressBar";
import { ImShuffle } from "react-icons/im";
import Spinner from "./components/Spinner";
import Affiliation from "./components/Affiliation";
import Footer from "./components/Footer";
import { result } from "lodash";



import { useTranslation } from 'react-i18next';


widgets.nouislider(Survey);


//Export new question with react rendering
export { MyQuestion } from "./MyQuestion";

Survey.StylesManager.applyTheme("defaultV2");


const newclass = {
    backgroundColor : "red"
}

let temp = null;

let cpage = 1;


function shuffle(array1) {
    var array = [].concat(array1);
    var currentIndex = array.length, temporaryValue, randomIndex;
    console.log("Kirnay" +currentIndex);
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
  
      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
  
    array1.splice(0, array1.length);
      for (var i = 0; i < array.length; i++) {
        array1.push(array[i]);
      }
    
   
  
    return array;
  }

  var test;
class SurveyComponent extends Component {

    

    constructor(props) {
        super(props);
        // test contains the current language
         test = this.props.cl;
        console.log("Ishka" +test)
        this.state = 
        {
            myJSON : Object.assign({"locale" : ""+test},json),
            showModal : false,
            userID : "",
            
            latestPage: 1,


            showSpinner : false,

            email : "",

            selectedLang : test,

            
        }

        console.log("Sha"+ test)

   
        this.randomizeMethod();
        
        console.log("Mahiya"+JSON.stringify(this.state.myJSON));

        this.survey = new Survey.Model(this.state.myJSON);
        
        // This is the current Language, change in surveyjs
        console.log("Finally" +props.cl)

        this.survey.onAfterRenderQuestion
        .add(function (survey, options){
            // console.log("*******************"+JSON.stringify(survey))

            

            console.log("Python" +survey.getQuestionByName('udlboeq9').description)


            // Get current page
            // Set 4 pages only, as 4th contains all the matrix questions
            console.log("Piya" +survey.currentPage.name)

            
            cpage = survey.currentPage.name

            // this.setState({
            //     latestPage : "abc"
            // })

            updatePageNumber(cpage)

            
            
            
          

            


            console.log("Random Pages Test end")
            
        })


    

         

       

        // Carousel Functionality start
        this.survey.onUpdateQuestionCssClasses
        .add(function(survey, options){

            


            var classes = options.cssClasses;

            classes.root = newclass;
            console.log("Uth Oye" + classes)

            
           


            
            // Radio Buttons Carousel Like Functionality as in Qualtrics
            if (options.question.getType() === "radiogroup" && options.question.name !== "udlboeq1"
            && options.question.name !== "udlboeq2"
            && options.question.name !== "udlboeq4"
            && options.question.name !== "udlboeq5"
            && options.question.name !== "q1new"
            && options.question.name !== "newquestion5"
            && options.question.name !== "newquestion6"
            && options.question.name !== "newquestion8"
            && options.question.name !== "newquestion9"
            ) {
                classes.description = "myclassba";
            }
        })

         // Radio button go to next page if user selects an option
        //  this.survey.onValueChanged.add((sender, options) => {
        //     console.log("value changed!" +options.name);

            
            
            
        //     console.log("Current Page is :" + this.survey.getPropertyValue("isShowNextButton"))
            

        //     if(options.name == "udlboeq9" || options.name == "udlboeq10" || 
        //     options.name == "udlboeq11" || options.name == "udlboeq12" ||
        //     options.name == "udlboeq13" || options.name == "udlboeq14" ||
        //     options.name == "udlboeq15" || options.name == "udlboeq16" ||
        //     options.name == "udlboeq17" || options.name == "udlboeq18" ||
        //     options.name == "udlboeq19" || options.name == "udlboeq20" ||
        //     options.name == "udlboeq21" || options.name == "udlboeq22" ||
        //     options.name == "udlboeq23" || options.name == "udlboeq24" ||
        //     options.name == "udlboeq25" || options.name == "udlboeq26" ||
        //     options.name == "udlboeq27" || options.name == "udlboeq28" ||
        //     options.name == "udlboeq29" || options.name == "udlboeq30" ||
        //     options.name == "udlboeq31" || options.name == "udlboeq32" ||
        //     options.name == "udlboeq33" || options.name == "udlboeq34" ||
        //     options.name == "udlboeq35" || options.name == "udlboeq36" ||
        //     options.name == "udlboeq37" || options.name == "udlboeq38" ||
        //     options.name == "udlboeq39"

        //     )
        //     {

        //         // When a question is answered, show the spinner
        //         // Which is a component, showing a spinner from a react library

        //         this.setState({
        //             showSpinner : true
        //         })


        //         // Now commented these to hide the buttons
        //         // As with the component appearing on top, no need to hide these 
        //         // Buttons, as they cannot be used, until the other component of loading on top is gone 
        //         // this.survey.setPropertyValue("isShowPrevButton", false);
        //         // this.survey.setPropertyValue("isShowNextButton", false);
                
                

        //         // As soon as value is changed for a question, it triggers to go to next page
        //         setTimeout(()=>{

        //             this.survey.nextPage()

        //         // When 2 seconds are passed, hide the component with spinner
        //             this.setState({
        //                 showSpinner : false
        //             })

        //         }, 2000)
                
        //     }
            
        //     // Get the answer of question 1, and redirect user to a good luck route
        //     if(options.name=="udlboeq1"){
        //         console.log("Q1 changed" +JSON.stringify(sender.data) )
                
                

        //         if(sender.data.udlboeq1 == "item1")
        //         {
        //             console.log("Yes")
                    
                    
        //         }
        //         else
        //         {
        //             console.log("No")
        //             // Go to home page
        //             window.location = "/"
        //         }
        //     }

            


        //     });
        
        // Carousel Functionality end

        


        this.survey.css = {
            question : {
                title : "title_ba"
            },

            radiogroup:{
                root : "radioroot",
                item: "uncheckedradio",
                itemChecked: "checkedradio",
                controlLabel : "radioLabel", 
            },
            checkbox: {
               
                item: "default-checkbox",
                itemChecked: "checked-checkbox",
                controlLabel : "checkboxLabel"
               
            }
        }

       


        // this.survey.onComplete.add((sender, options) => {
        // console.log("Complete! Response:" +  JSON.stringify(sender.data));
        // });

        // Save this whole submitted data object to database
        // Update Survey Submission method and show a dialog
        

        // this.survey.onCompleting.add((result, options)=> {
        //     var choice = window.confirm("Press OK if you are happy to submit your answers. \n Else press Cancel");
        //     //     console.log("Choiccceeeee" + choice)
            
           
        //    if(!choice) options.allowComplete = choice;


        //     if(choice)
        //     {
        //         console.log("You are happy to submit");

                

        //             // Submit Final 
        //             console.log("Survey Submitted");
        //             // result.data contains the answers submitted by user
        //             // ****************************************************
        //             console.log("Critical" + JSON.stringify(result.data))
        
        
        //             const unique_id = uuid();
        
        //             // Get email from dialog
        //             let metadata = {"email" : "testdeafault@gmail.com", "unique_id" : ""+unique_id, "locale " : ""+this.state.myJSON.locale} 
        
                
        //             var dataObj = Object.assign(metadata, result.data);
        
        //             // console.log("Merged object with unique id and email " + JSON.stringify(dataObj))
                    
        //             // dataObj = {
        //             //      "email" : "test@gmail.com",
        //             //      "unique_id" : ""+unique_id,
        //             //      "locale " : ""+this.state.myJSON.locale,
                         
        //             // }
        
        //             console.log("Hit api start" + JSON.stringify(dataObj));
        //             // https://alpaca.learnovatecentre.org/api/
        //             axios.post('https://app.udl-boe.eu/api/record', dataObj)
        //             // axios.post('http://localhost:5000/api/record', dataObj)
        //             .then(res => console.log(res.data))
        //             .catch(err => console.log(err));
        
        
        
                    
        
        //             this.setState({
        //                 // Hide the dialog box
                        
        //                 // ShoSuw Dialog 23 September
        //                 showModal: true,
        
        //                 userID : unique_id
        //             })
        
                    
        //             console.log("Hit api end" + unique_id)
                   
        
        //             // redirect to results page
        //             // 23 September, don't redirect
        //             // window.location = "/results/"+unique_id


        //             options.allowComplete = result;
               
        
        //     }
            
        // })


        this.survey.onComplete.add((result,options)=>{
          // Submit Final 
          console.log("Survey Submitted");
          // result.data contains the answers submitted by user
          // ****************************************************
          console.log("Critical" + JSON.stringify(result.data))


          const unique_id = uuid();

          // Get email from dialog
          let metadata = {"email" : "testdeafault@gmail.com", "unique_id" : ""+unique_id, "locale" : ""+test} 

      
          var dataObj = Object.assign(metadata, result.data);

          // console.log("Merged object with unique id and email " + JSON.stringify(dataObj))
          
          // dataObj = {
          //      "email" : "test@gmail.com",
          //      "unique_id" : ""+unique_id,
          //      "locale " : ""+this.state.myJSON.locale,
               
          // }

          console.log("Hit api start" + JSON.stringify(dataObj));
          // https://alpaca.learnovatecentre.org/api/
          axios.post('https://app.udl-boe.eu/api/record', dataObj)
        //   axios.post('http://localhost:5000/api/record', dataObj)
          .then(res => console.log(res.data))
          .catch(err => console.log(err));



          

          this.setState({
              // Hide the dialog box
              
              // ShoSuw Dialog 23 September
              showModal: true,

              userID : unique_id
          })

          
          console.log("Hit api end" + unique_id)  
        })

        // this.survey.onComplete.add((result, options) => {
        //     // Submit Final 
        //     console.log("Survey Submitted");
        //     // result.data contains the answers submitted by user
        //     // ****************************************************

            
        //     // var email = prompt("Please enter your email")

        //     var choice = window.confirm("Press OK if you are happy to submit your answers \n Else press Cancel");
        //     console.log("Choiccceeeee" + choice)
        //     if(!choice)
        //     {
        //         return;
        //     }

            

        //     console.log("Critical" + JSON.stringify(result.data))
        //     const unique_id = uuid();
        //     // Get email from dialog
        //     let metadata = {"email" : "testdeafault@gmail.com", "unique_id" : ""+unique_id, "locale " : ""+this.state.myJSON.locale} 
        //     var dataObj = Object.assign(metadata, result.data);
        //     // console.log("Merged object with unique id and email " + JSON.stringify(dataObj)) 
        //     // dataObj = {
        //     //      "email" : "test@gmail.com",
        //     //      "unique_id" : ""+unique_id,
        //     //      "locale " : ""+this.state.myJSON.locale,     
        //     // }
        //     console.log("Hit api start" + JSON.stringify(dataObj));
        //     // https://alpaca.learnovatecentre.org/api/
        //     axios.post('https://app.udl-boe.eu/api/record', dataObj)
        //     // axios.post('http://localhost:5000/api/record', dataObj)
        //     .then(res => console.log(res.data))
        //     .catch(err => console.log(err));
        //     this.setState({
        //         // Hide the dialog box
        //         // ShoSuw Dialog 23 September
        //         showModal: true,
        //         userID : unique_id
        //     })
        //     console.log("Hit api end" + unique_id)
        //     // redirect to results page
        //     // 23 September, don't redirect
        //     // window.location = "/results/"+unique_id
        // });

        // Created this method, because setState() was not working inside the existing method
        const updatePageNumber = (cpage) => {
            
            this.setState({
                latestPage : cpage
            }
            )

            console.log("Barkhayy" + this.state.latestPage)
        }
        
    }


    submitEmail = (e) => {
            
        // e.preventDefault();
        
        console.log("Saved email " + this.state.email + " id is " +this.state.userID)
    
        // Update email of current user 
        axios.put('https://app.udl-boe.eu:5000/baupdate/'+this.state.userID, 
        {email : ""+this.state.email})
            // axios.post('http://localhost:5000/record', dataObj)
            .then(res => console.log(res.data))
            .catch(err => console.log(err));

        this.setState({
            showModal: false
        })
    
    }

    randomizeMethod(){
            // =============== start of randmozing last five questions
            // Step 1 - Get pages array
            // This is string
            let pagesArray = JSON.stringify(this.state.myJSON.pages);
            

            // Step 2 - Convert it into array 
            let myarray = JSON.parse(pagesArray);
            
             // Step 3  Get the last carousel questions only
            var arr = []
            for(var i=0;i<myarray.length;i++){
                // Change here and start randomization from page 2
                // 23 September 2022
                // Before that it was i>=3, starting randomization from page 4 (index 3)
                if(i >=1)
                {
                    arr.push(myarray[i])
                }
            }

            // console.log("Carousel array" +arr)

            // Step 4 - Randomize carousel array elements
            shuffle(arr)

            arr.map(a => {
                console.log("Shuffled array"+JSON.stringify(a))
            })

            // Step 5 - Inject back the randomized array in the actual json
            // Delete the pages array from JSON object
            // delete this.state.myJSON.pages
            // console.log("Uth Oye" + JSON.stringify(this.state.myJSON))
            // First merge the array
            // And then inject
            
            // This line was missing first two questions, leading to stop auto navigation to next pages
            // arr.unshift(this.state.myJSON.pages[0],this.state.myJSON.pages[1], this.state.myJSON.pages[2])
            arr.unshift(this.state.myJSON.pages[0])
            
            // Now arr has the last 5 questions randomized

            this.setState({
                myJSON : Object.assign(this.state.myJSON, {"pages" : arr},)
            }, ()=>{
                console.log("----" + JSON.stringify(this.state.myJSON.pages[3]))
            })
           // =============== end of randmozing last five questions
    }


    componentDidMount(){
        
        window.scrollTo(0,0)

        this.randomizeMethod()

            
       

    }
    
    
   
    


    render() {
        return <div >


           {this.state.showSpinner &&  <Spinner /> }
            
            {/* <>
                <ProgressBar
                currentPage= {this.state.latestPage}/>

            </> */}
            
            <SurveyReact.Survey 
            model={this.survey}
            
            />


            <ReturnModal
            userID= {this.state.userID}
            showModal = {this.state.showModal}
            lang = {this.state.selectedLang}/>

            
        
        </div>;
    }
}



export function ReturnModal(props){
    const { t } = useTranslation();

    console.log("Bukhari" +props.lang)

    let lang = props.lang

    console.log("Tirmizi" + lang)
    
    return(
        <div>
            <Modal 
            // show
            show={props.showModal}
            heading={t('assesscomp')}
            >
            {/* <Modal show> */}
                <div>

               

                 
                   

                    <div style={{justifyContent:"center"}}>
                    <p>{t('assessmed')} </p>
                    
                    <p>
                    {t('assessdetail')}
                    </p>

                    </div>

                    {/* <p style={{display:"flex", justifyContent:"center"}}>
                       
                        
                        <b>
                            <Link
                            className="HeadingText"
                            to={`/results/${this.state.userID}`}>
                                    View Results
                            </Link>
                        </b>
                        
                    </p> */}


                <div style={{ display:"flex",columnGap:"3em", justifyContent:"center"}}>
                <div style={{display:"flex", justifyContent:"center"}}>
                    <Link
                    to={`/demographics/${props.userID}`}
                    state={{lang : props.lang? props.lang : "en"}}
                    className='yellowButton yellowButtonText'
                    style={{marginLeft:"-40px", height: test == "gr"? "2em": "1.5em", paddingTop: "0.5em" }}
                    >
                         {t('rsrchsurvey')}
                    </Link>
                </div>


                    <div style={{display:"flex", justifyContent:"center"}}>
                    <Link 
                    to={`/results/${props.userID}`}
                    state={{lang : lang? lang : "en" }}
                    className='yellowButton yellowButtonText'
                    style={{marginLeft:"-40px", height: test == "gr"? "2em": "1.5em", paddingTop: "0.5em", }}
                    >
                    {t('vwresults')}
                    </Link>
                    </div>

                    </div>

                
                    
               

                {/* OR<label className="HeadingText">
                    Enter your demographic details
                </label>  */}
                

                
               

               

                {/* 
                
                
                */}
                {/* <div
                style={{justifyContent:"center",display:"flex"}}>
                <input type="text" placeholder='Your Email'
                className="text-field" 
                onChange={event => this.setState({
                    email : event.target.value
                })} 
                 value={this.state.email}></input>
                </div> */}
                
              
                
                
                {/* <div
                style={{justifyContent:"center",display:"flex"}}>
                <Link 
                to={`/results/${this.state.userID}`}
                type="submit"
                className='yellowButton yellowButtonText'
                style={{padding:"0px"}}
                onClick={event => this.submitEmail(event)}> Submit </Link>

                </div> */}

                {/* <br/>

                

                <br/> */}

                {/* <button
                onClick={e => setShowModal(false)}>Close</button> */}

               

                

                </div>
            </Modal>
        </div>
    )
}


export function SurveyPage(props) {
    const location = useLocation();

    console.log("Jhaka" +location.state.currentLang)

    return (
        <div 
        className="surveybox"
        style={{height:"67vh", 
        
        
         }}
        >
        <SurveyComponent 
        cl = {location.state.currentLang? location.state.currentLang : "en"}/>

        
        <div 
        className="footer"
        style={{ position:"fixed", bottom:"0"}}>
            <div className="smallscreenfooter">
            {/* <Affiliation/> */}
            <Footer/>
            </div>
        </div>

        </div>
    );
  }
  