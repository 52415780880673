import React, {useState, useEffect} from 'react';
import { Link, useLocation } from "react-router-dom";


import { useTranslation } from 'react-i18next';

import bar from '../images/bar.svg';
import degree from '../images/degree.svg';
import letter from '../images/letter.svg';
import { useMediaQuery } from 'react-responsive'
import Affiliation from './Affiliation';
import Footer from './Footer';


export default function UDLIntro() {
  const location = useLocation()

  const [cl, setCL] = useState("")

  const { t } = useTranslation();

  useEffect(()=>{
      window.scrollTo(0,0);
      setCL(location.state.currentLang)
  }, [location.state.currentLang])

 console.log("In UDL Intro page" +location.state.currentLang )

 const isLaptop = useMediaQuery({
  query: "(min-device-width: 1280px)",
});

const isBigScreen = useMediaQuery({
  query: "(min-device-width: 1920px )",
});



  return (
  
    <div >

        
          <div
          className="intro-div" style={{ height:"40em"}}>
        
               
                <div style={{display: "flex", justifyContent:"center", paddingTop:"2%" }}>
        
                  
                <div className="box-outline-lg">
        
                  <div className="box-outline-head" style={{display:"flex", justifyContent:"center"}}>
                    {t('assinst')}
                  </div>
        
        
                <div>
                 
                 
                 <div style={{display:"flex", columnGap:"30px", marginLeft: "20px", 
                  marginRight:"20px"}}>
        
                    <img src={bar}/>
                    <p className="intro-label"> {t('introp1')}</p>
                  
                  </div>
        
        
                 
                  
        
                  <div style={{display:"flex", columnGap:"30px", marginLeft: "20px", 
                  marginRight:"20px"}}>
        
                    <img src={letter}/>
                    <p className="intro-label"> {t('introp3')}</p>
                  
                  </div>


                  <div style={{display:"flex", columnGap:"30px", marginLeft: "20px", 
                  marginRight:"20px"}}>
        
                    <img src={degree}/>
                    <p className="intro-label"> {t('introp2')}</p>
                  
                  </div>
        
                 
        
                    
                    <div style={{display:"flex", justifyContent:"center",}}>
                    {/* <Link to="/mainquestions"
                    className='yellowButton yellowButtonText'> */}
        
                      {/* <Link to="/udlintro"
                          state= {{currentLang : currentLang}}
                            className='yellowButton yellowButtonText'
                            style={{marginLeft: "50px", marginTop:"20px"}}> */}
                  
                  {/* <Link to="/mainquestions"
                  state= {{currentLang : location.state.currentLang? 
                      location.state.currentLang: ""}} */}
                      
                      
                      
                    </div>
                
                </div>
                </div>
                
                
                
                </div>
                {/* kiya */}
        
                {/* <div style={{display: "flex", justifyContent:"center"}}>
                    <label>What is this all for ? </label>
                    <label>What is UDL?</label>
                </div> */}
        
                {/* <div style={{display: "flex", justifyContent:"center", backgroundColor:"lightgrey", height: "500px"}}>
                    Explanation of UDL BOE Project
        
                    <br/>
                    <br/>
        
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum
                
                </div> */}
        
                <br/>
                
                <div style={{display:"flex", justifyContent:"center", marginTop:"-10px"}}>
                <Link to="/mainquestions"
                          state= {{currentLang : cl}}
                    className='yellowButton yellowButtonText'>
                      {t('procdbtn')}
                    </Link>
                  
                  </div>
        
                {/* <div style={{display: "flex", justifyContent:"center"}}>
                                            
                                               
                                                
                </div> */}
        
          </div>


          <div 
          className='footer'
          style={{position:"fixed", bottom:0}}>
                <Affiliation/>
                <Footer/>
      </div>

         

    </div>
  
 );
}
