import React, {useState, useEffect} from 'react';
import oerslist from '../sheets/oers_list.xlsx';
import * as XLSX from 'xlsx';
import OERTemp from './OERTemp';
import { Link } from "react-router-dom";
import useForceUpdate from 'use-force-update';

// import json data for OERs
import oerDataFile from './oerdata';
import FilterRadio from './FilterRadio';
import { loadNamespaces } from 'i18next';

import axios from 'axios';

// Extract data from Excel Sheet 
// Iterate over it and then generate the divs accordingly using e.g. map function
// https://dev.to/shareef/convert-excel-to-json-in-reactjs-in-just-2-steps-4pa1

// Information to add for each OER
// 1. Icon
// 2. Title
// 3. Description
// 4. Duration
// 5. Language
// 6. Blended
// 7. Link for Launch Button

var _ = require('lodash');

let navasortedObj = null;
let random = null;

export default function DynamicOers(props) {

    const forceUpdate = useForceUpdate();
    
    const [oerData, setOERData] = useState(null);

    const [dataObj, setDataObj] = useState(null);

    const [sorteddataObj, setSortedDataObj] = useState(null);

    const [showLoadBtn, setShowLoadBtn] = useState(true);

    const [loadButtonLocation, setLoadButtonLocation] = useState(1000);

    const [teacher, setTeacher] = useState(null);

    const[sortedArray, setSortedArray] = useState(null)


    

    const [oerD, setOERD] = useState(
        [
            {icon : "youtube",
            title : "1-2-3 Captions: Workflows for creating accessible videos",
            cluster : "Language & Symbols",
            description : "Webinar with handout slides on making videos accessible",
            duration: "1:01:33",
            language: "English",
            environment: "blended"
            },
            {icon : "youtube",
            title : "1-2-3 Captions: Workflows for creating accessible videos",
            cluster : "Language & Symbols",
            description : "Webinar with handout slides on making videos accessible",
            duration: "1:01:33",
            language: "English",
            environment: "blended"
            },
            {icon : "youtube",
            title : "1-2-3 Captions: Workflows for creating accessible videos",
            cluster : "Language & Symbols",
            description : "Webinar with handout slides on making videos accessible",
            duration: "1:01:33",
            language: "English",
            environment: "blended"
            },
            {icon : "youtube",
            title : "1-2-3 Captions: Workflows for creating accessible videos",
            cluster : "Language & Symbols",
            description : "Webinar with handout slides on making videos accessible",
            duration: "1:01:33",
            language: "English",
            environment: "blended"
            },
            {icon : "youtube",
            title : "1-2-3 Captions: Workflows for creating accessible videos",
            cluster : "Language & Symbols",
            description : "Webinar with handout slides on making videos accessible",
            duration: "1:01:33",
            language: "English",
            environment: "blended"
            },
            {icon : "youtube",
            title : "1-2-3 Captions: Workflows for creating accessible videos",
            cluster : "Language & Symbols",
            description : "Webinar with handout slides on making videos accessible",
            duration: "1:01:33",
            language: "English",
            environment: "blended"
            }

        ]
    )


    const {id} = props;

    let testObj = [];

    let loc = 950;

    console.log("xxxxxxxxxxxxxxx" +JSON.stringify(props))

     const returnScore = (cluster)=> {
        console.log("Cluster is" + cluster)
       
        
       
        if(cluster == "Perception")
            return props.perception;
        else if(cluster == "Language & Symbols")
            return props.lngSymbol;
        else if(cluster == "Comprehension")
            return props.comprehension;
        else if(cluster == "Physical Action")
            return props.physicalAction;
        else if(cluster == "Expression & Communication")
            return props.expression;  
        else if(cluster == "Executive Functions")
            return props.execFunction;
        else if(cluster == "Recruiting Interest")
            return props.recuritingInterest;
        else if(cluster == "Sustaining Effort & Persistence")
            return props.susEffort;
        else if(cluster == "Self Regulation")
            return props.selfRegulation;
        else
            return 0;
        
    }


    


    
   
    
   
    
    useEffect(()=>{


        // Teacher loaded from db
        console.log("Teacher loaded from db is " + id +
        "Recuriting Interest : " + props.recuritingInterest);

 axios.get('https://app.udl-boe.eu/api/teacherrecord/'+id)
    // axios.get('http://localhost:5000/api/teacherrecord/'+id)
//   axios.get('https://app.udl-boe.eu:5000/teacherrecord/'+id)
   .then(res => {
    
    setTeacher(res.data);
    
    // console.log("Teacher loaded is ******* " + JSON.stringify(teacher))
    })
  .catch(err => console.log(err));
        


        
        
            

        
      
        
        oerDataFile.map( (j, index) => {
            console.log("Titless : " + j['__EMPTY'] + "\n" + "Description: " + j['__EMPTY_1'] 
            + "\n" + "Duration: " +j['__EMPTY_17'] + "\n" + "Language" + j['__EMPTY_2'] + "\n Format " + j['__EMPTY_12']
            + "\n Cluster " +j['__EMPTY_16'] + "\n Link: "+j['__EMPTY_9']);
    
           
            // Load all, and remove index<5 conidtion
            //    if(index <=5){
    
           
                testObj.push (
                    {
                        title : j['OER Title']? j['OER Title'] : "Sample Title and a Long one Very Long one Sample Title and a Long one Very Long oneSample Title and a Long one Very Long oneSample Title and a Long one Very Long one",
                        description: j['OER Description']? j['OER Description'] : "Sample Description" ,
                        duration:  j['Duration'] ?j['Duration'] : "NA",
                        language : j['Language']?j['Language'] : "Language Agnostic" ,
                        cluster : j['Clusters']? j['Clusters'] : "No cluster",
                        format : j['Environment'] ? j['Environment'] : "Unknown",
                        link : j['OER Link'] ? j['OER Link'] : "https://udl-boe.eu/",
                        format: j['Type/Format'] ? j['Type/Format'] : "HTML",
                        score : returnScore(j['Clusters']),
                        checkpoint: j['Checkpoints'] ? j['Checkpoints'] : "Unknown",
                        cplink : j['cplink']? j['cplink']: ""
                        
                    })
            
            }
           
            
    
        // }
        
        )

        console.log("********************Default*************" +JSON.stringify(testObj))
       
        testObj= _.sortBy(testObj, [ "score"]);
        setSortedArray(testObj)
        
       

       
        

        // Update dataObj array based on scores
        // sorting here, based on answer of each question
        // October 2022
        // cluster

        console.log("********************Sorted*************" +JSON.stringify(testObj))
        
        // OERS OBJECT dataObj contains list of OERS with scores for 9 categories mapped
        // Now sort this 
        
       

        

        

    }, [props] )

   

    

   
   

  

   
    

    

    const readUploadFile = (e) => {
        e.preventDefault();
        if (e.target.files) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = XLSX.utils.sheet_to_json(worksheet);
                console.log("Sheet name" + sheetName)
                console.log("Rawzy" + JSON.stringify(json));

                // let testObj = [];
                // json.map(j => {
                //     console.log("Title : " + j['__EMPTY'] + "\n" + "Description: " + j['__EMPTY_1'] 
                //     + "\n" + "Duration: " +j['__EMPTY_17'] + "\n" + "Language" + j['__EMPTY_2'] + "\n Format " + j['__EMPTY_12']
                //     + "\n Cluster " +j['__EMPTY_16']);

                //     testObj.push (
                //     {
                //         title : j['__EMPTY']? j['__EMPTY'] : "Sample Title and a Long one Very Long one Sample Title and a Long one Very Long oneSample Title and a Long one Very Long oneSample Title and a Long one Very Long one",
                //         description: j['__EMPTY_1']? j['__EMPTY_1'] : "Sample Description" ,
                //         duration:  j['__EMPTY_17'] ?j['__EMPTY_17'] : "0 mins",
                //         language : j['__EMPTY_2']?j['__EMPTY_2'] : "Language Agnostic" ,
                //         cluster : j['__EMPTY_16']? j['__EMPTY_16'] : "No cluster"
                //     })

                // })

               

                setDataObj(testObj)

                setOERData(json);

                


            };
            reader.readAsArrayBuffer(e.target.files[0]);
        }


        

    }



    const loadOERs = e => {
        e.preventDefault();

        loc = loc * 500;

        console.log("Loading OERs 3 each time" +dataObj.length)
        let length = dataObj.length;

        let tempData = dataObj

        // set dataObj
        // add 3 items 
        oerDataFile.map( (j, index) => {
           if(index >=length && index <=length+5){

           
                tempData.push (
                    {
                        title : j['OER Title']? j['OER Title'] : "Sample Title and a Long one Very Long one Sample Title and a Long one Very Long oneSample Title and a Long one Very Long oneSample Title and a Long one Very Long one",
                        description: j['OER Description']? j['OER Description'] : "Sample Description" ,
                        duration:  j['Duration'] ?j['Duration'] : "NA",
                        language : j['Language']?j['Language'] : "Language Agnostic" ,
                        cluster : j['Clusters']? j['Clusters'] : "No cluster",
                        format : j['Environment'] ? j['Environment'] : "Unknown",
                        link : j['OER Link'] ? j['OER Link'] : "https://udl-boe.eu/",
                        checkpoint: j['Checkpoints'] ? j['Checkpoints'] : "Unknown",
                        cplink : j['cplink']? j['cplink']: ""
                    })
            
            }
            else if(length >= 87)
            {
                console.log("You are a Pro, Explored all OERs")
                // Hide Load More Btn
                setShowLoadBtn(false);
            }
           
            

        })

        setDataObj(tempData)


        // Set updated position for Load More Button

        
        
        forceUpdate()

        setLoadButtonLocation(loadButtonLocation + 40);


        



    }


    
   
  
    
  return (
    <div 
    className="dynoersmaindiv"
    style={{ marginLeft:"10em",}}>

        {/* {console.log("Bhootnath" + JSON.stringify(navasortedObj))} */}
       
        
        {/* DynamicOers */}

        {/* File Element to select excel sheet */}
        {/* <form>
        <label htmlFor="upload">Upload File</label>
        <input
        type="file"
        name="upload"
        id="upload"
        onChange={readUploadFile}
        />
        </form> */}

        {/* <div style={{border: "1px solid black"}}>
            {/* Single selection radio button for Type, Cluster, Language, Duration */}
            {/* Sort By : <FilterRadio/> */}
        {/* </div> */}
         {/* */} 
         
{/*         
asdf
*/}

        

        {/* Dynamic OERS based on data */}
        <div style={{ height:"fit-content",
    }}>
        
       
        
        
       <div>
        {
           sortedArray && sortedArray.map((oer,idx) => {
                // Break line after every third oer
                // https://stackoverflow.com/questions/25426418/css-and-html-2-divs-on-one-line-then-go-next-line
                    // console.log("..." +idx)
                
                
                    return(
                    <div style={{float:"left", marginBottom:"20px",
                    }}>
                    <OERTemp
                    dataObj = {oer} />
                    </div>
                    )  
                
                

                
                

            })
        }


        {/* <div style={{
        width:"50em", paddingLeft:"20em", marginLeft:"25em",
        
            
           
       
        }}>
        <Link to="/" className="launch-btn launch-btn-text-new"
        onClick={loadOERs} 
        style={{backgroundColor:"red", 
        color:"white", position:"absolute", top:"65em", left:"40%"
      }}>
                Load More
        </Link>
        </div> */}



        </div>
        
        

       



       
        


        </div>


        
       

      
        

    </div>


    
  )
}
